// https://stackoverflow.com/questions/74115306/how-to-logout-azure-from-swa-cli
// https://azure.github.io/static-web-apps-cli/docs/intro
export const AzureConfig = {
	webBaseUrl: 'https://missionbuilder.multiplied.ai',
	directoryTenentId: '4dddd100-bab7-470e-a645-d0287c853884',
	authClientID: '512e8259-a9dd-42b2-879b-ff9f9397c3e3',
	userReadScope: 'https://graph.microsoft.com/User.Read',
	userOfflineScope: 'https://graph.microsoft.com/Offline_access',
	storageContainerName: 'default-container',
	storageContainerVideoName: 'video-input',
	storageContainerVideoOutputName: 'video-output',
	imageOptimizedUrls: ['https://devcdn.multiplied-ai.com'],
};
