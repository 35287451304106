import React from 'react';
import { useTour } from '@reactour/tour';
import { createUseStyles } from 'react-jss';
import SusyDarkMode from './assets/img/wanna/susy/susy-dark-mode.png';
import Susy1 from './assets/img/wanna/susy/susy.png';
import Susy2 from './assets/img/wanna/susy/susy2.png';
import Susy3 from './assets/img/wanna/susy/susy3.png';
import Susy4 from './assets/img/wanna/susy/susy4.png';
import Susy5 from './assets/img/wanna/susy/susy5.png';
import Susy6 from './assets/img/wanna/susy/susy6.png';
import Susy7 from './assets/img/wanna/susy/susy7.png';
import Susy8 from './assets/img/wanna/susy/susy8.png';
import Susy9 from './assets/img/wanna/susy/susy9.png';
import Susy10 from './assets/img/wanna/susy/susy10.png';
import useDarkMode from './hooks/useDarkMode';
import Button from './components/bootstrap/Button';

/**
 * Steps style
 */
export const styles = {
	/**
	 * Overlay style
	 * @param base
	 */
	maskWrapper: (base: any) => ({ ...base }),
	maskArea: (base: any) => ({
		...base,
		rx: 'var(--bs-border-radius)',
	}),
	highlightedArea: (base: any) => ({ ...base }),
	badge: (base: any) => ({ ...base }),
	popover: (base: any) => ({
		...base,
		boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
		backgroundColor: 'var(--bs-body-bg)',
		color: 'var(--bs-body-color)',
		borderRadius: 'var(--bs-border-radius)',
		zIndex: '1000000009',
		top: '60px',
	}),
};

/**
 * Image Styles
 * @type {(data?: {theme?: DefaultTheme}) => Classes<"image">}
 */
const useStyles = createUseStyles({
	image: {
		maxHeight: '150px',
		objectFit: 'contain',
	},
});

/**
 * Prev & Next buttons
 * @returns {JSX.Element}
 * @constructor
 */
const TourNavigation = () => {
	const { currentStep, setCurrentStep } = useTour();
	return (
		<div className='col-12 mt-3'>
			<div className='row'>
				<div className='col'>
					{!!currentStep && (
						<Button
							icon='ArrowBackIos'
							color='info'
							isLink
							onClick={() => setCurrentStep(currentStep - 1)}>
							Prev
						</Button>
					)}
				</div>
				<div className='col-auto'>
					<Button
						icon='ArrowForwardIos'
						color='info'
						isLight
						onClick={() => setCurrentStep(currentStep + 1)}>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
};

/**
 * Selector
 * @param name
 * @returns {`[data-tour='${string}']`}
 */
const getTargetName = (name: string): `[data-tour='${string}']` => {
	return `[data-tour='${name}']`;
};

const DarkModeTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-5'>
				<img src={SusyDarkMode} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<div className='col-md-7 d-flex align-items-center'>
				<div>
					<p className='lead'>Dark / Light Mode</p>
					<p>You can switch between dark and light mode.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const LangSwitcherTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy2} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Language Switcher</p>
					<p>
						"react-i18next" is integrated for internationalization. Currently in demo,
						it is prepared only for <code>Aside</code>, you can easily use it in your
						project.
					</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const SearchTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy7} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Search</p>
					<p>You can set it to search for what you want with the search bar.</p>
					<ol>
						<li>
							Let's search for the word <b>"Card"</b> as an example.
						</li>
						<li>
							Then click on the word <b>"Card"</b> from the results.
						</li>
					</ol>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};
const KnowledgeBaseTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy9} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Knowledge Base</p>
					<p>Here you can find detailed usage of ui and form components for Facit.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};
const ProjectsTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy9} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Projects</p>
					<p>Here you can find detailed usage of ui and form components for Facit.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};
const AddModuleTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy9} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Add Module</p>
					<p>Here you can find detailed usage of ui and form components for Facit.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};
const NotificationsTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Notifications</p>
					<p>
						Since the "Subheader" is in the same file as the page content, you can
						easily use the relevant components here.
					</p>
				</div>
			</div>
			<div className='col-md-4'>
				<img src={Susy3} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<TourNavigation />
		</div>
	);
};

const AICollaboratorTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy9} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>AI Collaborator </p>
					<p>Here you can find detailed usage of ui and form components for Facit.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};
const UserProfileTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy8} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>User Profile</p>
					<p>
						With the UI, form and layout components specially prepared for Facit, you
						can easily set the screens suitable for your project.
					</p>
					<p>
						As an example, dashboard screens are made. Click to see the{' '}
						<b>"User Dashboard"</b> screen.
					</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};
const LastTour = () => {
	const { setIsOpen, setCurrentStep } = useTour();
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy10} className={classes.image} loading='lazy' width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Thank you for following me!</p>
					<p>I know it is very long, but I have actually presented very little of it.</p>
					<p>You can always contact us after purchasing.</p>
				</div>
			</div>
			<div className='col-12 mt-3'>
				<div className='row'>
					<div className='col-12'>
						<p>- What would you like to do now?</p>
					</div>
					<div className='col'>
						<Button
							icon='Close'
							color='danger'
							isLink
							onClick={() => {
								setIsOpen(false);
								setCurrentStep(0);
							}}>
							Close
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

/**
 * Tour Steps
 */
const steps = [
	/**
	 * Dark Mode
	 * @step 0
	 */
	{
		selector: getTargetName('dark-mode'),
		content: () => <DarkModeTour />,
	},
	/**
	 * Lang Selector
	 * @step 1
	 */
	/** {
		selector: getTargetName('lang-selector'),
		content: () => <LangSwitcherTour />,
		highlightedSelectors: [getTargetName('lang-selector-menu')],
		mutationObservables: [getTargetName('lang-selector-menu')],
		resizeObservables: [getTargetName('lang-selector-menu')],
	}, */
	/**
	 * Search
	 * @step 2
	 */
	{
		selector: getTargetName('search'),
		content: () => <SearchTour />,
		highlightedSelectors: [getTargetName('search-modal'), getTargetName('card')],
		mutationObservables: [getTargetName('search-modal'), getTargetName('card')],
		resizeObservables: [getTargetName('search-modal'), getTargetName('card')],
	},
	/**
	 * Knowledge Base
	 * @step 3
	 */
	{
		selector: getTargetName('knowledge-base'),
		content: () => <KnowledgeBaseTour />,
		highlightedSelectors: [getTargetName('knowledge-base')],
		mutationObservables: [getTargetName('knowledge-base')],
		resizeObservables: [getTargetName('knowledge-base')],
	},
	/**
	 * Projects
	 * @step 4
	 */
	{
		selector: getTargetName('projects'),
		content: () => <ProjectsTour />,
		highlightedSelectors: [getTargetName('projects')],
		mutationObservables: [getTargetName('projects')],
		resizeObservables: [getTargetName('projects')],
	},
	/**
	 * Add-module
	 * @step 5
	 */
	{
		selector: getTargetName('add-module'),
		content: () => <AddModuleTour />,
		highlightedSelectors: [getTargetName('add-module')],
		mutationObservables: [getTargetName('add-module')],
		resizeObservables: [getTargetName('add-module')],
	},
	/**
	 * Notifications
	 * @step 6
	 */
	{
		selector: getTargetName('notifications'),
		content: () => <NotificationsTour />,
		highlightedSelectors: [getTargetName('notifications')],
		mutationObservables: [getTargetName('notifications')],
		resizeObservables: [getTargetName('notifications')],
	},
	/**
	 * AI-Collaborator
	 * @step 7
	 */
	{
		selector: getTargetName('ai-collaborator'),
		content: () => <AICollaboratorTour />,
		highlightedSelectors: [getTargetName('ai-collaborator')],
		mutationObservables: [getTargetName('ai-collaborator')],
		resizeObservables: [getTargetName('ai-collaborator')],
	},
	/**
	 * User Profile
	 * @step 8
	 */
	{
		selector: getTargetName('user-profile'),
		content: () => <UserProfileTour />,
		highlightedSelectors: [getTargetName('user-profile')],
		mutationObservables: [getTargetName('user-profile')],
		resizeObservables: [getTargetName('user-profile')],
	},
	/**
	 * Last
	 * @step 9
	 */
	{
		selector: 'body',
		content: () => <LastTour />,
	},
];

export default steps;
