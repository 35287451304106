import { gql } from '@apollo/client';

export const GET_CALENDARS = gql`
	query Query($accessToken: String) {
		getCalendars(accessToken: $accessToken)
	}
`;

export const UPDATE_CALENDAR_MUTATION = gql`
	mutation Mutation($userInput: JSON, $accessToken: String) {
		updateCalendar(userInput: $userInput, accessToken: $accessToken)
	}
`;
