import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import {
	PROMPT_TYPE,
	Workflow,
	WorkflowCampaign,
	WorkflowComposedEmail,
	WorkflowOutput,
	WorkflowPPT,
	WorkflowStepTemplate,
	WorkflowTemplate,
} from '../models/models';
import useWorkflow from '../hooks/useWorkflow';
import useCollaboration from '../hooks/useCollaboration';
import { CollaborationThread, Collaborator } from '../models/collaboration';
import { ChatConversation } from '../models/chatgpt/models';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';

interface IWorkflowContext {
	loading: boolean;
	saveWorkflow: (file: any) => Promise<void>;
	createWorkflow: (file: Workflow) => Promise<void>;
	workflow: Workflow | undefined;
	workflowPPT: WorkflowPPT | undefined;
	workflowId: string;
	workflowTemplate: WorkflowTemplate | undefined;
	setWorkflowStep: Dispatch<SetStateAction<WorkflowStepTemplate | undefined>>;
	workflowStep?: WorkflowStepTemplate;
	workflowOutput?: WorkflowOutput[];
	allWorkflowOutput: WorkflowOutput[];
	generate: (
		promptType: PROMPT_TYPE,
		input?: Record<string, string> | undefined,
		chatId?: string | undefined,
		combination?: any[],
	) => Promise<void>;
	setWorkflowTemplate: Dispatch<SetStateAction<WorkflowTemplate | undefined>>;
	eventWorkflowId: string[];
	saveWorkflowOutput: (output: WorkflowOutput) => void;
	collaborationThreads: CollaborationThread[];
	collaborators: Collaborator[];
	addCollaborators: (
		collaborators: Collaborator[],
		workflowId: string,
		clientId: string,
		stepName: string,
	) => Promise<string | true | undefined>;
	removeCollaborators: (
		collaborators: Collaborator[],
		workflowId: string,
		clientId: string,
	) => Promise<string | true | undefined>;
	createNewWorkflowStep: (data: any) => Promise<void>
	deleteWorkflowStep: (a: any) => void;
	uploadVideoOutput: (
		blob: Blob,
		thumbnail: Blob,
		workflowId: string,
		stepId: string,
	) => Promise<string | undefined>;
	deleteWorkflowOutput: (output: WorkflowOutput) => void;
	cropOutputVideo: (
		videoUrl: string,
		cropdata: {
			width: number;
			height: number;
			x: number;
			y: number;
		},
	) => Promise<unknown>;
	exportWorkflow: () => void;
	saveWorkflowCampaign: (campaign: WorkflowCampaign) => Promise<void>;
	setEventWorkflowId: Dispatch<SetStateAction<string[]>>;
	saveWorkflowPPT: (file: WorkflowPPT) => Promise<void>;
	generateDynamicWorkflow: (workflowinit: Workflow, modifiedPrompt?: string | undefined) => Promise<void>;
	activeChatCompletion: ChatConversation | undefined;
	sendWorkflowEmails: (emails: WorkflowComposedEmail[]) => Promise<void>;
	addCollaborationQuestion: (id: string, sender: string, suggestion: string, workflowId: string, clientId: string, stepName: string, selectedCollaboratorsForQuestion?: string[], dataFragmentId?: string) => Promise<void>
	refetchWorkflowPPT: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
}

export const WorkflowContext = createContext<IWorkflowContext>({} as IWorkflowContext);

export const WorkflowProvider = ({
	children,
	inputWorkflowId,
}: {
	children: any;
	inputWorkflowId: string;
}) => {
	const {
		loading,
		saveWorkflow,
		createWorkflow,
		workflow,
		workflowId,
		workflowPPT,
		setWorkflowId,
		setWorkflowStep,
		workflowStep,
		workflowTemplate,
		workflowOutput,
		allWorkflowOutput,
		eventWorkflowId,
		generate,
		setWorkflowTemplate,
		saveWorkflowOutput,
		createNewWorkflowStep,
		deleteWorkflowStep,
		uploadVideoOutput,
		deleteWorkflowOutput,
		cropOutputVideo,
		exportWorkflow,
		saveWorkflowCampaign,
		setEventWorkflowId,
		saveWorkflowPPT,
		generateDynamicWorkflow,
		activeChatCompletion,
		sendWorkflowEmails,
		refetchWorkflowPPT,
	} = useWorkflow(inputWorkflowId);

	const {
		collaborationThreads,
		collaborators,
		addCollaborationQuestion,
		addCollaborators,
		removeCollaborators,
	} = useCollaboration(workflowId);

	useEffect(() => {
		setWorkflowId(inputWorkflowId);
	}, [inputWorkflowId]);

	useEffect(() => {
		setWorkflowId(inputWorkflowId);
	}, [inputWorkflowId]);

	return (
		<WorkflowContext.Provider
			value={{
				loading,
				saveWorkflow,
				createWorkflow,
				workflow,
				workflowId,
				workflowPPT,
				workflowTemplate,
				setWorkflowTemplate,
				workflowStep,
				setWorkflowStep,
				workflowOutput,
				allWorkflowOutput,
				eventWorkflowId,
				generate,
				saveWorkflowOutput,
				collaborationThreads,
				collaborators,
				addCollaborationQuestion,
				addCollaborators,
				removeCollaborators,
				createNewWorkflowStep,
				deleteWorkflowStep,
				uploadVideoOutput,
				deleteWorkflowOutput,
				cropOutputVideo,
				exportWorkflow,
				saveWorkflowCampaign,
				setEventWorkflowId,
				saveWorkflowPPT,
				generateDynamicWorkflow,
				activeChatCompletion,
				sendWorkflowEmails,
				refetchWorkflowPPT,
			}}>
			{children}
		</WorkflowContext.Provider>
	);
};
