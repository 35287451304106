import { useState, useEffect, useContext } from 'react';
import { DbUser } from '../Azure/models';
import { ActiveUser } from '../redux/active-item/active-itemSlice';
import { useQuery } from '@apollo/client';
import { GET_USERS_BY_CLIENTIDS } from '../GraphQL/user/queries';
import { AuthenticationResult } from '@azure/msal-browser';

export default function useUsers({
	user,
	activeUser,
}: {
	user?: AuthenticationResult;
	activeUser?: ActiveUser;
}) {
	const [users, setusers] = useState<DbUser[]>([]);
	const { data: usersByClientIds } = useQuery(GET_USERS_BY_CLIENTIDS, {
		variables: {
			clientIds: activeUser?.clientIds?.map((e) => e) ?? [],
			accessToken: user?.accessToken,
		},
		skip: !user?.accessToken || !user?.uniqueId || !activeUser?.clientIds,
	});

	useEffect(() => {
		if (
			!!usersByClientIds?.getUsersByClientIds &&
			usersByClientIds?.getUsersByClientIds?.length > 0
		) {
			setusers(usersByClientIds?.getUsersByClientIds as DbUser[]);
		}
	}, [usersByClientIds]);

	return {
		users,
	};
}
