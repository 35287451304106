import { useEffect, useState } from 'react';

import { ActiveUser } from '../redux/active-item/active-itemSlice';
import { AuthenticationResult } from '@azure/msal-browser';
import { getAzureAccount } from '../Azure/login';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CURRENT_USER } from '../GraphQL/user/queries';
import { UPDATE_USER_MUTATION } from '../GraphQL/user/mutation';
import { CommunicationPreferences, SalesProfessionalInfo } from '../models/profile';
import { getExpiryAccessToken } from '../util/token-verification';

export default function useLoggedInUser() {
	const [user, setUser] = useState<AuthenticationResult | null>(null);
	const [redirectUrl, setRedirectUrl] = useState('');
	const [activeUser, setActiveUser] = useState<ActiveUser>();
	const [isAuthenticatedCache, setIsAuthenticatedCache] = useState(false);
	const [salesProfessionalInfo, setSalesProfessionalInfo] = useState<SalesProfessionalInfo>({
		name: '',
		email: '',
		jobTitle: '',
		linkedinId: '',
		phoneNumber: '',
		photoUrl: '',
		UPS: '',
	});
	const [communicationPreferences, setCommunicationPreferences] =
		useState<CommunicationPreferences>({
			email: '',
			phoneNumber: '',
			toneAndStyle: '',
			socialMediaHandle: [],
		});

	const {
		data: activeUserData,
		error,
		loading,
	} = useQuery(GET_CURRENT_USER, {
		variables: { id: user?.uniqueId, accessToken: user?.accessToken },
		skip: !user?.accessToken || !user?.uniqueId,
	});
	const [updateUser, { error: updateUserError }] = useMutation(UPDATE_USER_MUTATION);

	useEffect(() => {
		setIsAuthenticatedCache(localStorage.getItem('isAuthenticated') != null);
		if (!!activeUserData?.user && !activeUser) {
			localStorage.setItem('isAuthenticated', 'true');
			setIsAuthenticatedCache(true);
			setActiveUser(activeUserData?.user);
		} else {
		}
	}, [activeUserData]);

	useEffect(() => {
		if (!user) {
			(async () => {
				const response = await getAzureAccount();
				if (!!response?.uniqueId) {
					setUser(response);
					localStorage.setItem('isAuthenticated', 'true');
					setIsAuthenticatedCache(true);
				}
			})();
		}
	}, []);

	useEffect(() => {
		if (activeUser) {
			setSalesProfessionalInfo({
				name: activeUser?.name,
				email: activeUser?.email,
				phoneNumber: activeUser?.phoneNumber,
				jobTitle: activeUser?.jobTitle,
				linkedinId: activeUser?.linkedinId,
				UPS: activeUser?.UPS,
			});
			setCommunicationPreferences({
				email: activeUser?.email ?? '',
				phoneNumber: activeUser?.phoneNumber,
				toneAndStyle: activeUser?.toneAndStyle,
				socialMediaHandle: activeUser?.socialMediaHandle,
			});
		}
	}, [activeUser]);

	const saveClientIdToUserData = async (newClientId: string) => {
		try {
			if (!!activeUser?.id) {
				await updateUser({
					variables: {
						userInput: {
							id: activeUser?.id,
							clientIds: [...(activeUser?.clientIds ?? [])!, newClientId],
						},
						accessToken: user?.accessToken,
					},
				});
				return;
			}
		} catch (err) {
			console.log('error on saving new client id:', err);
		}
	};

	const saveProfile = async (activeUser: Partial<ActiveUser>) => {
		const profData = await {
			...activeUser,
			id: activeUser?.id ?? user?.uniqueId ?? '',
			toneAndStyle: activeUser?.toneAndStyle ?? '',
			socialMediaHandle: activeUser?.socialMediaHandle ?? [],
		};
		await updateUser({
			variables: {
				userInput: {
					...profData,
				},
				accessToken: user?.accessToken,
			},
		});
		return;
	};

	const handleTokenExpiration = async () => {
		const expiryTime = await getExpiryAccessToken(user?.accessToken);
		if (expiryTime != -1 && expiryTime < new Date().getTime() + 300000) {
			const response = await getAzureAccount();
			if (!!response?.uniqueId) {
				setUser(response);
				localStorage.setItem('isAuthenticated', 'true');
				setIsAuthenticatedCache(true);
				await updateUser({
					variables: {
						userInput: {
							...activeUser,
						},
						accessToken: response.accessToken,
					},
				});
			}
		}
	};

	useEffect(() => {
		const intervalId = setInterval(handleTokenExpiration, 60000);
		return () => clearInterval(intervalId);
	}, [user?.accessToken]);

	return {
		user,
		setUser,
		activeUser,
		setActiveUser,
		saveClientIdToUserData,
		redirectUrl,
		setRedirectUrl,
		isAuthenticatedCache,
		saveProfile,
		salesProfessionalInfo,
		communicationPreferences,
	};
}
