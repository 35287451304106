import React, { lazy } from 'react';
import { childPages, dashboardPagesMenu, authMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import DataLake from '../pages/presentation/datalake/index';
import SkillsBase from '../pages/presentation/skillsBase';
import UserProfile from '../pages/presentation/userProfile';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	WORKFLOWLIST: lazy(() => import('../pages/presentation/workflow/WorkflowList')),
	WORKSPACE: lazy(() => import('../pages/presentation/settings/WorkspaceSettings')),
	COMPANY: lazy(() => import('../pages/presentation/company/manageCompany')),
	DATALAKE: lazy(() => import('../pages/presentation/datalake/index')),
	OUTPUTLIST: lazy(() => import('../pages/presentation/mission-builder/output/index')),
	PPT_VIEWER: lazy(() => import('../pages/presentation/mission-builder/output/ppt-viewer')),
	DOC_VIEWER: lazy(() => import('../pages/presentation/mission-builder/output/doc-viewer')),
	TL_VIEWER: lazy(
		() => import('../pages/presentation/mission-builder/output/tl-viewer/tl-viewer'),
	),
	MISSIONANALYTICS: lazy(() => import('../pages/presentation/mission-builder/analytics/index')),
	DISSEMINATE: lazy(() => import('../pages/presentation/disseminate/index')),
	MISSIONDETAILS: lazy(() => import('../pages/presentation/disseminate/mission-details')),
	MISSIONASSETS: lazy(() => import('../pages/presentation/disseminate/mission-assets')),
	STEPDETAILS: lazy(() => import('../pages/presentation/disseminate/step-details')),
	CAPTUREMISSIONASSETS: lazy(() => import('../pages/presentation/capture/mission-assets')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		// path: dashboardPagesMenu.dashboard.path,
		path: '/',
		element: <LANDING.WORKFLOWLIST />,
	},
	{
		path: authMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: authMenu.login.path,
		element: <Login />,
	},
	{
		path: authMenu.signUp.path,
		element: <Login isSignUp />,
	},
	{
		path: dashboardPagesMenu.workflows.path,
		element: <LANDING.WORKFLOWLIST />,
	},
	{ path: dashboardPagesMenu.missions.path, element: <LANDING.OUTPUTLIST /> },
	{ path: dashboardPagesMenu.missionAnalytics.path, element: <LANDING.MISSIONANALYTICS /> },

	{
		path: dashboardPagesMenu.workspace.path,
		element: <LANDING.WORKSPACE />,
	},
	{
		path: '/ppt-viewer/:id',
		element: <LANDING.PPT_VIEWER />,
	},
	{
		path: '/tl-viewer/:id',
		element: <LANDING.TL_VIEWER />,
	},
	{
		path: '/doc-viewer/:id',
		element: <LANDING.DOC_VIEWER />,
	},
	{
		path: dashboardPagesMenu.company.path,
		element: <LANDING.COMPANY />,
	},
	{
		path: dashboardPagesMenu.datalake.path,
		element: <DataLake />,
	},
	{
		path: dashboardPagesMenu.skillsBase.path,
		element: <SkillsBase />,
	},
	{
		path: dashboardPagesMenu.userProfile.path,
		element: <UserProfile />,
	},
	{
		path: '/disseminate/:id',
		element: <LANDING.DISSEMINATE />,
	},
	{
		path: '/disseminate/mission-details/:id',
		element: <LANDING.MISSIONDETAILS />,
	},
	{
		path: '/disseminate/mission-assets/:id',
		element: <LANDING.MISSIONASSETS />,
	},
	{
		path: '/disseminate/step-details/:id',
		element: <LANDING.STEPDETAILS />,
	},
	{
		path: '/disseminate/capture/mission-assets/:id',
		element: <LANDING.CAPTUREMISSIONASSETS />,
	},
];
const contents = [...presentation];

export default contents;
