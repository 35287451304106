import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { ChatCollaboration } from '../models/chat';
import { ClientContext } from '../contexts/ClientContext';
import { WorkflowContext } from '../contexts/WorkflowContext';
import { GET_CHAT_COLLABORATORS, GET_CHAT_CONVERSATION } from '../GraphQL/collaboration/queries';
import { useMutation, useQuery } from '@apollo/client';
import { CHAT_GPT_CALL_MUTATION } from '../GraphQL/callable-mutations';
import { Workflow } from '../models/models';
// import { DataFragmentVM, Workflow } from '../models/models';
// import { DatalakeContext } from '../contexts/DatalakeContext';
import _ from 'lodash';

enum RequestType {
	aiCollaboration = 'aiCollaboration',
	stepAiCollaboration = 'stepAiCollaboration',
	torontoAiCollaboration = 'torontoAiCollaboration',
	aiAgentCollaboration = "aiAgentCollaboration",
	wordDocument = "wordDocument",
}

export default function useChatCollaborator() {
	const [loading, setLoading] = useState(false);
	const [chats, setChats] = useState<ChatCollaboration[]>([]);
	const [activeChat, setActiveChat] = useState<ChatCollaboration | undefined>();
	const [activeDocumentId, setActiveDocumentId] = useState<string>();
	const { activeUser, user } = useContext(UserContext);
	const { activeClientId } = useContext(ClientContext);
	const { workflowStep, workflowId, saveWorkflow, workflow } = useContext(WorkflowContext);
		// const {save, fragment} = useContext(DatalakeContext)
	const sortedChats = [...chats]?.sort((a, b) => (a.lastUpdated > b.lastUpdated ? -1 : 1));
	const { data: chatCollaboratorsData, refetch: refetchChatCollaborators } = useQuery(
		GET_CHAT_COLLABORATORS,
		{
			variables: { clientId: activeClientId, accessToken: user?.accessToken },
			skip: !user?.accessToken || !user?.uniqueId || !activeClientId,
		},
	);
	const { data: chatConversationData, refetch: refetchChatConversation } = useQuery(
		GET_CHAT_CONVERSATION,
		{
			variables: { chatId: activeDocumentId, accessToken: user?.accessToken },
			skip: !user?.accessToken || !activeDocumentId,
		},
	);
	const [chatgptCall, { error: chatgptCallError }] = useMutation(CHAT_GPT_CALL_MUTATION);

	useEffect(() => {

		if (
			!!chatCollaboratorsData?.getChatCollaborators &&
			chatCollaboratorsData?.getChatCollaborators?.length > 0
		) {
			setChats(chatCollaboratorsData?.getChatCollaborators as ChatCollaboration[]);
		}
	}, [chatCollaboratorsData]);

	useEffect(() => {
		if(!!chatConversationData && !!chatConversationData.getChatConversation) {
			setActiveChat(chatConversationData.getChatConversation as ChatCollaboration)
		}
		else setActiveChat(undefined);
	}, [chatConversationData]);

	// useEffect(() => {
	// 	setActiveChat(undefined);
	// },[activeDocumentId]);


	const newChatCollaboration = async () => {
		setActiveChat(undefined);
		setLoading(false);
	};

	const chatGPTName = (input?: string) =>
		(input ?? '')
			.replaceAll('.', '-')
			.replaceAll('/', '_')
			.replace(/[^a-zA-Z0-9_-]/g, '')
			.substring(0, 60);

	const send = async (content: string, chatId?: string) => {
		setLoading(true);
		try {
			const response = await chatgptCall({
				variables: {
					userInput: {
						requestType: RequestType.aiCollaboration,
						clientId: activeClientId,
						message: { role: 'user', name: chatGPTName(activeUser?.name), content },
						id: chatId,
						promptId: 'ai-collaborator-output',
						accessToken: user?.accessToken,
					},
				},
			});
			setActiveChat(response?.data?.chatGPTCall as ChatCollaboration);
			await refetchChatCollaborators();
			setLoading(false);
			return response?.data?.chatGPTCall as ChatCollaboration
		} catch (err) {
			setLoading(false);
		}
	};

	const getSelectedDocumentsToWordText = async (content: string, userPrompt: string, currentStepName?: string, id?: string) => {
		let chatId;
		let stepDescription=''; 
		let chatInd=-1;

		if(!!currentStepName) {
			chatInd = workflow?.wfTemplate?.steps.findIndex((i) => i?.name === currentStepName) ?? -1;
			chatId = id ?? workflow?.wfTemplate?.steps[chatInd].documentStepId;
			stepDescription = (workflow?.data[workflow?.wfTemplate?.steps[chatInd].fields[0].name ?? ""] as any)?.text as string;
		}
		else {
			chatId = id ?? workflow?.documentId;
			workflow?.wfTemplate?.steps.forEach(step => {
				stepDescription+= (workflow?.data[step.fields[0].name] as any)?.text as string
			}); 
		}

		let input = {userPrompt, content, stepDescription};

		try {
			const result = await chatgptCall({
				variables: {
					userInput: {
						requestType: RequestType.wordDocument,
						clientId: activeClientId,
						promptId: "mission-builder-generate-word-document",
						id: chatId,
						input,
						accessToken: user?.accessToken,
					},
				},
			});
			// const res = result.data?.chatGPTCall as {documentStepId: string, documentUrl: string};
			// const editedWf = _.cloneDeep(workflow) as Workflow;
			// if (!chatId && !!editedWf?.wfTemplate?.steps[chatInd]) {
			// 	editedWf.wfTemplate.steps[chatInd].documentStepId = res.documentStepId;
			// 	await saveWorkflow(editedWf);
			// }
			// else {
			// 	const productVM: DataFragmentVM = {
			// 		folder: fragment?.folder ?? [],
			// 		name: fragment?.name ?? '',
			// 		text: fragment?.text ?? '',
			// 		images: fragment?.images,
			// 		videos: fragment?.videos,
			// 		documents: [{
			// 			url: res.documentUrl,
			// 			type: "pdf"
			// 		}],
			// 		type: 'document',
			// 		userId: '',
			// 	};
			// 	await save(productVM);
			// }
			// setActiveDocumentId(res.documentStepId);
			// return res.documentStepId;
			const documentStepId = result.data?.chatGPTCall as string;
			const editedWf = _.cloneDeep(workflow) as Workflow;
			if (!!currentStepName && !chatId && !!editedWf?.wfTemplate?.steps[chatInd]) {
				editedWf.wfTemplate.steps[chatInd].documentStepId = documentStepId;
				await saveWorkflow(editedWf);
			}
			else if(!currentStepName && !editedWf.documentId) {
				editedWf.documentId = documentStepId
				await saveWorkflow(editedWf);
			}
			await refetchChatConversation({chatId: documentStepId, accessToken: user?.accessToken});
			setActiveDocumentId(documentStepId);
			return documentStepId;
		} catch (e) {
			console.log({ e });
			return userPrompt;
		}
	};

	const sendAiAgent = async (content: string, chatId?: string) => {
		setLoading(true);
		try {
			const response = await chatgptCall({
				variables: {
					userInput: {
						requestType: RequestType.aiAgentCollaboration,
						clientId: activeClientId,
						message: { role: 'user', name: chatGPTName(activeUser?.name), content },
						id: chatId,
						promptId: 'ai-collaborator-output',
						accessToken: user?.accessToken,
					},
				},
			});
			setLoading(false);
			await refetchChatCollaborators();
			return response?.data?.chatGPTCall as string;
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const sendToronto = async (content: string, chatId?: string) => {
		setLoading(true);
		try {
			const response = await chatgptCall({
				variables: {
					userInput: {
						requestType: RequestType.torontoAiCollaboration,
						clientId: activeClientId,
						content,
						id: chatId,
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchChatCollaborators();
			setLoading(false);
			return response?.data?.chatGPTCall as string;
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const sendAiCollaborator = async (content: string, chatId?: string) => {
		setLoading(true);
		try {
			const response = await chatgptCall({
				variables: {
					userInput: {
						requestType: RequestType.stepAiCollaboration,
						clientId: activeClientId,
						message: { role: 'user', name: chatGPTName(activeUser?.name), content },
						id: chatId,
						workflowStep,
						workflowId,
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchChatCollaborators();
			setActiveChat(response?.data?.chatGPTCall as ChatCollaboration);
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	return {
		chats: sortedChats,
		setActiveDocumentId,
		activeChat,
		setActiveChat,
		newChatCollaboration,
		send,
		loading,
		setLoading,
		sendAiCollaborator,
		sendToronto,
		sendAiAgent,
		getSelectedDocumentsToWordText
	};
}
