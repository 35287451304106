import { useContext, useEffect, useState } from 'react';
import { CollaborationThread, Collaborator } from '../models/collaboration';
import { UserContext } from '../contexts/UserContext';
import { useMutation, useQuery } from '@apollo/client';
import { WORKFLOW_CALL_MUTATION } from '../GraphQL/callable-mutations';
import {
	GET_WORKFLOW_COLLABORATION_THREADS,
	GET_WORKFLOW_COLLABORATORS,
} from '../GraphQL/collaboration/queries';

export enum RequestType {
	add_collaborators = 'add_collaborators',
	remove_collaborators = 'remove_collaborators',
	add_collaboration_question = 'add_collaboration_question',
}
export default function useCollaboration(workflowId: string) {
	const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
	const [collaborationThreads, setCollaborationThreads] = useState<CollaborationThread[]>([]);
	const { activeUser } = useContext(UserContext);
	const [prevWfId, setPrevWfId] = useState('');
	const { user } = useContext(UserContext);
	const [workflowCall, { error: workflowCallError }] = useMutation(WORKFLOW_CALL_MUTATION);
	const { data: workflowCollaboratorsData, refetch: refetchworkflowCollaborators } = useQuery(
		GET_WORKFLOW_COLLABORATORS,
		{
			variables: {
				workflowId,
				clientIds: activeUser?.clientIds?.map((e) => e) ?? [],
				accessToken: user?.accessToken,
			},
			skip:
				!user?.accessToken ||
				!user?.uniqueId ||
				!(
					!!workflowId &&
					!!activeUser?.clientIds &&
					activeUser?.clientIds?.length > 0 &&
					(collaborators?.length == 0 || prevWfId != workflowId)
				),
		},
	);
	const {
		data: workflowCollaborationThreadssData,
		refetch: refetchWorkflowCollaborationThreads,
	} = useQuery(GET_WORKFLOW_COLLABORATION_THREADS, {
		variables: {
			workflowId,
			clientIds: activeUser?.clientIds?.map((e) => e) ?? [],
			accessToken: user?.accessToken,
		},
		skip:
			!user?.accessToken ||
			!user?.uniqueId ||
			!(
				!!workflowId &&
				!!activeUser?.clientIds &&
				activeUser?.clientIds?.length > 0 &&
				(collaborators?.length == 0 || prevWfId != workflowId)
			),
	});
	useEffect(() => {
		if (!!workflowCollaboratorsData?.getWorkflowCollaborators) {
			setCollaborators(workflowCollaboratorsData?.getWorkflowCollaborators as Collaborator[]);
		}
	}, [workflowCollaboratorsData]);

	useEffect(() => {
		if (!!workflowCollaborationThreadssData?.getWorkflowCollaborationThreads) {
			setCollaborationThreads(
				workflowCollaborationThreadssData?.getWorkflowCollaborationThreads as CollaborationThread[],
			);
		}
	}, [workflowCollaborationThreadssData]);

	useEffect(() => {
		if (!workflowId || !activeUser?.clientIds) {
			setCollaborationThreads([]);
			setCollaborators([]);
		}
		if (!!workflowId) {
			setCollaborationThreads((prev) => prev?.filter((i) => i?.workflowId === workflowId));
			setCollaborators((prev) => prev?.filter((i) => i?.workflowId === workflowId));
		}
	}, [activeUser?.clientIds, workflowId]);

	const addCollaborators = async (
		collaborators: Collaborator[],
		workflowId: string,
		clientId: string,
		stepName: string,
	) => {
		try {
			const res = await workflowCall({
				variables: {
					userInput: {
						requestType: RequestType.add_collaborators,
						collaborators,
						workflowId,
						clientId,
						chatId: stepName,
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchworkflowCollaborators({
				workflowId,
				clientIds: activeUser?.clientIds?.map((e) => e) ?? [],
				accessToken: user?.accessToken,
			});
			return true;
		} catch (err) {
			console.log(err);
			return err?.toString();
		}
	};

	const removeCollaborators = async (
		collaborators: Collaborator[],
		workflowId: string,
		clientId: string,
	) => {
		try {
			const res = await workflowCall({
				variables: {
					userInput: {
						requestType: RequestType.remove_collaborators,
						collaborators,
						workflowId,
						clientId,
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchworkflowCollaborators({
				workflowId,
				clientIds: activeUser?.clientIds?.map((e) => e) ?? [],
				accessToken: user?.accessToken,
			});
			return true;
		} catch (err) {
			console.log(err);
			return err?.toString();
		}
	};

	const addCollaborationQuestion = async (
		id: string,
		sender: string,
		suggestion: string,
		workflowId: string,
		clientId: string,
		stepName: string,
		selectedCollaboratorsForQuestion?: string[],
		dataFragmentId?: string
		
	) => {
		try {
			const res = await workflowCall({
				variables: {
					userInput: {
						requestType: RequestType.add_collaboration_question,
						id,
						sender,
						suggestion,
						workflowId,
						clientId,
						chatId: stepName,
						copyId: dataFragmentId,
						accessToken: user?.accessToken,
						selectedCollaboratorsForQuestion
					},
				},
			});
			refetchWorkflowCollaborationThreads({
				workflowId,
				clientIds: activeUser?.clientIds?.map((e) => e) ?? [],
				accessToken: user?.accessToken,
			});
		} catch (err) {
			console.log(err);
		}
	};

	return {
		addCollaborators,
		removeCollaborators,
		addCollaborationQuestion,
		collaborationThreads,
		collaborators,
	};
}
