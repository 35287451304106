import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCompanies from '../hooks/useCompanies';
import { Company, CompanyBasicInfo, Competitor, SuccessStories } from '../models/company';
import { Gallery } from '../models/product';
import { ActiveUser } from '../redux/active-item/active-itemSlice';

interface ICompanyContext {
	loading: boolean;
	activeCompany?: Company;
	successStories: SuccessStories[];
	setSuccessStories: Dispatch<SetStateAction<SuccessStories[]>>;
	upsertSuccessStory: (
		predicate: (a: SuccessStories, b: SuccessStories) => boolean,
		newItem: SuccessStories,
	) => void;
	removeSuccessStory: (value: SuccessStories) => void;
	competitors: Competitor[];
	setCompetitors: Dispatch<SetStateAction<Competitor[]>>;
	upsertCompetitors: (
		predicate: (a: Competitor, b: Competitor) => boolean,
		newItem: Competitor,
	) => void;
	removeCompetitors: (value: Competitor) => void;
	primaryLogo?: string;
	gallery?: Gallery[];
	setPrimaryLogo: Dispatch<SetStateAction<string>>;
	setGallery: Dispatch<SetStateAction<Gallery[]>>;
	basicInfo: CompanyBasicInfo;
	deleteCompany: (id: string) => Promise<void>;
	companies: Company[];
	setBasicInfo: Dispatch<SetStateAction<CompanyBasicInfo>>;
	saveCompany: (value: Partial<Company>) => void;

	fetchCompanyForUser: (user: ActiveUser) => Promise<Company | undefined>;
}

export const CompanyContext = createContext<ICompanyContext>({} as ICompanyContext);

export const CompanyProvider = ({ children }: { children: any }) => {
	const { id, tab } = useParams();

	const {
		loading,
		companies,
		deleteCompany,
		successStories,
		setSuccessStories,
		upsertSuccessStory,
		removeSuccessStory,
		primaryLogo,
		setPrimaryLogo,
		gallery,
		setGallery,
		fetchCompanyForUser,
		competitors,
		setCompetitors,
		upsertCompetitors,
		removeCompetitors,
		basicInfo,
		setBasicInfo,
		saveCompany,
	} = useCompanies();

	const activeCompany = companies?.[0];
	useEffect(() => {
		if (companies?.[0]) {
			if (companies?.[0]?.successStories) {
				setSuccessStories(companies?.[0]?.successStories);
			}
			if (companies?.[0]?.competitors) {
				setCompetitors(companies?.[0]?.competitors);
			}
			if (companies?.[0]?.gallery) {
				setGallery(companies?.[0]?.gallery);
			}
			if (companies?.[0]?.primaryLogo) {
				setPrimaryLogo(companies?.[0]?.primaryLogo);
			}
		}
	}, [companies]);
	return (
		<CompanyContext.Provider
			value={{
				loading: loading,
				activeCompany,
				successStories,
				setSuccessStories,
				upsertSuccessStory,
				removeSuccessStory,
				primaryLogo,
				setPrimaryLogo,
				gallery,
				setGallery,
				fetchCompanyForUser,
				competitors,
				setCompetitors,
				upsertCompetitors,
				removeCompetitors,
				deleteCompany,
				companies,
				basicInfo,
				setBasicInfo,
				saveCompany,
			}}>
			{children}
		</CompanyContext.Provider>
	);
};
