import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from './bootstrap/OffCanvas';
import Alert from './bootstrap/Alert';
import { NotificationsContext } from '../contexts/NotificationContext';
import { Notification } from '../models/models';
import striptags from 'striptags';
import moment from 'moment';
import Progress from './bootstrap/Progress';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from './Avatar';
import { useStartTyping } from 'react-use';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { BsSend } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { shorten } from '../util/util';

interface Props {
	offcanvasStatus: boolean;
	setOffcanvasStatus: Dispatch<SetStateAction<boolean>>;
}

interface User {
	name: string;
	userProfile: string;
}

interface Chat {
	message: string;
	user: User;
	daysAgo: string;
	assetImage: string;
}

interface Group {
	groupName: string;
	groupChats: Chat[];
}

function AssetChatCanvas({ offcanvasStatus, setOffcanvasStatus }: Props) {
	const [openChat, setOpenChat] = useState(false);
	const [activeGroup, setActiveGroup] = useState('');
	const chatGroups: Group[] = [
		{
			groupName: 'Construction Crew',
			groupChats: [
				{
					message:
						'We just completed the foundation for the new site! Everything is going according to plan.',
					user: {
						name: 'John Doe',
						userProfile: 'https://docs.material-tailwind.com/img/face-1.jpg',
					},
					daysAgo: '2 days ago',
					assetImage:
						'https://mbstgstoragez8cs4.blob.core.windows.net/default-container/f7960d39-8a6a-450b-aa38-3d0bf610494e/17216137110962dd21b9f-fed7-4c95-b80e-bcc8412d756a.jpg?sv=2024-05-04&spr=https&st=2024-07-22T02%3A01%3A52Z&se=2079-07-09T02%3A01%3A52Z&sr=b&sp=rw&sig=BhqtaRXdJj29xY39P2nM4p2FXQGKeOC7QJg9RlFSU5U%3D',
				},
				{
					message:
						'The crane will be delivered tomorrow morning. Ensure the area is clear for setup.',
					user: {
						name: 'Anna Smith',
						userProfile: 'https://docs.material-tailwind.com/img/face-3.jpg',
					},
					daysAgo: '1 day ago',
					assetImage:
						'https://mbstgstoragez8cs4.blob.core.windows.net/default-container/f7960d39-8a6a-450b-aa38-3d0bf610494e/17216137110962dd21b9f-fed7-4c95-b80e-bcc8412d756a.jpg?sv=2024-05-04&spr=https&st=2024-07-22T02%3A01%3A52Z&se=2079-07-09T02%3A01%3A52Z&sr=b&sp=rw&sig=BhqtaRXdJj29xY39P2nM4p2FXQGKeOC7QJg9RlFSU5U%3D',
				},
			],
		},
		{
			groupName: 'Safety Team',
			groupChats: [
				{
					message:
						'We need to double-check the safety harnesses before the next shift starts. There was an issue reported today.',
					user: {
						name: 'Mike Johnson',
						userProfile: 'https://docs.material-tailwind.com/img/face-4.jpg',
					},
					daysAgo: '3 days ago',
					assetImage:
						'https://mbstgstoragez8cs4.blob.core.windows.net/default-container/f7960d39-8a6a-450b-aa38-3d0bf610494e/17216137110962dd21b9f-fed7-4c95-b80e-bcc8412d756a.jpg?sv=2024-05-04&spr=https&st=2024-07-22T02%3A01%3A52Z&se=2079-07-09T02%3A01%3A52Z&sr=b&sp=rw&sig=BhqtaRXdJj29xY39P2nM4p2FXQGKeOC7QJg9RlFSU5U%3D',
				},
				{
					message:
						'All workers, please remember to sign in before entering the site. We need to keep accurate records.',
					user: {
						name: 'Lisa Brown',
						userProfile: 'https://docs.material-tailwind.com/img/face-5.jpg',
					},
					daysAgo: '5 days ago',
					assetImage:
						'https://mbstgstoragez8cs4.blob.core.windows.net/default-container/f7960d39-8a6a-450b-aa38-3d0bf610494e/172131421237142b1265d-703b-4442-bab6-148b79b54542.jpg?sv=2024-05-04&spr=https&st=2024-07-18T14%3A50%3A14Z&se=2079-07-05T14%3A50%3A14Z&sr=b&sp=rw&sig=mkydcv3opQD%2F8jGdyGUNO2MThFyvJc3Br7JJDhibMIk%3D',
				},
			],
		},
		{
			groupName: 'Electrical Engineers',
			groupChats: [
				{
					message:
						'The wiring in the east wing is complete. We can proceed with the testing tomorrow.',
					user: {
						name: 'Tom Wilson',
						userProfile:
							'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1760&q=80',
					},
					daysAgo: '4 days ago',
					assetImage:
						'https://mbstgstoragez8cs4.blob.core.windows.net/default-container/f7960d39-8a6a-450b-aa38-3d0bf610494e/17216137110962dd21b9f-fed7-4c95-b80e-bcc8412d756a.jpg?sv=2024-05-04&spr=https&st=2024-07-22T02%3A01%3A52Z&se=2079-07-09T02%3A01%3A52Z&sr=b&sp=rw&sig=BhqtaRXdJj29xY39P2nM4p2FXQGKeOC7QJg9RlFSU5U%3D',
				},
				{
					message:
						'Please make sure all the circuit breakers are labeled properly. We had a mix-up last time.',
					user: {
						name: 'Sam Taylor',
						userProfile:
							'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
					},
					daysAgo: '3 days ago',
					assetImage:
						'https://mbstgstoragez8cs4.blob.core.windows.net/default-container/f7960d39-8a6a-450b-aa38-3d0bf610494e/172129336508394713a00-acc7-4cbd-9f47-45c1ed918b2a.jpg?sv=2024-05-04&spr=https&st=2024-07-18T09%3A02%3A47Z&se=2079-07-05T09%3A02%3A47Z&sr=b&sp=rw&sig=DUM0UZuu9iVhlaIZPU77BJ2ge9TON9xEoMlbz7w%2Bpg0%3D',
				},
			],
		},
		{
			groupName: 'Plumbing Crew',
			groupChats: [
				{
					message:
						'The main water line has been installed. We need to pressure test it before we move on.',
					user: {
						name: 'Joe Doe',
						userProfile: 'https://docs.material-tailwind.com/img/face-1.jpg',
					},
					daysAgo: '6 days ago',
					assetImage:
						'https://mbstgstoragez8cs4.blob.core.windows.net/default-container/f7960d39-8a6a-450b-aa38-3d0bf610494e/17216137110962dd21b9f-fed7-4c95-b80e-bcc8412d756a.jpg?sv=2024-05-04&spr=https&st=2024-07-22T02%3A01%3A52Z&se=2079-07-09T02%3A01%3A52Z&sr=b&sp=rw&sig=BhqtaRXdJj29xY39P2nM4p2FXQGKeOC7QJg9RlFSU5U%3D',
				},
				{
					message:
						'Remember to check the fittings in the basement. We had a small leak last week.',
					user: {
						name: 'Kyle Anderson',
						userProfile:
							'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80',
					},
					daysAgo: '5 days ago',
					assetImage:
						'https://mbstgstoragez8cs4.blob.core.windows.net/default-container/f7960d39-8a6a-450b-aa38-3d0bf610494e/17231205980264805bce1-d0cb-4ce2-8fda-3ca6a02b182c.jpg?sv=2024-05-04&spr=https&st=2024-08-08T12%3A36%3A43Z&se=2079-07-26T12%3A36%3A43Z&sr=b&sp=rw&sig=19qpnblJ%2F70itac7I4cXDRc84HR2uypnWpfR0JmgLIE%3D',
				},
			],
		},
	];
	const handleOpenGroup = (groupName: string, open: boolean) => {
		setActiveGroup(groupName);
		setOpenChat(open);
	};
	const GroupCard = ({ group }: { group: Group }) => {
		const latestChat = group?.groupChats?.[group.groupChats.length - 1];
		return (
			<div
				className='chat-card p-4 mb-1 cursor-pointer'
				onClick={() => handleOpenGroup(group?.groupName, true)}>
				<div className='d-flex gap-3'>
					<div className='col-9'>
						<p style={{ fontSize: 15 }}>{shorten(latestChat?.message, 120)}</p>
						<div className='chat-details d-flex align-items-center gap-3'>
							<Avatar
								src={latestChat?.user?.userProfile}
								className='rounded-circle'
								shadow='sm'
								size={35}
								style={{ objectFit: 'cover' }}
							/>
							<span className='text-primary'> {latestChat?.user?.name}</span>
							<span className='text-muted fs-12'>{latestChat.daysAgo}</span>
						</div>
					</div>
					<div className='col-3 flex-shrink-0'>
						<img
							alt='asset-image'
							style={{
								height: 85,
								width: '90%',
								objectFit: 'cover',
								borderRadius: 10,
							}}
							className='img-fluid '
							src={latestChat?.assetImage}
						/>
					</div>
				</div>
			</div>
		);
	};

	const ChatCard = ({ chat }: { chat: Chat }) => {
		const [showReplyInput, setShowReplyInput] = useState(false);
		const [message, setMessage] = useState('');
		return (
			<div className='px-4 mb-4'>
				<div className='chat-details d-flex align-items-center gap-3 mb-3'>
					<Avatar
						src={chat?.user?.userProfile}
						className='rounded-circle'
						shadow='sm'
						size={35}
						style={{ objectFit: 'cover' }}
					/>
					<span className='text-primary'> {chat?.user?.name}</span>
					<span className='text-muted fs-12'>{chat?.daysAgo}</span>
				</div>
				<p className='fs-5'>{chat?.message}</p>
				<div>
					{!showReplyInput ? (
						<span
							className='text-muted cursor-pointer'
							onClick={() => setShowReplyInput(true)}>
							Reply
						</span>
					) : (
						<div className='reply-container'>
							<textarea
								className='custom-styled-textarea mb-3 p-3'
								rows={5}
								value={message}
								onChange={(e) => setMessage(e?.currentTarget?.value)}
							/>
							<div className='d-flex align-items-center gap-4'>
								<p
									className='text-muted cursor-pointer mb-0'
									onClick={() => setShowReplyInput(false)}>
									Cancel
								</p>
								<p
									className='cursor-pointer text-primary mb-0'
									onClick={() => setShowReplyInput(false)}>
									Send <FiSend className='ms-1' />
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	};
	const GroupChatCard = () => {
		const group = chatGroups?.find((g) => g?.groupName == activeGroup);
		const latestChat = group?.groupChats?.[group.groupChats.length - 1];

		return (
			<div className='group-chat-card'>
				<div className='d-flex gap-3 group-header mb-4'>
					<div className='flex-shrink-0'>
						<MdOutlineKeyboardBackspace
							size={24}
							className='cursor-pointer'
							onClick={() => handleOpenGroup('', false)}
						/>
					</div>
					<div className='col-8'>
						<div className='chat-details d-flex align-items-center gap-3 mb-3'>
							<Avatar
								src={latestChat?.user?.userProfile}
								className='rounded-circle'
								shadow='sm'
								size={35}
								style={{ objectFit: 'cover' }}
							/>
							<span className='text-primary'> {latestChat?.user?.name}</span>
							<span className='text-muted fs-12'>{latestChat?.daysAgo}</span>
						</div>
						<p className='fs-4'>{latestChat?.message}</p>
					</div>
					<div className='col-3 flex-shrink-0'>
						<img
							alt='asset-image'
							style={{
								// height: 85,
								width: '90%',
								objectFit: 'cover',
								borderRadius: 10,
							}}
							className='img-fluid '
							src='https://mbstgstoragez8cs4.blob.core.windows.net/default-container/f7960d39-8a6a-450b-aa38-3d0bf610494e/17216137110962dd21b9f-fed7-4c95-b80e-bcc8412d756a.jpg?sv=2024-05-04&spr=https&st=2024-07-22T02%3A01%3A52Z&se=2079-07-09T02%3A01%3A52Z&sr=b&sp=rw&sig=BhqtaRXdJj29xY39P2nM4p2FXQGKeOC7QJg9RlFSU5U%3D'
						/>
					</div>
				</div>
				<div className='group-body'>
					{group?.groupChats?.map((chat, index) =>
						index != group.groupChats.length - 1 ? <ChatCard chat={chat} /> : <></>,
					)}
				</div>
			</div>
		);
	};
	return (
		<OffCanvas
			id='assetChatCanvas'
			titleId='offcanvasExampleLabel'
			placement='end'
			isOpen={offcanvasStatus}
			setOpen={setOffcanvasStatus}
			style={{ zIndex: 999999999 }}>
			<OffCanvasHeader setOpen={setOffcanvasStatus}>
				<OffCanvasTitle id='offcanvasExampleLabel'>Chats</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody>
				{!openChat ? (
					chatGroups.map((item, index) => <GroupCard group={item} />)
				) : (
					<GroupChatCard />
				)}
			</OffCanvasBody>
		</OffCanvas>
	);
}

export default AssetChatCanvas;
