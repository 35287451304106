import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import {
	Company,
	Image,
	Product,
	User,
	Document,
	DataFragment,
	SKILLBASEFRAGMENT_TYPE,
	skillIconList_TYPE,
} from '../../../models/models';
import { Modals } from '../../../components/Modals';
import { useModal } from '@ebay/nice-modal-react';
import DatalakeModal from '../datalake/datalakeModal';
import Icon from '../../../components/icon/Icon';
import { Link, useNavigate } from 'react-router-dom';
import FolderBreadCrumb from '../datalake/FolderBreadCrumb';
import Page from '../../../layout/Page/Page';
import ScrollContainer from 'react-indiana-drag-scroll';
import intl from 'react-intl-universal';
import Input from '../../../components/bootstrap/forms/Input';
import UserCard from './userCard';
import styled from 'styled-components';
import userProfiles from './users';
import UserForm from './userForm';
import { GetAllUsersContext } from '../../../contexts/getAllUsersContext';
import Spinner from '../../../components/bootstrap/Spinner';
import { useInView } from 'react-intersection-observer';
import { ClientContext } from '../../../contexts/ClientContext';

function SkillsBase() {
	const createfragments: SKILLBASEFRAGMENT_TYPE[] = ['internal', 'external'];
	const listIcons: skillIconList_TYPE[] = ['GroupAdd', 'PersonAddAlt'];
	const [activeFilter, setActiveFilter] = useState('All');
	const [openModal, setOpenModal] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const [tempSearch, setTempSearch] = useState('');
	const { getPeople, peopleUsers, loading, loadMore, setSearch } = useContext(GetAllUsersContext);
	const [selectedFragmentType, setSelectedFragmentType] = useState<SKILLBASEFRAGMENT_TYPE>();
	const { ref, inView } = useInView({
		threshold: 1.0, // Trigger fetchMore when the bottom is visible
	});

	useEffect(() => {
		if (inView && !loading) {
			loadMore();
		}
	}, [inView]);

	const suggestions = [
		{ name: 'William Scott', role: 'Project Manager' },
		{ name: 'Jane Smith', role: 'Site Supervisor' },
		{ name: 'Bob Johnson', role: 'Civil Engineer' },
		{ name: 'Alice Brown', role: 'Safety Officer' },
		{ name: 'Charlie Davis', role: 'Construction Worker' },
	];
	const handleInputChange = async () => {
		setSearch(tempSearch);
		// await getPeople(tempSearch);
		// setShowDropdown(e.target.value.length > 0);
	};

	const handleSuggestionClick = (suggestion: { name: string; role: string }) => {
		setSearch(suggestion.name);
		setShowDropdown(false);
	};

	const { dataFragments, currentFolder, folders, suggestedDataFragements, allDataFragments } =
		useContext(DatalakeContext);
	const { activeClientId } = useContext(ClientContext);
	const [filteredFragments, setFilteredFragments] = useState(
		dataFragments?.filter((f) => f?.clientId === activeClientId),
	);
	const [showList, setShowList] = useState(false);

	useEffect(() => {
		setOpenModal(true);
	}, []);
	const datalakeModal = useModal(Modals.DATALAKE);
	const { setFragmentId: setProductId } = useContext(DatalakeContext);
	console.log('Filtered fragments', filteredFragments);
	const companies = filteredFragments?.filter((f) => f.type == 'company') as Company[];
	const users = filteredFragments?.filter((f) => f.type == 'user') as User[];
	const products = filteredFragments?.filter((f) => f.type == 'product') as Product[];
	const images = filteredFragments?.filter((f) => f.type == 'image') as Image[];
	const leadsList = filteredFragments?.filter((f) => f.type == 'lead') as DataFragment[];
	const contentsList = filteredFragments?.filter((f) => f.type == 'content') as DataFragment[];
	const documents = filteredFragments?.filter((f) => f.type == 'document') as Document[];
	console.log('leadsss', leadsList);
	console.log('contents', contentsList);
	const filteredData: DataFragment[] = useMemo(() => {
		switch (activeFilter) {
			case 'All':
				return [...companies, ...users, ...products, ...images, ...documents];
			case 'Company':
				return companies;
			case 'People':
				return users;
			case 'Products':
				return products;
			case 'Images':
				return images;
			case 'Documents':
				return documents;
			default:
				return [];
		}
	}, [activeFilter, companies, users, products, images, documents]);

	const navigate = useNavigate();

	const handleNewButtonClick = () => {
		setShowList(!showList); // Toggle the state
	};

	return (
		<div className='sheet-modal mt-0 overflow-hidden'>
			<Sheet
				isOpen={openModal}
				onClose={() => {}}
				snapPoints={[0.85]}
				disableDrag={true}
				className='child-sheet'
				style={{ zIndex: 99 }}>
				<Sheet.Container>
					<Sheet.Header className='child-sheet-header' />
					<Sheet.Content className='child-sheet-content'>
						<div style={{ width: '100vw', height: '100vh', overflowY: 'scroll' }}>
							<Page
								container={`${window.innerWidth <= 600 ? 'xl' : 'fluid'}`}
								className='flex-grow-0 '>
								<div className='module-optios-container border px-md-5 pt-md-4'>
									<div className='position-relative'>
										<h2 className='fw-normal fs-1 mt-3 mb-4 heading-text'>
											<span className='text-muted'>Create New</span>
										</h2>
										<Link to='/'>
											<div className='cursor-pointer close-icon'>
												<Icon icon='Close' className='theme-gray-text' />
											</div>
										</Link>
									</div>

									<div className='module-options-container d-flex align-items-center  mt-4'>
										<ScrollContainer
											horizontal={true}
											className='scroll-container '>
											<div className='modules-list w-100 d-flex gap-5 '>
												{createfragments?.map((item, index) => (
													<div
														className='module-item position-relative'
														onClick={() => {
															setSelectedFragmentType(item);
															setProductId('new');
															datalakeModal.show();
														}}
														key={item}>
														<div className='workflow-type-container cursor-pointer d-flex flex-column align-items-center'>
															<div className='icon-placeholder d-flex align-items-center justify-content-center'>
																<Icon
																	icon={listIcons[index]}
																	size={'2x'}
																	className='icon'
																/>
															</div>
															<p className='type-name'> {item}</p>
														</div>
													</div>
												))}
											</div>
										</ScrollContainer>
									</div>
								</div>
							</Page>
							<div className=' d-flex px-2 px-md-5 pt-4 flex-column flex-lg-row justify-content-between align-items-center col-md-8 mb-2 mb-lg-0 '>
								<div className=' col-lg-12 mb-2 mb-lg-0  h6 d-flex gap-2 align-items-center'>
									<p className='mb-0 uppercase-title '>
										{intl.get('app.skill.plural.name')}
									</p>
									<label className='border-0 bg-transparent cursor-pointer'>
										<Icon icon='ChevronRight' color='primary' />
									</label>
									<FolderBreadCrumb />
								</div>
							</div>
							<div className='px-3 py-4'>
								<div
									className='d-flex align-items-center col-3 search-container border position-relative'
									data-tour='search'>
									<Input
										id='searchInput'
										type='search'
										className='border-0 shadow-none bg-transparent'
										placeholder='Search...'
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setTempSearch(e.target.value)
										}
										value={tempSearch}
										autoComplete='off'
										onKeyDown={(e) => {
											if (e?.key == 'Enter') {
												handleInputChange();
											}
										}}
									/>
									<label className='border-0 bg-transparent cursor-pointer'>
										<Icon
											icon='Search'
											size='2x'
											color='primary'
											onClick={handleInputChange}
										/>
									</label>
									{showDropdown && (
										<div className='position-absolute bg-white w-100 search-dropdown'>
											<ul className='mb-0'>
												{suggestions.map((suggestion, index) => (
													<li
														key={index}
														className='p-3'
														onClick={() =>
															handleSuggestionClick(suggestion)
														}>
														<Icon icon='Search' size='lg' />
														{suggestion.name}{' '}
														<span>&#x2022; Internal &#x2022;</span>{' '}
														<span>{suggestion.role}</span>
													</li>
												))}
												<li
													className='p-3 cursor-pointer border-top text-center d-flex align-items-center justify-content-center'
													onClick={() => {
														handleSuggestionClick({
															name: 'show all results',
															role: 'all',
														});
													}}>
													Show all results
												</li>
											</ul>
										</div>
									)}
								</div>
							</div>
							<UserContainer className='p-3 w-100 h-100'>
								<div className='cards-wrapper'>
									{peopleUsers?.map((user, index) => (
										<UserCard
											user={user}
											ref={index === peopleUsers.length - 1 ? ref : null}
										/>
									))}
								</div>
							</UserContainer>
							{loading ? <Spinner /> : <></>}
						</div>
					</Sheet.Content>
				</Sheet.Container>
			</Sheet>
			<DatalakeModal id={Modals.DATALAKE}>
				<UserForm selectedFragmentType={selectedFragmentType} />
			</DatalakeModal>
		</div>
	);
}

export default SkillsBase;

const UserContainer = styled.div`
	.cards-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;

		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}
		@media (min-width: 992px) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media (min-width: 1300px) {
			grid-template-columns: repeat(5, 1fr);
		}
	}
`;
