// import axios from "axios";
import { decodeToken } from 'react-jwt';

export async function getExpiryAccessToken(token?: string) {
	if (!token) return -1;
	try {
		const decodedToken = await decodeToken(token);
		if (!decodedToken) {
			return -1;
		}
		return (decodedToken as any)?.exp * 1000;
	} catch (error) {
		return -1;
	}
}
