import React, { useContext } from 'react';
import { Company, DataFragment, Folder, Image } from '../../../models/models';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FaPlus, FaFolder, FaFolderPlus } from 'react-icons/fa';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import { useModal } from '@ebay/nice-modal-react';
import { Modals } from '../../../components/Modals';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { CardActions } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';

function Folders({ folders, isHorizontal }: { folders: Folder[]; isHorizontal: boolean }) {
	const { openFolder } = useContext(DatalakeContext);
	const datalakeModal = useModal(Modals.DATALAKE);
	return (
		<div className='px-2 px-md-5 mb-4'>
			<p className='uppercase-title'>Folders</p>
			{isHorizontal ? (
				<div
					className='row mx-0 justify-content-center align-items-center flex-wrap col-mb-1  col-6 col-md-4 col-lg-2 mb-4 '
					style={{ width: 'auto', height: '50%', marginLeft: '-45px' }}>
					<div className='container' style={{ marginLeft: '70px' }}>
						<div className='row'>
							{folders?.map((folder) => (
								<div
									key={folder.path + folder.name}
									className='d-flex align-items-center position-relative  white-card border  cursor-pointer justify-content-center col-6 col-md-4 col-lg-2 mb-4'
									onClick={() => {
										openFolder(folder.path);
									}}
									style={{
										height: '55px',
										width: '250px',
										padding: '20px',
										whiteSpace: 'nowrap',
										flex: 'wrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										fontSize: '1em',
										margin: '10px',
										marginLeft: '5px',
										borderRadius: '15px',
									}}>
									<div className='text-center'>
										<FaFolder size={35} color='#71788B' className='mt-2' />
									</div>
									<div className='px-3 pb-4 flex-grow-1'>
										<p
											className='shop-description card-title-text fs-5 mb-0 mt-5 px-2'
											title={folder.name}>
											{folder.name}
										</p>
										<p className='shop-description small-sub-title fs-7 mb-3  px-2'>
											{folder.fileCount} item(s)
										</p>
									</div>
									<div className='ml-7'>
										{/* <Icon icon='MoreVert' size={'2x'} /> */}
										<CardActions>
											<Dropdown>
												<DropdownToggle hasIcon={false}>
													<Button
														icon='MoreVert'
														// color={themeStatus}
														shadow='default'
														aria-label='Edit'
														className='p-0'
														z-index='999'
													/>
												</DropdownToggle>
												<DropdownMenu isAlignmentEnd>
													<DropdownItem>
														<Button icon='EditNote'>Edit</Button>
													</DropdownItem>
													<DropdownItem isDivider />
													<DropdownItem>
														<Button icon='Delete'>Delete</Button>
													</DropdownItem>
												</DropdownMenu>
											</Dropdown>
										</CardActions>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			) : (
				<div
					className='d-flex justify-content-between align-items-center flex-wrap col-mb-1'
					style={{ width: 'auto', height: '50%', marginLeft: '-45px' }}>
					<div className='container' style={{ marginLeft: '10px' }}>
						<div className='row'>
							{folders?.map((folder) => (
								<div
									key={folder.path + folder.name}
									className='row-item position-relative bg-dark d-flex  align-items-center col-6 col-md-4 col-lg-2 mb-4'
									onClick={() => {
										openFolder(folder.path);
									}}
									style={{
										height: '45px',
										width: '180px',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										fontSize: '1em',
										margin: '5px',
									}}>
									<div className='text-center'>
										<FaFolder size={28} color='#71788B' className='mt-2' />
									</div>
									<div className='px-3 pb-4 flex-grow-1'>
										<p
											className='shop-description text-white fs-6 mb-0 mt-5 px-2'
											title={folder.name}>
											{folder.name}
										</p>
										<p className='shop-description text-gray text-muted fs-8 mb-3  px-2'>
											{folder.fileCount} item(s)
										</p>
									</div>
									<div className='ml-5'>
										{/* <Icon icon='MoreVert' size={'2x'} /> */}
										<CardActions>
											<Dropdown>
												<DropdownToggle hasIcon={false}>
													<Button
														icon='MoreVert'
														// color={themeStatus}
														shadow='default'
														aria-label='Edit'
														className='p-0'
														z-index='999'
													/>
												</DropdownToggle>
												<DropdownMenu isAlignmentEnd>
													<DropdownItem>
														<Button icon='EditNote'>Edit</Button>
													</DropdownItem>
													<DropdownItem isDivider />
													<DropdownItem>
														<Button icon='Delete'>Delete</Button>
													</DropdownItem>
												</DropdownMenu>
											</Dropdown>
										</CardActions>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Folders;
