
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: '73e9ad1a-2123-41d2-8c78-763e58b1c140',
		// enableAutoRouteTracking: true,
		extensions: [reactPlugin]
	}
});
appInsights.loadAppInsights();

// appInsights.trackPageView();

appInsights.addTelemetryInitializer((envelope) => {
	if (envelope?.baseType === "PageviewData") {
	  console.log('PageViewData',envelope);
	}
  });

// Function to track workflow visits
const trackWorkflowVisit = (workflowId: string) => {
    appInsights.trackEvent({ name: 'WorkflowVisit', properties: { workflowId } });
  };
// Function to track workflow Creation
const trackWorkflowCreated = (workflowId: string) => {
    appInsights.trackEvent({ name: 'WorkflowCreated', properties: { workflowId } });
  };


export { reactPlugin, appInsights, trackWorkflowVisit, trackWorkflowCreated };