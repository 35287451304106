import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, authMenu } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import AssetManager from '../pages/presentation/mission-builder/asset-manager';

const headers: RouteProps[] = [
	{ path: authMenu.login.path, element: null },
	{ path: authMenu.signUp.path, element: null },
	{ path: authMenu.page404.path, element: null },
	{
		path: dashboardPagesMenu.workflows.path,
		element: <DashboardHeader />,
	},
	{
		path: 'mission-builder/:id',
		element: <DashboardHeader />,
	},
	{
		path: '/workflow/:id',
		element: <DashboardHeader />,
	},
	{
		path: dashboardPagesMenu.datalake.path,
		element: <DashboardHeader />,
	},
	{
		path: dashboardPagesMenu.workspace.path,
		element: <DashboardHeader />,
	},
	{
		path: '/',
		element: <DashboardHeader />,
	},
	{
		path: '/mission-analytics',
		element: <DashboardHeader />,
	},
	{
		path: '/sb',
		element: <DashboardHeader />,
	},
	{
		path: '/user-profile/:id',
		element: <DashboardHeader />,
	},
	{
		path: '/doc-viewer/:id',
		element: <DashboardHeader />,
	},
	{
		path: '/disseminate/:id',
		element: (
			<div className='d-none'>
				<DashboardHeader />
			</div>
		),
	},
	{
		path: `*`,
		element: null,
	},
];

export default headers;
