import React, { ReactNode, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Popovers from '../../../components/bootstrap/Popovers';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { dashboardPagesMenu, authMenu } from '../../../menu';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { t } from 'i18next';
import logo from '../../../assets/img/ai/Frame.png';
import darkLogo from '../../../assets/img/ai/Frame-black.png';
import bechtelLogo from '../../../assets/img/ai/bechtel-logo.png';
import newbechtelLogo from '../../../assets/img/ai/1200px-Bechtel_logo.svg.png';
import { ModuleContext } from '../../../contexts/ModuleContext';
import { NotificationsContext } from '../../../contexts/NotificationContext';
import { Client, Notification } from '../../../models/models';
import { ClientContext } from '../../../contexts/ClientContext';
import Icon from '../../../components/icon/Icon';
import Swal from 'sweetalert2';
import ThemeContext from '../../../contexts/ThemeContext';
import AICollaboratorChat from '../../../components/AICollaboratorChat';
import classNames from 'classnames';
import { useTour } from '@reactour/tour';
import NotificationCanvas from '../../../components/NotificationCanvas';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { WorkflowContext } from '../../../contexts/WorkflowContext';
import intl from 'react-intl-universal';
import { msalLogout } from '../../../Azure/login';
import { IsAIEnabled, shorten } from '../../../util/util';
import AssetChatCanvas from '../../../components/AssetChatCanvas';
const DashboardHeader = () => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { id } = useParams();
	const { setIsOpen } = useTour();
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { i18n } = useTranslation();
	const { salesProfessionalInfo } = useContext(UserContext);
	const { activeUser, user } = useContext(UserContext);

	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});
	const navigate = useNavigate();
	const {
		clients,
		activeClient,
		activeClientId,
		setActiveClientId,
		createNewClient,
		removeClient,
		setActiveClient,
	} = useContext(ClientContext);
	const { modules, licensedModules, setSelectedModule, selectedModule, workflows } =
		useContext(ModuleContext);
	const workflow = workflows?.find((w) => w.id === id);
	const activeModule = modules?.find((m) => m?.id === selectedModule);
	const { notifications, loading, readNotification } = useContext(NotificationsContext);
	const {
		openHelpTab,
		setOpenHelpTab,
		offcanvasStatus,
		setOffcanvasStatus,
		assetChatCanvasStatus,
		setAssetChatCanvasStatus,
	} = useContext(ThemeContext);
	const [creatingCompany, setCreatingCompany] = useState(false);
	const [activeMenu, setActiveMenu] = useState(intl.get('app.project.plural.name'));
	const [expand, setExpand] = useState(false);
	const filterMenu = [
		{
			name: intl.get('app.skill.plural.name'),
			link: '/sb',
			dataTourText: intl.get('app.skill.plural.name'),
		},
		{
			name: intl.get('app.datalake.name'),
			link: '/kb',
			dataTourText: intl.get('app.datalake.name'),
		},
		{
			name: intl.get('app.project.plural.name'),
			link: '/',
			dataTourText: intl.get('app.project.plural.name'),
		},
	];
	const clickNotification = async (item: Notification) => {
		await readNotification(item?.id);
	};
	useEffect(() => {
		if (window) {
			window.localStorage.setItem('activeClientId', activeClientId);
		}
	}, [activeClientId]);
	const onCompanySelect = (id: string) => {
		setActiveClientId(id);
		navigate('/');
	};
	const createNewCompany = async () => {
		setCreatingCompany(true);
		if (!!activeUser?.id) {
			const userResponce = await Swal.fire({
				title: 'Confirmation',
				text: `Do you want to create a new Workspace?`,
				allowOutsideClick: true,
				confirmButtonText: 'Yes',
				confirmButtonColor: 'blue',
				cancelButtonColor: 'secondary',
				cancelButtonText: 'No',
				showCancelButton: true,
				showLoaderOnConfirm: true,
				preConfirm: () => {
					return new Promise<void>((resolve) => {
						setTimeout(() => {
							resolve();
						}, 3000);
					});
				},
			});
			console.log('user resss', userResponce);
			if (!!userResponce?.isConfirmed) {
				const workspace = (await createNewClient()) as { data: Client } | false;
				if (workspace && workspace?.data?.id) {
					setActiveClientId(workspace?.data?.id);
					setActiveClient(workspace?.data);
					navigate('/');
					console.log('workspace response', workspace);
				}
			}
		}
	};

	const location = useLocation();
	useEffect(() => {
		if (creatingCompany) {
			setCreatingCompany(false);

			navigate('/workspace');
		}
	}, [activeClient]);
	const inProgressNotification = notifications?.find(
		(notification) =>
			!!notification?.totalRequestCount &&
			typeof notification?.processedRequestCount == 'number' &&
			(notification?.processedRequestCount / notification?.totalRequestCount) * 100 < 100,
	);
	return (
		<Header>
			<HeaderLeft className='d-flex flex-column flex-lg-row align-items-lg-center gap-3 gap-lg-0'>
				{/* <Search /> */}
				<div onClick={() => navigate('/')} className='cursor-pointer'>
					<img
						srcSet={
							!!activeClient?.logoImgUrl
								? activeClient?.logoImgUrl
								: !!location?.pathname?.includes('mission-builder')
								? ''
								: darkModeStatus
								? logo
								: darkLogo
						}
						src={
							!!activeClient?.logoImgUrl
								? activeClient?.logoImgUrl
								: darkModeStatus
								? logo
								: darkLogo
						}
						alt='logo'
						loading='lazy'
						style={{
							width: '50px',
							height: '50px',
							borderRadius: '50px',
							objectFit: 'contain',
						}}
						className='btn-only-icon'
					/>
				</div>
				<p
					onClick={() => navigate('/')}
					className='fs-16 mb-0 black-text me-0 cursor-pointer '>
					{activeClient?.name}
				</p>
				<label className='border-0 bg-transparent cursor-pointer'>
					<Icon icon='ChevronRight' size='2x' />
				</label>
				<p className='fs-16 mb-0 black-text me-0 cursor-pointer'>
					{location?.pathname == dashboardPagesMenu?.workspace?.path
						? 'Workspaces'
						: activeModule?.name}
				</p>
				<Dropdown
					className={
						location?.pathname == dashboardPagesMenu?.workspace?.path ? 'd-none' : ''
					}>
					<DropdownToggle hasIcon={false}>
						<label
							className='border-0 bg-transparent cursor-pointer'
							onClick={() => setExpand(!expand)}>
							{!expand ? (
								<Icon icon={'Keyboard Arrow Right'} size='2x' />
							) : (
								<Icon icon={'Keyboard Arrow Down'} size='2x' />
							)}
						</label>
					</DropdownToggle>
					<DropdownMenu>
						{licensedModules?.map((m, index) => (
							<DropdownItem key={index}>
								<Button
									icon={'Arrow Right'}
									onClick={() => {
										setSelectedModule(m?.id);
									}}>
									{modules?.filter((i) => m.id === i.id).map((j) => j.name)}
								</Button>
							</DropdownItem>
						))}
					</DropdownMenu>
				</Dropdown>
				<p className='fs-16 mb-0 black-text me-0 '>{shorten(workflow?.name ?? '', 20)}</p>
			</HeaderLeft>

			<HeaderRight>
				<div className='row g-3 align-items-center'>
					{/* <div className='col-auto'>
						<Popovers
							title='DashboardHeader.tsx'
							desc={<code>src/pages/_layout/_headers/DashboardHeader.tsx</code>}>
							HeaderRight
						</Popovers>
						<code className='ps-3'>DashboardHeader.tsx</code>
					</div> */}
					{/* Tour Modal */}
					{localStorage.getItem('tourModalStarted') === 'shown' && (
						<div className='col-auto position-relative'>
							<Popovers trigger='hover' desc='Start the "Facit" tour'>
								<Button
									{...styledBtn}
									icon='Tour'
									onClick={() => setIsOpen(true)}
									aria-label='Start the "Facit" tour'
								/>
							</Popovers>
							<Icon
								icon='Circle'
								className={classNames(
									'position-absolute start-65',
									'text-primary',
									'animate__animated animate__heartBeat animate__infinite animate__slower',
								)}
							/>
						</div>
					)}
					{/* <div className='col-auto filter-menu'>
						<ul className='d-flex align-items-center gap-3 mb-0'>

							{filterMenu?.map((value, index) => (
								<li
									key={index}
									className={`menu-item cursor-pointer fw-normal ${
										activeMenu == value?.name ? 'black-text' : 'text-muted'
									}`}
									onClick={() => {
										setActiveMenu(value?.name);
										if (value?.link) {
											navigate(`${value?.link}`);
										}
									}}
									data-tour={value?.dataTourText}>
									{value?.name}
								</li>
							))}
						</ul>
					</div> */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Analytics'>
							<div
								style={{
									position: 'relative',
									width: '42px',
									height: '42px',
									alignItems: 'center',
								}}
								className='d-flex justify-content-center'>
								<div
									style={{ position: 'absolute' }}
									onClick={() => setActiveMenu('')}>
									<Button
										{...styledBtn}
										icon='Insights'
										onClick={() => navigate('/mission-analytics')}
										aria-label='Analytics'
										data-tour='analytics'
										style={{
											border: loading ? '2px solid blue' : '',
											zIndex: 1,
											padding: 0,
											width: !!inProgressNotification?.id ? '32px' : '',
											height: !!inProgressNotification?.id ? '32px' : '',
										}}
									/>
								</div>
								<div
									className={!!inProgressNotification?.id ? '' : 'd-none'}
									style={{ position: 'absolute' }}>
									<CircularProgressbar
										value={
											!!inProgressNotification?.processedRequestCount &&
											typeof inProgressNotification?.totalRequestCount ==
												'number'
												? (inProgressNotification?.processedRequestCount /
														inProgressNotification?.totalRequestCount) *
												  100
												: 5
										}
									/>
								</div>
							</div>
						</Popovers>
					</div>
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Notifications'>
							<div
								style={{
									position: 'relative',
									width: '42px',
									height: '42px',
									alignItems: 'center',
								}}
								className='d-flex justify-content-center'>
								<div style={{ position: 'absolute' }}>
									<Button
										{...styledBtn}
										icon='Notifications'
										onClick={() => setOffcanvasStatus(true)}
										aria-label='Notifications'
										data-tour='notifications'
										style={{
											border: loading ? '2px solid blue' : '',
											zIndex: 1,
											padding: 0,
											width: !!inProgressNotification?.id ? '32px' : '',
											height: !!inProgressNotification?.id ? '32px' : '',
										}}
									/>
								</div>
								<div
									className={!!inProgressNotification?.id ? '' : 'd-none'}
									style={{ position: 'absolute' }}>
									<CircularProgressbar
										value={
											!!inProgressNotification?.processedRequestCount &&
											typeof inProgressNotification?.totalRequestCount ==
												'number'
												? (inProgressNotification?.processedRequestCount /
														inProgressNotification?.totalRequestCount) *
												  100
												: 5
										}
									/>
								</div>
							</div>
						</Popovers>
					</div>
					{IsAIEnabled && (
						<div className='col-auto'>
							<Popovers trigger='hover' desc='AI Collaborator'>
								<Button
									{...styledBtn}
									icon='TipsAndUpdates'
									onClick={() => setOpenHelpTab(true)}
									aria-label='AI Collaborator'
									data-tour='ai-collaborator'
								/>
							</Popovers>
						</div>
					)}
					{/* Dark Mode */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Dark / Light mode'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={darkModeStatus ? 'LightMode' : 'DarkMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'
								data-tour='dark-mode'
							/>
						</Popovers>
					</div>
					{/* Lang Selector */}
					<div className='col-auto cursor-pointer'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								{/* {typeof getLangWithKey(i18n.language as ILang['key']['lng'])
									?.icon === 'undefined' ? (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										className='btn-only-icon'
										aria-label='Change language'
										data-tour='lang-selector'>
										<Spinner isSmall inButton='onlyIcon' isGrow />
									</Button>
								) : (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										icon={
											getLangWithKey(i18n.language as ILang['key']['lng'])
												?.icon
										}
										aria-label='Change language'
										data-tour='lang-selector'
									/>
								)} */}
								<div className='user-avatar' data-tour='user-profile'>
									{salesProfessionalInfo?.photoUrl ? (
										<img
											srcSet={salesProfessionalInfo?.photoUrl}
											src={salesProfessionalInfo?.photoUrl}
											alt='Avatar'
											width={128}
											loading='lazy'
											height={128}
											className='btn-only-icon'
										/>
									) : (
										<div className='first-letter'>
											<p className='mb-0'>
												{(
													activeUser?.name ??
													activeUser?.email ??
													salesProfessionalInfo?.name
												)
													?.charAt(0)
													?.toUpperCase()}
											</p>
										</div>
									)}
								</div>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
								<DropdownItem>
									<Dropdown className='px-0' direction={'start'}>
										<DropdownToggle hasIcon={true}>
											<Button
												icon={'Business'}
												className='w-100 text-lg-start'>
												<span>{intl.get('app.workspace.plural.name')}</span>
											</Button>
										</DropdownToggle>
										<DropdownMenu direction={'left'}>
											<div className='py-2 px-4 fw-bold'>
												Select {intl.get('app.workspace.name')}
											</div>
											<DropdownItem isDivider aria-disabled></DropdownItem>
											<div
												style={{ overflowY: 'scroll', maxHeight: '500px' }}>
												{clients?.map((client, index) => (
													<DropdownItem
														key={index}
														onClick={() => {
															onCompanySelect(client?.id);
														}}>
														<span>{client?.name}</span>
													</DropdownItem>
												))}
											</div>
											<DropdownItem key={'create-new'} onClick={() => {}}>
												<Button
													icon={'Add'}
													onClick={() => {
														createNewCompany();
													}}>
													Add New {intl.get('app.workspace.name')}
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</DropdownItem>
								<DropdownItem isDivider aria-disabled></DropdownItem>
								<DropdownItem>
									<Button
										icon={'RoomPreferences'}
										onClick={() => {
											navigate('/workspace');
										}}>
										{activeClient?.name}
									</Button>
								</DropdownItem>
								{/* <DropdownItem>
									<Button icon={'Admin Panel Settings'} onClick={() => {}}>
										Administrator
									</Button>
								</DropdownItem> */}
								<DropdownItem>
									<Button
										icon={'Logout'}
										onClick={async () => {
											if (!!user?.uniqueId) {
												await localStorage.setItem('activeClientId', '');
												removeClient();
												await msalLogout();
											}
										}}>
										{t('menu:Logout') as ReactNode}
									</Button>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
				</div>
				<NotificationCanvas
					offcanvasStatus={offcanvasStatus}
					setOffcanvasStatus={setOffcanvasStatus}
				/>

				<AssetChatCanvas
					offcanvasStatus={assetChatCanvasStatus}
					setOffcanvasStatus={setAssetChatCanvasStatus}
				/>
				{IsAIEnabled && (
					<AICollaboratorChat
						openAICollaboratorTab={openHelpTab}
						setOpenAICollaboratorTab={setOpenHelpTab}
					/>
				)}
			</HeaderRight>
		</Header>
	);
};

export default DashboardHeader;
