import React, { useContext } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MdCheck, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import { ModuleContext } from '../contexts/ModuleContext';
import { DatalakeContext } from '../contexts/DatalakeContext';
import { ClientContext } from '../contexts/ClientContext';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import { Modals } from './Modals';

function HierarchSeachDropdown({ searchType }: { searchType: 'workflow' | 'datalake' }) {
	const { missionHierarchySettings } = useContext(ClientContext);
	const { workflowSeachParams, setWorkflowSeachParams } = useContext(ModuleContext);
	const { datalakeSeachParams, setDatalakeSeachParams } = useContext(DatalakeContext);
	let location = useLocation();
	const stepModal = useModal(Modals.MISSIONBUILDER);

	const selectLevel1 = async (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		value: string,
	) => {
		// await event.preventDefault();
		if (searchType === 'datalake') {
			setDatalakeSeachParams((prev) => ({
				...prev,
				level1: prev?.level1 === value ? '' : value,
			}));
		} else if (searchType === 'workflow') {
			setWorkflowSeachParams((prev) => ({
				...prev,
				level1: prev?.level1 === value ? '' : value,
			}));
		}
		console.log({ value });
	};

	const selectLevel2 = async (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		value: string,
	) => {
		// await event.preventDefault();
		if (searchType === 'datalake') {
			setDatalakeSeachParams((prev) => ({
				...prev,
				level2: prev?.level2 === value ? '' : value,
			}));
		} else if (searchType === 'workflow') {
			setWorkflowSeachParams((prev) => ({
				...prev,
				level2: prev?.level2 === value ? '' : value,
			}));
		}
	};

	const selectLevel3 = async (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		value: string,
	) => {
		// await event.preventDefault();
		if (searchType === 'datalake') {
			setDatalakeSeachParams((prev) => ({
				...prev,
				level3: prev?.level3 === value ? '' : value,
			}));
		} else if (searchType === 'workflow') {
			setWorkflowSeachParams((prev) => ({
				...prev,
				level3: prev?.level3 === value ? '' : value,
			}));
		}
	};

	const currentLevel1 =
		searchType === 'datalake'
			? datalakeSeachParams?.level1
			: searchType === 'workflow'
			? workflowSeachParams?.level1
			: '';
	const currentLevel2 =
		searchType === 'datalake'
			? datalakeSeachParams?.level2
			: searchType === 'workflow'
			? workflowSeachParams?.level2
			: '';
	const currentLevel3 =
		searchType === 'datalake'
			? datalakeSeachParams?.level3
			: searchType === 'workflow'
			? workflowSeachParams?.level3
			: '';

	const datalakeClassname =
		location.pathname == '/kb' || location.pathname?.startsWith('/mission-builder/')
			? 'kb-page'
			: 'other-page';
	return (
		<div className='d-flex gap-3 my-5 flex-wrap'>
			<DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					<button
						className={`TextButton  ${datalakeClassname}`}
						aria-label='Customise options'>
						<span className='count-text'>1</span>{' '}
						{currentLevel1?.length > 0
							? currentLevel1
							: missionHierarchySettings?.levelTitles?.[0]}{' '}
						<MdKeyboardArrowDown className='arrow-icon' size={18} />
					</button>
				</DropdownMenu.Trigger>

				<DropdownMenu.Portal>
					<DropdownMenu.Content
						className={`DropdownMenuContent  ${datalakeClassname}`}
						sideOffset={5}>
						<DropdownMenu.RadioGroup value={currentLevel1}>
							{missionHierarchySettings?.level1?.map((item, index) => (
								<DropdownMenu.RadioItem
									key={index}
									className='DropdownMenuRadioItem'
									value={item}
									onClick={(event) => selectLevel1(event, item)}>
									<DropdownMenu.ItemIndicator className='DropdownMenuItemIndicator'>
										<BsDot size={18} className='arrow-icon' />
									</DropdownMenu.ItemIndicator>
									{item}
								</DropdownMenu.RadioItem>
							))}
							<DropdownMenu.RadioItem
								className='DropdownMenuRadioItem'
								value={''}
								onClick={(event) => selectLevel1(event, '')}>
								<DropdownMenu.ItemIndicator className='DropdownMenuItemIndicator'>
									<BsDot size={18} className='arrow-icon' />
								</DropdownMenu.ItemIndicator>
								None
							</DropdownMenu.RadioItem>
						</DropdownMenu.RadioGroup>
						<DropdownMenu.Arrow className='DropdownMenuArrow' />
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
			{/* <DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					<button
						className={`TextButton  ${datalakeClassname}`}
						aria-label='Customise options'>
						<span className='count-text'>2</span>{' '}
						{currentLevel2?.length > 0
							? currentLevel2
							: missionHierarchySettings?.levelTitles?.[1]}{' '}
						<MdKeyboardArrowDown className='arrow-icon' size={18} />
					</button>
				</DropdownMenu.Trigger>

				<DropdownMenu.Portal>
					<DropdownMenu.Content
						className={`DropdownMenuContent  ${datalakeClassname}`}
						sideOffset={5}>
						<DropdownMenu.RadioGroup value={currentLevel2}>
							{missionHierarchySettings?.level2?.map((item, index) => (
								<DropdownMenu.RadioItem
									key={index}
									className='DropdownMenuRadioItem'
									value={item}
									onClick={(event) => selectLevel2(event, item)}>
									<DropdownMenu.ItemIndicator className='DropdownMenuItemIndicator'>
										<BsDot size={18} className='arrow-icon' />
									</DropdownMenu.ItemIndicator>
									{item}
								</DropdownMenu.RadioItem>
							))}
							<DropdownMenu.RadioItem
								className='DropdownMenuRadioItem'
								value={''}
								onClick={(event) => selectLevel2(event, '')}>
								<DropdownMenu.ItemIndicator className='DropdownMenuItemIndicator'>
									<BsDot size={18} className='arrow-icon' />
								</DropdownMenu.ItemIndicator>
								None
							</DropdownMenu.RadioItem>
						</DropdownMenu.RadioGroup>
						<DropdownMenu.Arrow className='DropdownMenuArrow' />
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root> */}
			{/* <DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					<button
						className={`TextButton  ${datalakeClassname}`}
						aria-label='Customise options'>
						<span className='count-text'>3</span>{' '}
						{currentLevel3?.length > 0
							? currentLevel3
							: missionHierarchySettings?.levelTitles?.[2]}{' '}
						<MdKeyboardArrowDown className='arrow-icon' size={18} />
					</button>
				</DropdownMenu.Trigger>

				<DropdownMenu.Portal>
					<DropdownMenu.Content
						className={`DropdownMenuContent  ${datalakeClassname}`}
						sideOffset={5}>
						<DropdownMenu.RadioGroup value={currentLevel3}>
							{missionHierarchySettings?.level3?.map((item, index) => (
								<DropdownMenu.RadioItem
									key={index}
									className='DropdownMenuRadioItem'
									value={item}
									onClick={(event) => selectLevel3(event, item)}>
									<DropdownMenu.ItemIndicator className='DropdownMenuItemIndicator'>
										<BsDot size={18} className='arrow-icon' />
									</DropdownMenu.ItemIndicator>
									{item}
								</DropdownMenu.RadioItem>
							))}
							<DropdownMenu.RadioItem
								className='DropdownMenuRadioItem'
								value={''}
								onClick={(event) => selectLevel3(event, '')}>
								<DropdownMenu.ItemIndicator className='DropdownMenuItemIndicator'>
									<BsDot size={18} className='arrow-icon' />
								</DropdownMenu.ItemIndicator>
								None
							</DropdownMenu.RadioItem>
						</DropdownMenu.RadioGroup>

						<DropdownMenu.Arrow className='DropdownMenuArrow' />
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root> */}
		</div>
	);
}

export default HierarchSeachDropdown;
