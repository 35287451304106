import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface imageEditor {
	images: string[];
	selectedImage: string;
	selectedImageName: string;
	isSaving: boolean;
}
const initialState: imageEditor = {
	images: [],
	selectedImage: '',
	selectedImageName: '',
	isSaving: false,
};

export const imageEditorSlice = createSlice({
	name: 'imageEditor',
	initialState,
	reducers: {
		setEditedImages: (state, action: PayloadAction<string[]>) => {
			state.images = action.payload;
		},
		setSelectedImage: (state, action: PayloadAction<string>) => {
			state.selectedImage = action.payload;
		},
		setSelectedImageName: (state, action: PayloadAction<string>) => {
			state.selectedImageName = action.payload;
		},
		setSavingImage: (state, action: PayloadAction<boolean>) => {
			state.isSaving = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setEditedImages, setSelectedImage, setSelectedImageName, setSavingImage } =
	imageEditorSlice.actions;
export default imageEditorSlice.reducer;
