import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Client, MissionHierarchySettings, SendgridApi } from '../models/models';
import { CLIENT_CALL, DELETE_CLIENT } from '../GraphQL/client/mutation';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CLIENTS } from '../GraphQL/client/queries';
import { EntraIdUser } from '../Azure/models';
import {
	GET_MISSION_HIERARCH_SETTINGS,
	UPDATE_MISSION_HIERARCH_SETTINGS,
} from '../GraphQL/mission-hierarchy-settings';

enum ClientsRequestType {
	inviteMembers = 'inviteMembers',
	addMember = 'addMember',
	createCustomerPortalSession = 'createCustomerPortalSession',
	createNewClient = 'createNewClient',
	buyModule = 'buyModule',
	addSendgridApiKey = 'addSendgridApiKey',
	removeSendgridApiKey = 'removeSendgridApiKey',
	fetchSendgridApiKeys = 'fetchSendgridApiKeys',
	makeDefaultSendgridApiKey = 'makeDefaultSendgridApiKey',
	addSendgridApiKeyEmail = 'addSendgridApiKeyEmail',
	removeSendgridApiKeyEmail = 'removeSendgridApiKeyEmail',
	makeDefaultSendgridApiKeyEmail = 'makeDefaultSendgridApiKeyEmail',
	saveClient = 'saveClient',
	createChatGPTImage = 'createChatGPTImage',
}

export default function useClient() {
	const [clients, setClients] = useState<Client[]>([]);
	const [activeClient, setActiveClient] = useState<Client>();
	const [activeClientId, setActiveClientId] = useState<string>('');
	const [missionHierarchySettings, setMissionHierarchySettings] =
		useState<MissionHierarchySettings | null>(null);
	const { activeUser, user } = useContext(UserContext);
	const [clientCall, { error: createClientError }] = useMutation(CLIENT_CALL);
	const [updateMissionHierarchySettings, {}] = useMutation(UPDATE_MISSION_HIERARCH_SETTINGS);
	const [deleteClient, { error: deleteClientError }] = useMutation(DELETE_CLIENT);
	const { data: missionHierarchySettingsData, refetch: refetchMissionHierarchySettings } =
		useQuery(GET_MISSION_HIERARCH_SETTINGS, {
			variables: {
				clientId: activeClient?.id,
				accessToken: user?.accessToken,
			},
			skip: !user?.accessToken || !activeClient?.id,
		});

	const { data: clientsData, refetch: refetchClients } = useQuery(GET_CLIENTS, {
		variables: {
			clientIds: activeUser?.clientIds?.map((e) => e) ?? [],
			accessToken: user?.accessToken,
		},
		skip:
			!user?.accessToken ||
			!user?.uniqueId ||
			!(!!activeUser?.clientIds && activeUser?.clientIds?.length > 0 && clients?.length == 0),
	});

	useEffect(() => {
		if (!!clientsData?.getClients) {
			setClients(clientsData?.getClients as Client[]);
			const updatedActiveClient = clientsData?.getClients?.find(
				(client: Client) =>
					!!activeClientId &&
					activeClient?.id == activeClientId &&
					activeClient?.id == client?.id,
			);
			if (!!updatedActiveClient) {
				setActiveClient(updatedActiveClient);
			}
		}
	}, [clientsData]);

	const refetchClientsFunc = async () => {
		const res = await refetchClients();
		if (!!res?.data?.getClients) {
			setClients(res?.data?.getClients);
			const updatedActiveClient = res?.data?.getClients?.find(
				(client: Client) =>
					!!activeClientId &&
					activeClient?.id == activeClientId &&
					activeClient?.id == client?.id,
			);
			if (!!updatedActiveClient) {
				setActiveClient(updatedActiveClient);
			}
		}
	};
	const refetchAndSetNewClients = async () => {
		const res = await refetchClients();
		if (!!res?.data?.getClients) {
			setClients(res?.data?.getClients);
			setActiveClientId(res?.data?.getClients?.[res?.data?.getClients?.length - 1]?.id);
		}
	};
	const onlyRefetchClients = async () => {
		const res = await refetchClients();
		if (!!res?.data?.getClients) {
			setClients(res?.data?.getClients);
		}
	};

	const refetchMissionHierarchySettingsFunc = async () => {
		const res = await refetchMissionHierarchySettings({
			clientId: activeClient?.id,
			accessToken: user?.accessToken,
		});
		if (!!res?.data?.getMissionHierarchySettings) {
			setMissionHierarchySettings(res?.data?.getMissionHierarchySettings);
		}
	};

	useEffect(() => {
		if (!!missionHierarchySettingsData?.getMissionHierarchySettings) {
			setMissionHierarchySettings(missionHierarchySettingsData?.getMissionHierarchySettings);
		}
	}, [missionHierarchySettingsData]);

	useEffect(() => {
		if (
			!!activeClient?.id &&
			!!missionHierarchySettings?.clientId &&
			missionHierarchySettings?.clientId != activeClient?.id
		) {
			(async () => {
				await setMissionHierarchySettings(null);
				await refetchMissionHierarchySettingsFunc();
			})();
		}
	}, [activeClient?.id]);

	const removeClient = async () => {
		setActiveClient(undefined);
		await localStorage.setItem('activeClientId', '');
		await localStorage.removeItem('selectedModule');
		setActiveClientId('');
		setClients([]);
	};
	useEffect(() => {
		if (!activeUser) {
			return;
		} else if (!!activeClientId && clients?.length > 0) {
			(async () => {
				let activeCurrent = clients?.find((client) => client.id == activeClientId);
				if (!!activeCurrent) {
					await localStorage.setItem('activeClientId', activeCurrent?.id);
					setActiveClient(activeCurrent);
					setActiveClientId(activeCurrent?.id);
				} else {
					let firstClient = clients?.[0];
					await localStorage.setItem('activeClientId', firstClient?.id);
					setActiveClient(firstClient);
					setActiveClientId(firstClient?.id);
				}
			})();
		} else if (!activeClientId && clients?.length > 0) {
			(async () => {
				let firstClient = clients?.[0];
				await localStorage.setItem('activeClientId', firstClient?.id);
				setActiveClient(firstClient);
				setActiveClientId(firstClient?.id);
			})();
		}
	}, [clients]);

	useEffect(() => {
		if (!activeClientId || activeClientId == activeClient?.id) {
			return;
		} else {
			(async () => {
				let current = clients?.find((client) => client.id == activeClientId);
				if (!!current) {
					await localStorage.setItem('activeClientId', current?.id);
					setActiveClient(current);
				}
			})();
		}
	}, [activeClientId, clients]);

	useEffect(() => {
		(async () => {
			const storedActiveClient = await localStorage.getItem('activeClient');
			if (!!storedActiveClient) {
				const parsedActiveClient = JSON.parse(storedActiveClient);
				setActiveClient(parsedActiveClient);
				const clientId = parsedActiveClient?.id;
				setActiveClientId(clientId);
			}
		})();
	}, []);

	useEffect(() => {
		if (activeClient) {
			(async () => {
				await localStorage.setItem('activeClient', JSON.stringify(activeClient));
				const clientId = activeClient?.id;
				setActiveClientId(clientId);
				await localStorage.setItem('activeClientId', clientId);
			})();
		}
	}, [activeClient]);

	const deleteWorkspace = async (clientId: string) => {
		try {
			const response = await deleteClient({
				variables: {
					id: clientId,
					accessToken: user?.accessToken,
				},
			});
			await onlyRefetchClients();
			setActiveClientId(clients?.[0]?.id);

			return !!response?.data?.deleteClient;
		} catch (err: any) {
			console.error('Error deleting the workspace', err);

			return err.toString();
		}
	};
	const saveClient = async (client: Partial<Client>) => {
		if (!client || !client?.id) return false;

		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.saveClient,
						clientInput: client,
						clientId: client?.id,
						accessToken: user?.accessToken,
					},
				},
			});
			if (client.id) {
				setActiveClientId(client?.id);
			}

			console.log('client saved');
			return !!response?.data?.clientCall;
		} catch (err: any) {
			console.error('Error adding/updating client', err);
			// setIsBusy(false);
			return err.toString();
		}
	};

	const createNewClient = async () => {
		if (!activeClientId || !activeUser?.id || !activeClient?.billingClientId) {
			return false;
		}
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.createNewClient,
						user: activeUser,
						clientId: activeClientId,
						billingClientId: activeClient?.billingClientId,
						input: { currentClientsCount: clients?.length },
						accessToken: user?.accessToken,
					},
				},
			});
			if (!!response?.data?.clientCall?.id) {
				setClients((prev) => [
					...prev?.filter((i) => i?.id != response?.data?.clientCall?.id),
					{ ...response?.data?.clientCall },
				]);
			}
			await refetchAndSetNewClients();
			return;
		} catch (err: any) {
			console.error('Error adding module to client', err.toString());
			return false as false;
		}
	};

	const inviteMembers = async (users: EntraIdUser[]) => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.inviteMembers,
						clientId: activeClientId,
						users: users,
						input: { sender: activeUser?.id },
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchClientsFunc();
			return response?.data?.clientCall;
		} catch (err) {
			console.log('errror on inviting member', err);
		}
		return undefined;
	};

	const createChatGPTImage = async ({
		workflowId,
		imagePrompt,
	}: {
		workflowId: string;
		imagePrompt: string;
	}) => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.createChatGPTImage,
						clientId: activeClientId,
						workflowId,
						imagePrompt,
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchClientsFunc();
			return response?.data?.clientCall;
		} catch (err) {
			console.log('errror on inviting member', err);
		}
		return undefined;
	};

	const verifyClientIdToMember = async (clientId: string, secretCode: string) => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.addMember,
						clientId: clientId,
						input: {
							userId: activeUser?.id,
							secretCode: secretCode,
							userEmail: activeUser?.email,
						},
						accessToken: user?.accessToken,
					},
				},
			});

			return response?.data?.clientCall as Client | { msg: string };
		} catch (err) {
			console.log('error on verifying client id to new member', err);
			return false as false;
		}
	};

	const fetchSendgridApiKeys = async () => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.fetchSendgridApiKeys,
						clientId: activeClientId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.clientCall as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const addSendgridApiKey = async (apiKey: string, emails: string[]) => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.addSendgridApiKey,
						apiKey,
						emails,
						clientId: activeClientId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.clientCall as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const removeSendgridApiKey = async (id: string) => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.removeSendgridApiKey,
						id,
						clientId: activeClientId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.clientCall as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const makeDefaultSendgridApiKey = async (id: string) => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.makeDefaultSendgridApiKey,
						id,
						clientId: activeClientId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.clientCall as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const addSendgridApiKeyEmail = async (id: string, emails: string[]) => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.addSendgridApiKeyEmail,
						id,
						emails,
						clientId: activeClientId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.clientCall as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const removeSendgridApiKeyEmail = async (id: string, emails: string[]) => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.removeSendgridApiKeyEmail,
						id,
						emails,
						clientId: activeClientId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.clientCall as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const makeDefaultSendgridApiKeyEmail = async (id: string, emails: string[]) => {
		try {
			const response = await clientCall({
				variables: {
					userInput: {
						requestType: ClientsRequestType.makeDefaultSendgridApiKeyEmail,
						id,
						emails,
						clientId: activeClientId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.clientCall as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const updateMissionHierarchySettingsFunc = async (data: Partial<MissionHierarchySettings>) => {
		try {
			const response = await updateMissionHierarchySettings({
				variables: {
					userInput: {
						...data,
					},
					accessToken: user?.accessToken,
				},
			});
			setMissionHierarchySettings(response?.data?.updateMissionHierarchySettings);
			return response?.data?.updateMissionHierarchySettings as MissionHierarchySettings;
		} catch (e) {
			return [];
		}
	};

	return {
		clients,
		activeClient,
		activeClientId,
		setActiveClientId,
		saveClient,
		inviteMembers,
		verifyClientIdToMember,
		removeClient,
		createNewClient,
		fetchSendgridApiKeys,
		addSendgridApiKey,
		removeSendgridApiKey,
		makeDefaultSendgridApiKey,
		addSendgridApiKeyEmail,
		removeSendgridApiKeyEmail,
		makeDefaultSendgridApiKeyEmail,
		setActiveClient,
		createChatGPTImage,
		deleteWorkspace,
		missionHierarchySettings,
		updateMissionHierarchySettingsFunc,
	};
}
