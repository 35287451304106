import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import Button from '../../../components/bootstrap/Button';
import GeneralData from './general-data';
import Images from './images';
import Products from './products';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import {
	Company,
	Image,
	Product,
	User,
	Document,
	DataFragment,
	DATAFRAGMENT_TYPE,
	iconList_TYPE,
} from '../../../models/models';
import { Modals } from '../../../components/Modals';
import { useModal } from '@ebay/nice-modal-react';
import DatalakeModal from './datalakeModal';
import FragmentForm from './fragmentForm';
import Companies from './companies';
import Users from './users';
import Icon from '../../../components/icon/Icon';
import { ModalHeader } from '../../../components/bootstrap/Modal';
import Attachments from './attachments';
import DataFragmentLists from './DataFragmentLists';
import { Link, useNavigate } from 'react-router-dom';
import Files from './Files';
import Folders from './Folders';
import FolderBreadCrumb from './FolderBreadCrumb';
import { arraysEqual } from '../../../util/util';
import { ROOT_FOLDER } from '../../../hooks/useFolders';
import Page from '../../../layout/Page/Page';
import ScrollContainer from 'react-indiana-drag-scroll';
import intl from 'react-intl-universal';
import HierarchSeachDropdown from '../../../components/HierarchSeachDropdown';
import { ClientContext } from '../../../contexts/ClientContext';

function DataLake() {
	const {
		// setFragmentId: setProductId,
		setSelectedFragmentType,
		deleteDataFragment,
	} = useContext(DatalakeContext);
	const filters = [
		'All',
		'Company',
		'People',
		'Products',
		'Images',
		'Documents',
		'Leads',
		'Contents',
	];
	const createfragments: DATAFRAGMENT_TYPE[] = [
		'company',
		'user',
		'product',
		'image',
		'document',
		'lead',
		'content',
	];
	const listIcons: iconList_TYPE[] = [
		'Business',
		'PersonAddAlt',
		'Inventory2',
		'AddPhotoAlternate',
		'Description',
		'AccountBox',
		'Article',
	];
	const [activeFilter, setActiveFilter] = useState('All');
	const [openModal, setOpenModal] = useState(false);
	const {
		dataFragments,
		currentFolder,
		folders,
		suggestedDataFragements,
		allDataFragments,
		datalakeSeachParams,
		setDatalakeSeachParams,
	} = useContext(DatalakeContext);
	const { activeClientId } = useContext(ClientContext);
	const [filteredFragments, setFilteredFragments] = useState(
		dataFragments?.filter((f) => f.clientId === activeClientId),
	);
	const [showList, setShowList] = useState(false);
	console.log({ suggestedDataFragements });

	useEffect(() => {
		setOpenModal(true);
	}, []);
	const datalakeModal = useModal(Modals.DATALAKE);
	const { setFragmentId: setProductId } = useContext(DatalakeContext);
	console.log('Filtered fragments', filteredFragments);
	const companies = filteredFragments?.filter((f) => f.type == 'company') as Company[];
	const users = filteredFragments?.filter((f) => f.type == 'user') as User[];
	const products = filteredFragments?.filter((f) => f.type == 'product') as Product[];
	const images = filteredFragments?.filter((f) => f.type == 'image') as Image[];
	const leadsList = filteredFragments?.filter((f) => f.type == 'lead') as DataFragment[];
	const contentsList = filteredFragments?.filter((f) => f.type == 'content') as DataFragment[];
	const documents = filteredFragments?.filter((f) => f.type == 'document') as Document[];
	console.log('leadsss', leadsList);
	console.log('contents', contentsList);
	const filteredData: DataFragment[] = useMemo(() => {
		switch (activeFilter) {
			case 'All':
				return [...companies, ...users, ...products, ...images, ...documents];
			case 'Company':
				return companies;
			case 'People':
				return users;
			case 'Products':
				return products;
			case 'Images':
				return images;
			case 'Documents':
				return documents;
			default:
				return [];
		}
	}, [activeFilter, companies, users, products, images, documents, dataFragments]);

	const navigate = useNavigate();

	const handleNewButtonClick = () => {
		setShowList(!showList); // Toggle the state
	};

	return (
		<div className='sheet-modal mt-0 overflow-hidden'>
			{/* <div className='sheet-modal-header d-flex align-items-center justify-content-between px-4'>
			</div> */}
			<Sheet
				isOpen={openModal}
				onClose={() => {}}
				snapPoints={[0.85]}
				disableDrag={true}
				className='child-sheet'
				style={{ zIndex: 99 }}>
				<Sheet.Container>
					<Sheet.Header className='child-sheet-header' />
					<Sheet.Content className='child-sheet-content'>
						<div style={{ width: '100vw', height: '100vh', overflowY: 'scroll' }}>
							<Page
								container={`${window.innerWidth <= 600 ? 'xl' : 'fluid'}`}
								className='flex-grow-0 '>
								<div className='module-optios-container border px-md-5 pt-md-4 overflow-hidden  '>
									<div className='module-options-header d-block d-lg-none'></div>
									<div className='position-relative'>
										<h2 className='fw-normal fs-1 mb-5 heading-text'>
											<span className='text-muted'>Create New</span>
										</h2>
										<Link to='/'>
											<div className='cursor-pointer close-icon'>
												<Icon icon='Close' className='theme-gray-text' />
											</div>
										</Link>
									</div>
									<div className='module-options-container'>
										<ScrollContainer
											horizontal={true}
											className='scroll-container '>
											<div className='modules-list w-100 d-flex gap-5 '>
												{createfragments?.map((item, index) => (
													<div
														className='module-item position-relative'
														onClick={() => {
															setSelectedFragmentType(item);
															setProductId('new');
															datalakeModal.show();
														}}
														key={item}>
														<div className='workflow-type-container cursor-pointer d-flex flex-column align-items-center'>
															<div className='icon-placeholder d-flex align-items-center justify-content-center'>
																<Icon
																	icon={listIcons[index]}
																	size={'2x'}
																	className='icon'
																/>
															</div>
															<p className='type-name'> {item}</p>
														</div>
													</div>
												))}
											</div>
										</ScrollContainer>
									</div>
								</div>
							</Page>
							<div className=' d-flex px-2 px-md-5 pt-4 flex-column flex-lg-row justify-content-between align-items-center col-md-8 mb-2 mb-lg-0 '>
								<div className=' col-lg-12 mb-2 mb-lg-0  h6 d-flex gap-2 align-items-center'>
									<p className='mb-0 uppercase-title '>
										{intl.get('app.datalake.name')}
									</p>
									<label className='border-0 bg-transparent cursor-pointer'>
										<Icon icon='ChevronRight' color='primary' />
									</label>
									<FolderBreadCrumb />
								</div>
							</div>
							<div className='top-search-container'>
								<div className='my-3 pt-md-0  align-items-center '>
									{/* <Search /> */}
									<ModalHeader className=' px-4'>
										<div
											className=' col-md-8 mb-4 mb-lg-0'
											style={{
												outline: '1px solid',
												borderRadius: '20px',
												width: 'auto',
											}}>
											<label
												className='border-0 bg-transparent cursor-pointer'
												htmlFor='searchInput'>
												<Icon icon='Search' size='2x' color='primary' />
											</label>
											<input
												type='text'
												placeholder='Search....'
												// className='search'
												className='border-0 shadow-none bg-transparent'
												style={{
													outline: 'none',
													border: 'none',
													backgroundColor: 'none',
													height: '40px',
													width: 'auto',
													fontSize: '20px',
													background: '#0000000F',
													borderRadius: '40px',
													paddingLeft: '10px',
												}}
												onChange={(e) =>
													setDatalakeSeachParams((prev) => ({
														...prev,
														searchText: e.target.value,
													}))
												}
											/>
										</div>
									</ModalHeader>
									<div className='px-4'>
										<HierarchSeachDropdown searchType={'datalake'} />
									</div>
								</div>{' '}
							</div>

							{arraysEqual(currentFolder, ROOT_FOLDER) ? (
								<>
									{arraysEqual(currentFolder, ROOT_FOLDER) ? (
										<Files
											files={suggestedDataFragements?.filter(
												(f) => f.clientId === activeClientId,
											)}
											title='Recent'
										/>
									) : (
										<></>
									)}
									{folders?.length > 0 ? (
										<Folders folders={folders} isHorizontal={true} />
									) : (
										<></>
									)}
									{allDataFragments?.filter((f) => f.clientId === activeClientId)
										?.length > 0 &&
									!arraysEqual(
										allDataFragments.map((f) => f.id),
										suggestedDataFragements
											?.filter((f) => f.clientId === activeClientId)
											.map((f) => f.id),
									) ? (
										<Files
											files={allDataFragments?.filter(
												(f) => f.clientId === activeClientId,
											)}
											title='Files'
										/>
									) : (
										<></>
									)}
								</>
							) : (
								<>
									{folders?.length > 0 ? (
										<Folders folders={folders} isHorizontal={true} />
									) : (
										<></>
									)}
									{dataFragments?.filter((f) => f.clientId === activeClientId)
										?.length > 0 ? (
										<Files
											files={dataFragments?.filter(
												(f) => f.clientId === activeClientId,
											)}
											title='Files'
										/>
									) : (
										<></>
									)}
								</>
							)}

							{activeFilter !== 'All' && (
								<div>
									{activeFilter === 'Company' && (
										<Companies companies={filteredData} />
									)}
									{activeFilter === 'People' && (
										<Users users={filteredData as User[]} />
									)}
									{activeFilter === 'Products' && (
										<Products products={filteredData} />
									)}
									{activeFilter === 'Images' && <Images images={filteredData} />}
									{activeFilter === 'Documents' && (
										<Attachments documents={filteredData} />
									)}
									{activeFilter === 'Leads' && (
										<DataFragmentLists
											lists={leadsList}
											name={'Leads'}
											type={'lead'}
										/>
									)}
									{activeFilter === 'Contents' && (
										<DataFragmentLists
											lists={contentsList}
											name={'Contents'}
											type={'content'}
										/>
									)}
								</div>
							)}
						</div>
					</Sheet.Content>
				</Sheet.Container>
			</Sheet>
			<DatalakeModal id={Modals.DATALAKE}>
				<FragmentForm />
			</DatalakeModal>
		</div>
	);
}

export default DataLake;
