import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { DATAFRAGMENT_TYPE, DataFragment, DataFragmentVM, Folder, WorkflowSearchParams } from '../models/models';
import useFolders from '../hooks/useFolders';
import { DB_CONTAINERS } from '../Azure/db-containers';
import slug from 'slug';
import { ClientContext } from './ClientContext';
import { UserContext } from './UserContext';

interface IDatalakeContext {
	dataFragments: DataFragment[];
	suggestedDataFragements: DataFragment[];
	allDataFragments: DataFragment[];
	loading: boolean;
	deleteDataFragment: (fragment: DataFragment) => Promise<void>;
	save: (product: DataFragmentVM) => Promise<string>;
	fragment: DataFragment | undefined;
	fragmentId: string | undefined;
	selectedFragmentType: DATAFRAGMENT_TYPE;
	setFragmentId: Dispatch<SetStateAction<string | undefined>>;
	setSelectedFragmentType: Dispatch<SetStateAction<DATAFRAGMENT_TYPE>>;
	currentFolder: string[];
	openFolder: (path: string) => void;
	openChildFolder: (folderName: string) => void;
	folders: Folder[];
	isRootFolder: boolean;
	setDatalakeSeachParams: Dispatch<SetStateAction<WorkflowSearchParams>>;
	datalakeSeachParams: WorkflowSearchParams;
}

export const DatalakeContext = createContext<IDatalakeContext>({} as IDatalakeContext);

export const DatalakeProvider = ({ children }: { children: any }) => {
	const [selectedFragmentType, setSelectedFragmentType] = useState<DATAFRAGMENT_TYPE>('product');
	const { activeClientId } = useContext(ClientContext);
	const { activeUser } = useContext(UserContext);

	const filterDataFragment = (f: DataFragment, filter: string) => {
		if (!f) return false;
		const lcf = filter.toLowerCase();
		if (
			f.name?.toLowerCase()?.includes(lcf) ||
			f.text?.toLowerCase()?.includes(lcf) ||
			f.type?.toLowerCase()?.includes(lcf)
		)
			return true;
		for (const folder of f.folder ?? []) {
			if (folder.toLowerCase()?.includes(lcf)) return true;
		}
		for (const link of f.links ?? []) {
			if (link.type?.toLowerCase()?.includes(lcf) || link.url?.toLowerCase()?.includes(lcf))
				return true;
		}
		return false;
	};

	const {
		files: dataFragments,
		loading,
		deleteFile: deleteDataFragment,
		setFileId: setFragmentId,
		fileId: fragmentId,
		file: fragment,
		saveFile,
		currentFolder,
		isRootFolder,
		folders,
		openFolder,
		openChildFolder,
		suggestedfiles: suggestedDataFragements,
		allFiles: allDataFragments,
		setDatalakeSeachParams,
		datalakeSeachParams,
	} = useFolders<DataFragment>(DB_CONTAINERS.DATALAKE, filterDataFragment);

	const reconstruct = (vm: DataFragmentVM, dbFragment: DataFragment | undefined) => {
		const idSlug = slug(`${vm.name ?? dbFragment?.name}-${new Date().getTime()}`);
		const merged: DataFragment = {
			...vm,
			folder: vm?.folder?.length > 0 ? vm?.folder : dbFragment?.folder ?? ['/', vm.type],
			id: dbFragment?.id ?? idSlug,
			created: dbFragment?.created ?? new Date().toISOString(),
			clientId: dbFragment?.clientId ?? activeClientId ?? activeUser?.id ?? 'defaultClientId',
			lastUpdated: new Date().toISOString(),
			userId: dbFragment?.userId ?? activeUser?.id ?? 'defaultClientId',
		};
		return merged;
	};

	const save = async (fragmentVM: DataFragmentVM): Promise<string> => {
		// setIsBusy(true);
		if (!activeClientId || !activeUser?.id) return '';

		try {
			const saveFragment = reconstruct(fragmentVM, fragment);
			await saveFile(saveFragment);
			return saveFragment?.id;
		} catch (err: any) {
			console.error('Error adding/updating fragment', err);
			// setIsBusy(false);
			return err.toString();
		}
	};

	return (
		<DatalakeContext.Provider
			value={{
				dataFragments,
				loading,
				deleteDataFragment,
				save,
				datalakeSeachParams,
				setDatalakeSeachParams,
				fragment,
				fragmentId,
				setFragmentId,
				selectedFragmentType,
				setSelectedFragmentType,
				folders,
				currentFolder,
				isRootFolder,
				openFolder,
				openChildFolder,
				suggestedDataFragements,
				allDataFragments,
			}}>
			{children}
		</DatalakeContext.Provider>
	);
};
