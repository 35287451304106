import { v4 as uuid } from 'uuid';
import * as XLSX from 'xlsx';
import { size, isEmpty, isEqual, xorWith } from 'lodash';
import { getMetadata } from 'video-metadata-thumbnails';
import { AzureConfig } from '../Azure/azure-constants';

export const generateRandomId = () => {
	var result = '';
	var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < 30; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const downloadExcel = (data: any, filename: string) => {
	const worksheet = XLSX.utils.json_to_sheet(data);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
	//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
	//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
	XLSX.writeFile(workbook, `${filename}.csv`);
};

export const emailsForWebsite = (urlString?: string) => {
	if (!urlString) return ['test@gmail.com'];
	const url = new URL(urlString);
	const emailHost = url.hostname.split('.').slice(-2).join('.');

	return [`info@${emailHost}`];
};

export const websiteHostUrl = (urlString?: string) => {
	if (!urlString) return [''];
	const url = new URL(urlString);
	const host = url.hostname;
	return host;
};

export const generateShortRandomId = () => {
	var result = '';
	var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < 15; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const generateShortRandomNumber = () => {
	var result = '';
	var characters = '1234567890';
	var charactersLength = characters.length;
	for (var i = 0; i < 10; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const cloneNumberStampedId = (oldId: string) => {
	const newId = oldId.split('-').slice(0, -1).join('-') + '-' + generateShortRandomNumber();
	return newId;
};

export const cloneName = (oldName: string) => {
	const parts = oldName.split(' - copy');
	let newId = parts[0] + ' - copy';
	if (parts.length > 1) {
		if (!isNaN(parseInt(parts[1]))) {
			let index = +parts[1].trim() + 1;
			newId += ' ' + index;
		} else {
			newId += ' 2';
		}
	}

	return newId;
};

export const shorten = (str?: string, maxLen: number = 3) => {
	return str ? (str.length > maxLen ? str.substring(0, maxLen) + '...' : str) : '';
};

export const campaignToneData = [
	{
		title: 'Audience',
		categories: ['General', 'Knowledgeable', 'Expert'],
		hint: 'Knowledgeable (default): Requires focus to read and understand.',
	},
	{
		title: 'Formality',
		categories: ['Informal', 'Neutral', 'Formal'],
		hint: 'Neutral (default): Restricts slang but allows standard casual expressions.',
	},
	{
		title: 'Domain',
		categories: ['Academic', 'Business', 'General', 'Email', 'Casual', 'Creative'],
		hint: 'Get customized suggestions for business writing, academic assignments, and more.',
	},
	{
		title: 'Intent',
		categories: ['Inform', 'Describe', 'Convince', 'Tell A Story'],
		hint: `Experimental. What are you trying to do? This helps us build new suggestions and won't affect your feedback today.`,
	},
	{
		title: 'Subject Line Preference',
		categories: ['Personalized', 'Benefit focused', 'Urgent'],
		hint: `A study investigating the impact of subject line content on email open rates and user engagement.`,
	},
];

export const getNameFromImageUrl = (url?: string) => {
	return url?.split('?')?.[0]?.split('/')?.pop()?.split('%2F')?.pop();
};

export const uniqueId = () => uuid();

export const numberKey = (id: number) => String(id).padStart(9, '0');

export const sequenceId = () => {
	const start = new Date('2021-01-01');
	const now = new Date();
	const elapsed = Math.floor((now.getTime() - start.getTime()) / 1000);
	return numberKey(elapsed);
};

export const getResizedImage = (image: string, size: number = 1024) => {
	return image;
};

export const arraysEqual = (a: string[], b: string[]) => {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (var i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	}
	return true;
};

export function titleUpperCase(str: string) {
	if (!str) return '';
	return str
		.replace(/_/g, ' ')
		.toLowerCase()
		.split(' ')
		.map(function (word) {
			return word.charAt(0).toUpperCase() + word.slice(1);
		})
		.join(' ');
}

export function camelToTitleCase(string?: string) {
	if (!string) return '';
	string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
	string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
	return string.substring(0, 1).toUpperCase() + string.substring(1);
}

export function isValidHttpUrl(str: string) {
	try {
		const newUrl = new URL(str);
		return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
	} catch (err) {
		return false;
	}
}

export function onlyUnique<T>(value: T, index: number, array: T[]) {
	return array.indexOf(value) === index;
}

export function subsetEquals(subset: object, set: object) {
	if (!subset) return false;

	const keys1 = Object.keys(subset);
	if (!keys1.length) return false;

	if (!set) return false;
	for (let key of keys1) {
		if (subset[key as keyof object] !== set[key as keyof object]) {
			return false;
		}
	}

	return true;
}

export function areArraysEqual<T>(x: T[], y: T[]) {
	if (size(x) !== size(y)) return false;
	else return isEmpty(xorWith(x, y, isEqual));
}

export const initials = (name: string) => {
	let returnName = '';
	for (let i = 0; i < name?.length; i++) {
		if (i > 0 && name[i - 1] === ' ' && name[i] != ' ') {
			returnName = returnName + name[i];
		} else if (i === 0 && name[i] != ' ') {
			returnName = returnName + name[i];
		}
	}
	const length = 2;

	var trimmedName = returnName.length > length ? returnName.substring(0, 2) : returnName;

	return trimmedName.toUpperCase();
};
export const createImageFromInitials = (size: number, name: string) => {
	if (name == null) return;
	name = initials(name);
	const canvas = document.createElement('canvas');
	canvas.width = canvas.height = size;
	return canvas.toDataURL();
};

export const getRandomColor = () => {
	var letters = '0123456789ABCDEF';
	var color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

export function spacedTitle(value?: string) {
	if (!value) return ' ';
	const output = value.replace(/([A-Z])/g, '   $1').trim();
	return output;
}

export const isValidUrl = (urlString: string) => {
	var urlPattern = new RegExp(
		'^(https?:\\/\\/)?' + // validate protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
			'(\\#[-a-z\\d_]*)?$',
		'i',
	); // validate fragment locator

	return !!urlPattern.test(urlString);
};

export async function urlToFileObject(url: string, name: string) {
	let response = await fetch(url);
	let data = await response.blob();
	let file = new File([data], name, { lastModified: new Date().getTime() });
	return file;
}

export function isValidEmail(email: string) {
	const emailRegex = '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$';
	const isEmailValid = email?.match(emailRegex);

	if (isEmailValid) {
		return true;
	} else {
		return false;
	}
}

export const appendHttpToUrl = (url: string) => {
	if (!/^https?:\/\//i.test(url)) {
		return 'https://' + url;
	} else {
		return url;
	}
};

export const blobToBase64 = (blob: Blob) => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise((resolve) => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

export const imageUrlToBase64 = async (url: string) => {
	const data = await fetch(url);
	const blob = await data.blob();
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			const base64data = reader.result;
			resolve(base64data);
		};
		reader.onerror = reject;
	});
};

export const imageToDataURL = async (url: string, name: string, type: string) => {
	const data = await fetch(url);
	const blob = await data.blob();
	var file = new File([blob], name, {
		type,
	});
	return file;
};

export async function getVideoDimensions(videoUrl: string) {
	try {
		const metadata = await getMetadata(videoUrl);
		const width = metadata?.width;
		const height = metadata?.height;
		return { width, height };
	} catch (err) {
		console.error(err);
		throw err;
	}
}

export function resizeWithMaxDimension(width: number, height: number, maxDimension: number) {
	let newWidth, newHeight;

	const ratio = width / height;

	if (width > height) {
		newWidth = Math.min(maxDimension, width);
		newHeight = newWidth / ratio;
	} else {
		newHeight = Math.min(maxDimension, height);
		newWidth = newHeight * ratio;
	}

	return { width: Math.round(newWidth), height: Math.round(newHeight) };
}

export async function getImageDimensions(imageUrl: string) {
	const base64Data = await imageUrlToBase64(imageUrl);
	return new Promise((resolve, reject) => {
		const img = new Image();

		img.onload = function () {
			resolve({ width: img.naturalWidth, height: img.naturalHeight });
		};

		img.onerror = function (error) {
			reject(error);
		};

		img.src = `${base64Data}`;
	});
}

export function getImageTypeFromBase64(base64Data: string) {
	const matches = base64Data.match(/^data:image\/([a-zA-Z]+);base64,/);
	if (matches && matches[1]) {
		return matches[1].toLowerCase();
	} else {
		return null; // Invalid or unsupported data URL format
	}
}

export function generateFilename(blobType: string) {
	let extension;

	switch (blobType) {
		// Images
		case 'image/jpeg':
			extension = '.jpg';
			break;
		case 'image/png':
			extension = '.png';
			break;
		case 'image/gif':
			extension = '.gif';
			break;
		case 'image/bmp':
			extension = '.bmp';
			break;
		case 'image/tiff':
			extension = '.tiff';
			break;
		// Videos
		case 'video/mp4':
			extension = '.mp4';
			break;
		case 'video/quicktime':
			extension = '.mov';
			break;
		case 'video/webm':
			extension = '.webm';
			break;
		case 'video/ogg':
			extension = '.ogv';
			break;
		case 'video/x-msvideo':  
			extension = '.avi';
			break;
		case 'video/x-ms-wmv':  
			extension = '.wmv';
			break;
		case 'video/mpeg':  
			extension = '.mpeg';
			break;
		case 'video/x-flv':  
			extension = '.flv';
			break;
		case 'video/3gpp': 
			extension = '.3gp';
			break;
		case 'video/x-matroska': 
			extension = '.mkv';
			break;
		// Audio
		case 'audio/mpeg':
			extension = '.mp3';
			break;
		case 'audio/wav':
			extension = '.wav';
			break;
		case 'audio/webm':
			extension = '.webm';
			break;
		case 'audio/webm;codecs=opus':
			extension = '.webm';
			break;
		case 'audio/ogg':
			extension = '.ogg';
			break;
		case 'audio/flac':
			extension = '.flac';
			break;
		// Documents
		case 'application/pdf':
			extension = '.pdf';
			break;
		case 'application/msword':
			extension = '.docx';
			break;
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			extension = '.xlsx';
			break;
		case 'application/vnd.ms-excel':
			extension = '.xls';
			break;
		case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
			extension = '.pptx';
			break;
		case 'application/vnd.ms-powerpoint':
			extension = '.ppt';
			break;
		case 'text/plain':
			extension = '.txt';
			break;
		case 'text/csv':
			extension = '.csv';
			break;
		// Web content
		case 'text/html':
			extension = '.html';
			break;
		case 'application/xhtml+xml':
			extension = '.xhtml';
			break;
		// Archives
		case 'application/zip':
			extension = '.zip';
			break;
		case 'application/x-gzip':
			extension = '.gz';
			break;
		case 'application/x-bzip2':
			extension = '.bz2';
			break;
		// Add more cases for other file types as needed
		default:
			extension = '.dat'; // Default extension if type is unknown
			break;
	}

	// Generate a unique filename
	const uniqueFilename = new Date().getTime() + uniqueId() + extension;

	return uniqueFilename;
}

export const getFileExtnFromUrl = (url: string): string | undefined => {
	const path = url.split('#')[0].split('?')[0]; 
	const lastSegment = path.split('/').pop(); 
	if (!lastSegment) {
	  return undefined;
	}
	const parts = lastSegment.split('.');
	if (parts.length === 1 || (parts[0] === '' && parts.length === 2)) {
	  return undefined; 
	}
	const extension = parts.pop()?.toLowerCase(); 
	switch (extension) {
	  case 'jpg':
		return 'jpeg'; 
	  default:
		return extension; 
	}
  };

export const chatGPTName = (input?: string) =>
	(input ?? '')
		.replaceAll('.', '-')
		.replaceAll('/', '_')
		.replace(/[^a-zA-Z0-9_-]/g, '')
		.substring(0, 60);

export const removeDuplicates = (data :any) => {
	const map = new Map();
	data?.forEach((item :any) => {
		if (!map.has(item?.id)) {
			map.set(item?.id, item);
		}
	});
	return Array.from(map.values());
};

export const IsAIEnabled=(process.env.AI_Features === 'true') ? true : false;