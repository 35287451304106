import { AzureConfig } from '../Azure/azure-constants';
import { generateFilename } from '../util/util';
import {
	convertBlobToArrayBuffer,
} from '../GraphQL/sasToken/convert-file-to-arraybuffer';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import {
	GET_SAS_URL,
} from '../GraphQL/sasToken/mutation';
import { UserContext } from '../contexts/UserContext';
import { ClientContext } from '../contexts/ClientContext';
import { useContext } from 'react';
import useTranscribe from '../hooks/useTranscribe';

export default function useImageUpload() {
	const [getSasUrl, {}] = useMutation(GET_SAS_URL);
	const { user } = useContext(UserContext);
	const { activeClientId } = useContext(ClientContext);
	const {videoConverterFunc} = useTranscribe();

	const MAX_WIDTH = 320;
	const MAX_HEIGHT = 180;
	const MIME_TYPE = 'image/jpeg';
	const QUALITY = 0.4;

	function calculateSize(img: HTMLImageElement, maxWidth: number, maxHeight: number) {
		let width = img.width;
		let height = img.height;

		// calculate the width and height, constraining the proportions
		if (width > height) {
			if (width > maxWidth) {
				height = Math.round((height * maxWidth) / width);
				width = maxWidth;
			}
		} else {
			if (height > maxHeight) {
				width = Math.round((width * maxHeight) / height);
				height = maxHeight;
			}
		}
		return [width, height];
	}

	const uploadFileToBlobStorage = async ({ file }: { file: File | Blob }) => {
		try {
			const fileName = generateFilename(file?.type);
			const sasurl = await getSasUrl({
				variables: {
					containerName: !!file?.type?.includes('video')
						? AzureConfig.storageContainerVideoName
						: AzureConfig.storageContainerName,
					fileName,
					activeClientId,
					accessToken: user?.accessToken,
				},
			});
			const localSasUrl: string = sasurl?.data?.getgenerateSASURL;
			if (localSasUrl === '') {
				return {url: '', thumbnailUrl: ''};
			} else {
				try {
					const fileArrayBuffer = await convertBlobToArrayBuffer(file);
					if (fileArrayBuffer === null || fileArrayBuffer.byteLength < 1) {
						return {url: '', thumbnailUrl: ''};
					}
					try {
						const response = await axios.put(localSasUrl, fileArrayBuffer, {
							headers: { 'x-ms-blob-type': 'BlockBlob' },
						});
						if (response.status === 201) {
							if (!!file?.type?.includes('video')) {
								const data = await videoConverterFunc({
									videoUrl: localSasUrl,
									activeClientId,
									accessToken: user?.accessToken,
								});
								// expressDockerPostApi('convert-video', {
								// 	videoUrl: localSasUrl,
								// 	activeClientId,
								// 	accessToken: user?.accessToken,
								// });
								const baseUrl = localSasUrl?.split(
									AzureConfig.storageContainerVideoName,
								)?.[0];
								const outputContainer = AzureConfig.storageContainerVideoOutputName;
								const fileName = localSasUrl
									?.split('?')?.[0]
									?.split(`${activeClientId}/`)?.[1];
								const encodedUrl = `${baseUrl}${outputContainer}/${activeClientId}/${fileName?.replaceAll(".", "---")}/master.m3u8`;
								const thumbnailUrl = `${baseUrl}${outputContainer}/${activeClientId}/${fileName?.replaceAll(".", "---")}/thumnail.png`;
								return {url: encodedUrl, thumbnailUrl}
							}
							return {url: localSasUrl, thumbnailUrl: localSasUrl};
						} else {
							console.log(response.status, response.statusText);
							return {url: '', thumbnailUrl: ''};
						}
					} catch (error) {
						console.error(error);
						return {url: '', thumbnailUrl: ''};
					}
				} catch (error) {
					console.error('Error:', error);
					return {url: '', thumbnailUrl: ''};
				}
			}
		} catch (e) {
			console.log({ e });
			return {url: '', thumbnailUrl: ''};
		}
	};

	const uploadCompressedImages = async (file: File) => {
		let downloadUrl = '';
		const getUrl: Promise<string> = new Promise(async (resolve, reject) => {
			try {
				const blobUrl = URL.createObjectURL(file);
				const img = new Image();
				img.src = blobUrl;
				img.onerror = function () {
					URL.revokeObjectURL(this.src);
					console.log('Cannot load image');
					reject(new Error('Cannot load image'));
				};

				img.onload = async function () {
					URL.revokeObjectURL(img.src);
					const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
					const canvas = document.createElement('canvas');
					canvas.width = newWidth;
					canvas.height = newHeight;
					const ctx = canvas?.getContext('2d');
					ctx?.drawImage(img, 0, 0, newWidth, newHeight);

					await canvas.toBlob(
						async (blob) => {
							if (!!blob) {
								const downloadUrl = await uploadFileToBlobStorage({ file: blob });
								resolve(downloadUrl?.url); // Resolve the Promise with the download URL
							}
						},
						MIME_TYPE,
						QUALITY,
					);
				};

				// Handle errors that might occur during the process
				img.onerror = function () {
					URL.revokeObjectURL(this.src);
					console.log('Cannot load image');
					reject(new Error('Cannot load image'));
				};
			} catch (err) {
				reject(err);
			}
		});

		await getUrl.then((url) => {
			downloadUrl = url;
		});

		return downloadUrl;
	};

	const uploadBase64toStorage = async (blobUrl: string) => {
		try {
			const blob = await fetch(blobUrl).then((r) => r.blob());
			const response = await uploadFileToBlobStorage({ file: blob });
			return response;
		} catch (err) {
			return {
				url: '',
				thumbnailUrl: '',
			}
		}
	};

	return {
		uploadCompressedImages,
		uploadBase64toStorage,
		uploadFileToBlobStorage,
	};
}
