import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { Col, Form, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MouseEvent, useContext, useState } from 'react';
import {
	FileDocument,
	Images,
	Product,
	DataFragmentVM,
	DATAFRAGMENT_TYPE,
	SKILLBASEFRAGMENT_TYPE,
} from '../../../models/models';
import Spinner from '../../../components/bootstrap/Spinner';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { Modals } from '../../../components/Modals';
import { useModal } from '@ebay/nice-modal-react';
import Select from '../../../components/bootstrap/forms/Select';
import { isValidUrl, uniqueId, urlToFileObject } from '../../../util/util';
import FileInput from '../../../components/FileInput';
import Swal from 'sweetalert2';
import useTranscribe from '../../../hooks/useTranscribe';
import Icon from '../../../components/icon/Icon';
import { ClientContext } from '../../../contexts/ClientContext';
import { WorkflowContext } from '../../../contexts/WorkflowContext';
import useImageUpload from '../../../helpers/useImageUpload';
import AudioTranscriber from '../datalake/AudioTranscribe';
import DocumentPicker from '../datalake/documentPicker';
import FragmentImagePicker from '../datalake/fragmentImagePicker';
import { MdOutlineClose, MdOutlineLocationOn } from 'react-icons/md';
import { IoCalendarOutline } from 'react-icons/io5';
import { GetAllUsersContext } from '../../../contexts/getAllUsersContext';
import { PeopleUser } from '../../../Azure/models';

export interface UserFormPredefinedType {
	datalakeType: DATAFRAGMENT_TYPE;
	closeModal: () => void;
	setValueToForm: (e: string) => void;
	hideCloseButton?: boolean;
}

const UserForm = ({ predefinedData, selectedFragmentType }: { predefinedData?: UserFormPredefinedType, selectedFragmentType?: SKILLBASEFRAGMENT_TYPE }) => {
	const [description, setDescription] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const { activeClientId, activeClient } = useContext(ClientContext);
	const { workflow, workflowTemplate: wft } = useContext(WorkflowContext);
	const [selectedType, setSelectedType] = useState<DATAFRAGMENT_TYPE>(
		predefinedData?.datalakeType ?? 'product',
	);
	const {savePeople} = useContext(GetAllUsersContext);
	const modal = useModal(Modals.DATALAKE);

	const onTypeSelect = (e: any) => {
		setSelectedType(e?.target?.value);
	};
	const handleButtonClick = () => {
		if (window.confirm('Are you sure you want to close this tab?')) {
			modal.hide();
			if (!!predefinedData?.datalakeType) {
				predefinedData.closeModal();
			}
		}
	};
	const [isHovered, setIsHovered] = useState(false);
	const handleMouseEnter = () => {
		setIsHovered(true);
	};
	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const handleSubmit = async (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		event.preventDefault();
		setLoading(true);
		const tempUser: Partial<PeopleUser> = {
			firstName, lastName, headline, profileUrl, profilePictureUrl, location, industry, summary, currentPositions, pastPositions,
			education, skills, endorsements, recommendations, contactInfo, projects, experience, certification, highlights, profileType: selectedFragmentType
		};
		await savePeople(tempUser);
		if (!!predefinedData?.datalakeType) {
			predefinedData.closeModal();
		}
		setLoading(false);
	};
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [headline, setHeadline] = useState<string>("");
	const [profileUrl, setProfileUrl] = useState<string>("");
	const [profilePictureUrl, setPofilePictureUrl] = useState<string>("");
	const [location, setLocation] = useState({} as PeopleUser["location"]);

	const [industry, setIndustry] = useState<string>("");
	const [summary, setSummary] = useState<string>("");

	const [currentPositions, setCurrentPositions] = useState([] as PeopleUser["currentPositions"]);
	const [currentPositionTitle, setCurrentPositionTitle] = useState<string>("");
    const [currentPositionCompanyName, setCurrentPositionCompanyName] = useState<string>("");
    const [currentPositionsCompanyUrl, setCurrentPositionsCompanyUrl] = useState<string>("");
    const [currentPositionsLocation, setCurrentPositionsLocation] = useState<string>("");
    const [currentPositionStartDate, setCurrentPositionStartDate] = useState<string>("");
    const [currentPositionsEndDate, setCurrentPositionsEndDate] = useState<string>("");
    const [currentPositionDescription, setCurrentPositionDescription] = useState<string>("");

	const [pastPositions, setPastPositions] = useState([] as PeopleUser["pastPositions"]);
	const [pastPositionTitle, setPastPositionTitle] = useState<string>("");
    const [pastPositionCompanyName, setPastPositionCompanyName] = useState<string>("");
    const [pastPositionCompanyUrl, setPastPositionCompanyUrl] = useState<string>("");
	const [pastPositionsLocation, setPastPositionsLocation] = useState<string>("");
    const [pastPositionStartDate, setPastPositionStartDate] = useState<string>("");
    const [pastPositionEndDate, setPastPositionEndDate] = useState<string>("");
    const [pastPositionDescription, setPastPositionDescription] = useState<string>("");

	const [education, setEducation] = useState([] as PeopleUser["education"]);
	const [educationSchoolName, setEducationSchoolName] = useState<string>("");
    const [educationDegree, setEducationDegree] = useState<string>("");
    const [educationFieldOfStudy, setEducationFieldOfStudy] = useState<string>("");
    const [educationStartDate, setEducationStartDate] = useState<string>("");
    const [educationEndDate, setEducationEndDate] = useState<string>("");

	const [skills, setSkills] = useState([] as PeopleUser["skills"]);
	const [skill, setSkill] = useState<string>("");

	const [endorsements, setEndorsements] = useState([] as PeopleUser["endorsements"]);
	const [endorsementSkill, setEndorsementSkill] = useState<string>("");
    const [endorsementEndorsedBy, setEndorsementEndorsedBy] = useState<string>("");

	const [recommendations, setRecommendations] = useState([] as PeopleUser["recommendations"]);
	const [recommendationRecommenderName, setRecommendationRecommenderName] = useState<string>("");
    const [recommendationRecommenderProfileUrl, setRecommendationRecommenderProfileUrl] = useState<string>("");
    const [recommendationText, setRecommendationText] = useState<string>("");

	const [contactInfo, setContactInfo] = useState({} as PeopleUser["contactInfo"]);
	// const [contactInfoEmail, setContactInfoEmail] = useState<string>("");
    // const [contactInfoPhone, setContactInfoPhone] = useState<string>("");
    // const [contactInfoAddress, setContactInfoAddress] = useState<string>("");
    // const [contactInfoLinkedInProfileUrl, setContactInfoLinkedInProfileUrl] =  useState<string>("");

	const [projects, setProjects] = useState([] as PeopleUser["projects"]);
	const [projectTitle, setProjectTitle] = useState<string>("");
    const [projectDescription, setProjectDescription] = useState<string>("");
    const [projectUrl, setProjectUrl] = useState<string>("");
    const [projectStartDate, setProjectStartDate] = useState<string>("");
    const [projectEndDate, setProjectEndDate] = useState<string>("");
    const [projecRole, setProjecRole] = useState<string>("");

	const [experience, setExperience] = useState([] as PeopleUser["experience"]);
	const [experienceCompany, setExperienceCompany] = useState<string>(""); 
    const [experienceRole, setExperienceRole] = useState<string>("");
    const [experienceDuration, setExperienceDuration] = useState<string>("");

	const [certification, setCertification] = useState([] as PeopleUser["certification"]);
	const [certificationName, setCertificationName] = useState<string>("");
    const [certificationIssuingOrganization, setCertificationIssuingOrganization] = useState<string>("");
    const [certificationIssueDate, setCertificationIssueDate] = useState<string>("");

	const [highlights, setHighlights] = useState([] as PeopleUser["highlights"]);
	const [highlight, setHighlight] = useState<string>("");
	const handleSummaryChange = (value : any) => {
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = value;
		const textContent = tempDiv.textContent || tempDiv.innerText || '';
		setSummary(textContent);
	  };

	return (
		<Card className='position-relative '>
			<CardHeader>
				<CardLabel icon='perso' className='mb-6' iconColor='info'>
					<CardTitle className='capitalize'>Create New User</CardTitle>
				</CardLabel>
				<div className='d-flex justify-content-end' style={{ paddingRight: '15px' }}>
					<div
						className='d-flex justify-content-end py-4'
						style={{ marginRight: '25px' }}>
						<button
							className='btn btn-blue'
							type='submit'
							onClick={e => handleSubmit(e)}>
							{loading ? <Spinner /> : 'Save'}
						</button>
					</div>

					{!predefinedData?.hideCloseButton && (
						<button
							style={{
								fontSize: '20px',
								color: isHovered ? 'black' : 'grey',
								background: 'none',
								border: 'none',
								fontWeight: isHovered ? 'bold' : '',
							}}
							onClick={handleButtonClick}
							// onMouseEnter={handleMouseEnter}
							// onMouseLeave={handleMouseLeave}
						>
							<Icon icon='Close' size={'2x'} />
						</button>
					)}
				</div>
			</CardHeader>
			<CardBody className='pt-0'>
				<Form>
					<FormGroup className='mb-3'>
						<Form.Label>First Name</Form.Label>
						<Form.Control type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Last Name</Form.Label>
						<Form.Control type='text' value={lastName} onChange={(e) => setLastName(e.target.value)}/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Headline</Form.Label>
						<Form.Control type='text' value={headline} onChange={(e) => setHeadline(e.target.value)}/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Profile URL</Form.Label>
						<Form.Control type='text' value={profileUrl} onChange={(e) => setProfileUrl(e.target.value)}/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Profile Picture URL</Form.Label>
						<Form.Control type='text' value={profilePictureUrl} onChange={(e) => setPofilePictureUrl(e.target.value)}/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Location</Form.Label>
						<Form.Control className='mb-3' type='text' placeholder='Country' value={location.country} onChange={(e) => setLocation({...location, country: e.target.value})}/>
						<Form.Control type='text' placeholder='City' value={location.city} onChange={(e) => setLocation({...location, city: e.target.value})}/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Industry</Form.Label>
						<Form.Control type='text' value={industry} onChange={(e) => setIndustry(e.target.value)}/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Summary</Form.Label>
						<ReactQuill value={summary} onChange={handleSummaryChange}/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Current Position</Form.Label>
						<div className='mb-3'>
							<div className='mb-3 d-flex justify-content-between border rounded p-3'>
								<div>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											Senior Software Engineer
										</p>
										<p className='mb-2 text-muted'>
											<a href='https://www.example.com'>Example Corp </a>
										</p>
									</div>
									<div>
										<p>
											Leading the development of the company's main product.
										</p>
										<p className='mb-0 text-muted d-flex align-items-center gap-2'>
											<IoCalendarOutline /> 2020-01-01
										</p>
										<p className='mb-0 text-muted d-flex align-items-center gap-2'>
											<MdOutlineLocationOn /> San Francisco, CA
										</p>
									</div>
								</div>
								<MdOutlineClose size={20} className='cursor-pointer' />
							</div>
						</div>
						<div className='border rounded p-4'>
							<Form.Control className='mb-3' type='text' placeholder='Title' value={currentPositionTitle} onChange={(e) => setCurrentPositionTitle(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Company Name' value={currentPositionCompanyName} onChange={(e) => setCurrentPositionCompanyName(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Company URL' value={currentPositionsCompanyUrl} onChange={(e) => setCurrentPositionsCompanyUrl(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Location' value={currentPositionsLocation} onChange={(e) => setCurrentPositionsLocation(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Start Date' value={currentPositionStartDate} onChange={(e) => setCurrentPositionStartDate(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Description' value={currentPositionDescription} onChange={(e) => setCurrentPositionDescription(e.target.value)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setCurrentPositions([...currentPositions, {
									title: currentPositionTitle,
									companyName: currentPositionCompanyName,
									companyUrl: currentPositionsCompanyUrl,
									location: currentPositionsLocation,
									startDate: currentPositionStartDate,
									endDate: currentPositionsEndDate,
									description: currentPositionDescription
								}])
							}}>
								Add
							</button>
						</div>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Past Position</Form.Label>
						<div className='mb-3'>
							<div className='mb-3 d-flex justify-content-between border rounded p-3'>
								<div>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											Software Engineer
										</p>
										<p className='mb-2 text-muted'>
											<a href='https://www.example.com'>Another Corp </a>
										</p>
									</div>
									<div>
										<p>Worked on various web applications.</p>
										<p className='mb-0 text-muted d-flex align-items-center gap-2'>
											<IoCalendarOutline /> 2015-06-01 to 2019-12-31
										</p>
										<p className='mb-0 text-muted d-flex align-items-center gap-2'>
											<MdOutlineLocationOn /> New York, NY
										</p>
									</div>
								</div>
								<MdOutlineClose size={20} className='cursor-pointer' />
							</div>
						</div>
						<div className='border rounded p-4'>
							<Form.Control className='mb-3' type='text' placeholder='Title' value={pastPositionTitle} onChange={(e) => setPastPositionTitle(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Company Name' value={pastPositionCompanyName} onChange={(e) => setPastPositionCompanyName(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Company Url' value={pastPositionCompanyUrl} onChange={(e) => setPastPositionCompanyUrl(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Start Date' value={pastPositionStartDate} onChange={(e) => setPastPositionStartDate(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Location' value={pastPositionsLocation} onChange={(e) => setPastPositionsLocation(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='End Date' value={pastPositionEndDate} onChange={(e) => setPastPositionEndDate(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Description' value={pastPositionDescription} onChange={(e) => setPastPositionDescription(e.target.value)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setPastPositions([...pastPositions, {
									title: pastPositionTitle,
									companyName: pastPositionCompanyName,
									companyUrl: pastPositionCompanyUrl,
									location: pastPositionsLocation,
									startDate: pastPositionStartDate,
									endDate: pastPositionEndDate,
									description: pastPositionDescription
								}])
							}}>
								Add
							</button>
						</div>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Education</Form.Label>
						<div className='mb-3'>
							<div className='mb-3 d-flex justify-content-between border rounded p-3'>
								<div>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											University of Example
										</p>
										<p className='mb-2 text-muted'>Bachelor of Science</p>
									</div>
									<div>
										<p>Computer Science</p>
										<p className='mb-0 text-muted d-flex align-items-center gap-2'>
											<IoCalendarOutline /> 2011-09-01 to 2015-06-01
										</p>
									</div>
								</div>
								<MdOutlineClose size={20} className='cursor-pointer' />
							</div>
						</div>
						<div className='rounded border p-4'>
							<Form.Control className='mb-3' type='text' placeholder='School Name' value={educationSchoolName} onChange={(e) => setEducationSchoolName(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Degree' value={educationDegree} onChange={(e) => setEducationDegree(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Field of Study' value={educationFieldOfStudy} onChange={(e) => setEducationFieldOfStudy(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Start Date' value={educationStartDate} onChange={(e) => setEducationStartDate(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='End Date' value={educationEndDate} onChange={(e) => setEducationEndDate(e.target.value)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setEducation([...education, {
									schoolName: educationSchoolName,
									degree: educationDegree,
									fieldOfStudy: educationFieldOfStudy,
									startDate: educationStartDate,
									endDate: educationEndDate
								}])
							}}>
								Add
							</button>
						</div>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Skills</Form.Label>
						<div className='d-flex align-items-center gap-3 mb-3 flex-wrap'>
							<div className='tag d-flex align-items-center justify-content-between w-auto border rounded p-1 gap-2'>
								<p className='mb-0'>JavaScript </p>
								<MdOutlineClose className='text-muted cursor-pointer' size={16} />
							</div>
							<div className='tag d-flex align-items-center justify-content-between w-auto border rounded p-1 gap-2'>
								<p className='mb-0'>TypeScript</p>
								<MdOutlineClose className='text-muted cursor-pointer' size={16} />
							</div>
							<div className='tag d-flex align-items-center justify-content-between w-auto border rounded p-1 gap-2'>
								<p className='mb-0'>React </p>
								<MdOutlineClose className='text-muted cursor-pointer' size={16} />
							</div>
							<div className='tag d-flex align-items-center justify-content-between w-auto border rounded p-1 gap-2'>
								<p className='mb-0'>Node.js</p>
								<MdOutlineClose className='text-muted cursor-pointer' size={16} />
							</div>
						</div>
						<div className='p-4 rounded border'>
							<Form.Control type='text' placeholder='skill' className='mb-3' value={skill} onChange={(e) => setSkill(e.target.value)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setSkills([...skills.filter(ski => ski !== skill), skill]);
							}}>
								Add
							</button>
						</div>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Endorsements</Form.Label>
						<div className='mb-3'>
							<div className='mb-3 d-flex justify-content-between border rounded p-3'>
								<div>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											Jane Smith
										</p>
										<p className='mb-2 text-muted'>JavaScript</p>
									</div>
								</div>
								<MdOutlineClose size={20} className='cursor-pointer' />
							</div>
						</div>
						<div className='border rounded p-4'>
							<Form.Control className='mb-3' type='text' placeholder='Skill' value={endorsementSkill} onChange={(e) => setEndorsementSkill(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Endorsed By' value={endorsementEndorsedBy} onChange={(e) => setEndorsementEndorsedBy(e.target.value)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setEndorsements([...endorsements, {
									skill: endorsementSkill,
									endorsedBy: endorsementEndorsedBy
								}])
							}}>
								Add
							</button>
						</div>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Recommendations</Form.Label>
						<div className='mb-3'>
							<div className='mb-3 d-flex justify-content-between border rounded p-3'>
								<div>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											Jane Smith
										</p>
										<a
											href='https://www.linkedin.com/in/janesmith'
											className='mb-2'>
											https://www.linkedin.com/in/janesmith
										</a>
										<p className='mb-2 text-muted'>
											John is a fantastic software engineer and a great team
											player.
										</p>
									</div>
								</div>
								<MdOutlineClose size={20} className='cursor-pointer' />
							</div>
						</div>
						<div className='rounded border p-4'>
							<Form.Control
								className='mb-3'
								type='text'
								placeholder='Recommender Name'
								value={recommendationRecommenderName} onChange={(e) => setRecommendationRecommenderName(e.target.value)}
							/>
							<Form.Control
								className='mb-3'
								type='text'
								placeholder='Recommender Profile URL'
								value={recommendationRecommenderProfileUrl} onChange={(e) => setRecommendationRecommenderProfileUrl(e.target.value)}
							/>
							<ReactQuill className='mb-3' value={recommendationText} onChange={(e) => setRecommendationText(e)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setRecommendations([...recommendations, {
									recommenderName: recommendationRecommenderName,
									recommenderProfileUrl: recommendationRecommenderProfileUrl,
									text: recommendationText
								}])
							}}>
								Add
							</button>
						</div>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Contact Info</Form.Label>
						<Form.Control className='mb-3' type='email' placeholder='Email' value={contactInfo.email} onChange={(e) => setContactInfo({...contactInfo, email: e.target.value})}/>
						<Form.Control
							className='mb-3'
							type='text'
							placeholder='LinkedIn Profile URL'
							value={contactInfo.linkedInProfileUrl} onChange={(e) => setContactInfo({...contactInfo, linkedInProfileUrl: e.target.value})}
						/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Projects</Form.Label>
						<div className='mb-3'>
							<div className='mb-3 d-flex justify-content-between border rounded p-3'>
								<div>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											Project A
										</p>

										<p className='mb-1 text-muted'>Lead Developer</p>
										<p className='mb-0'>
											<a href='https://example.com/project-a'>
												Example Corp{' '}
											</a>
										</p>
									</div>
									<div>
										<p>
											Developed a scalable web application for managing tasks.
										</p>
										<p className='mb-0 text-muted d-flex align-items-center gap-2'>
											<IoCalendarOutline /> 2018-01-01 to 2018-12-31
										</p>
									</div>
								</div>
								<MdOutlineClose size={20} className='cursor-pointer' />
							</div>
						</div>
						<div className='border rounded p-4'>
							<Form.Control className='mb-3' type='text' placeholder='Title' value={projectTitle} onChange={(e) => setProjectTitle(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Description' value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='URL' value={projectUrl} onChange={(e) => setProjectUrl(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Start Date' value={projectStartDate} onChange={(e) => setProjectStartDate(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='End Date' value={projectEndDate} onChange={(e) => setProjectEndDate(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Role' value={projecRole} onChange={(e) => setProjecRole(e.target.value)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setProjects([...projects, {
									title: projectTitle,
									description: projectDescription,
									url: projectUrl,
									startDate: projectStartDate,
									endDate: projectEndDate,
									role: projecRole
								}])
							}}>
								Add
							</button>
						</div>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Highlights</Form.Label>
						<div className='mb-3'>
							<div className='mb-3 d-flex justify-content-between border rounded p-3'>
								<p className='mb-1 fw-semibold'>
									Successfully managed large-scale construction projects
								</p>

								<MdOutlineClose size={20} className='cursor-pointer' />
							</div>
						</div>
						<div className='border rounded p-4'>
							<Form.Control type='text' className='mb-3' placeholder='highlight' value={highlight} onChange={(e) => setHighlight(e.target.value)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setHighlights([...highlights.filter(hig => hig !== highlight), highlight]);
							}}>
								Add
							</button>
						</div>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Experience</Form.Label>
						<div className='mb-3'>
							<div className='mb-3 d-flex justify-content-between border rounded p-3'>
								<div>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											BuildIt Inc
										</p>

										<p className='mb-1 text-muted'>
											Construction Project Manager
										</p>
									</div>
									<div>
										<p className='mb-0 text-muted d-flex align-items-center gap-2'>
											<IoCalendarOutline /> Jan 2017 - Present
										</p>
									</div>
								</div>
								<MdOutlineClose size={20} className='cursor-pointer' />
							</div>
						</div>
						<div className='rounded border p-4'>
							<Form.Control className='mb-3' type='text' placeholder='Company' value={experienceCompany} onChange={(e) => setExperienceCompany(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Role' value={experienceRole} onChange={(e) => setExperienceRole(e.target.value)}/>
							<Form.Control className='mb-3' type='text' placeholder='Duration' value={experienceDuration} onChange={(e) => setExperienceDuration(e.target.value)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setExperience([...experience, {
									company: experienceCompany,
									role: experienceRole,
									duration: experienceDuration
								}])
							}}>
								Add
							</button>
						</div>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>Certification</Form.Label>
						<div className='mb-3'>
							<div className='mb-3 d-flex justify-content-between border rounded p-3'>
								<div>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											PMI
										</p>

										<p className='mb-1 text-muted'>
											Project Management Professional (PMP)
										</p>
									</div>
									<div>
										<p className='mb-0 text-muted d-flex align-items-center gap-2'>
											<IoCalendarOutline /> May 2017
										</p>
									</div>
								</div>
								<MdOutlineClose size={20} className='cursor-pointer' />
							</div>
						</div>
						<div className='rounded border p-4'>
							<Form.Control className='mb-3' type='text' placeholder='Name' value={certificationName} onChange={(e) => setCertificationName(e.target.value)}/>
							<Form.Control
								className='mb-3'
								type='text'
								placeholder='Issuing Organization'
								value={certificationIssuingOrganization} onChange={(e) => setCertificationIssuingOrganization(e.target.value)}
							/>
							<Form.Control className='mb-3' type='text' placeholder='Issue Date' value={certificationIssueDate} onChange={(e) => setCertificationIssueDate(e.target.value)}/>
							<button className='btn btn-blue-bordered' style={{ width: 100 }} onClick={e => {
								e.preventDefault();
								setCertification([...certification, {
									name: certificationName,
									issuingOrganization: certificationIssuingOrganization,
									issueDate: certificationIssueDate
								}])
							}}>
								Add
							</button>
						</div>
					</FormGroup>
				</Form>
			</CardBody>
			{/* <CardFooter className='position-relative'> */}
			{/* <ModalFooter className='w-100'>
				<div className='d-flex justify-content-end py-4'>
					<button
						className='btn btn-blue'
						type='submit'
						onClick={(e) => handleProductSubmit(e)}>
						{loading ? <Spinner /> : 'Save'}
					</button>
				</div>
			</ModalFooter> */}
			{/* </CardFooter> */}
		</Card>
	);
};

export default UserForm;
