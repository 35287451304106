import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';

import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardSubTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import ScrollContainer from 'react-indiana-drag-scroll';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import Icon from '../../../components/icon/Icon';
import demoImg from '../../../assets/img/demo/bottom-shell-demo.png';
import Swal from 'sweetalert2';
import actionDemoImg from '../../../assets/img/demo/bottom-inclined-demo.png';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { WorkflowContext } from '../../../contexts/WorkflowContext';
import { DataFragment, FieldData, WorkflowOutput } from '../../../models/models';
import { FaPlus } from 'react-icons/fa';
import xss from 'xss';
import { shorten, uniqueId } from '../../../util/util';
import { DatalakePanelProps } from '../workflow/DatalakePanel';
import { HLSVideoPlayerWithQuality } from '../../../components/hls-video-player';
import OutputPanel from './OutputPanel';
import { Modals } from '../../../components/Modals';
import { BsInfoCircle } from 'react-icons/bs';
import { useModal } from '@ebay/nice-modal-react';

interface MissionAssetsPanelProps extends DatalakePanelProps {
	setActivePanel: Dispatch<
		SetStateAction<
			'card-view' | 'sandbox-view' | 'flowchart-view' | 'videoOutput' | 'document-view'
		>
	>;
	collapsePanel: boolean;
	setCollapsePanel: Dispatch<SetStateAction<boolean>>;
	activePanel?: 'card-view' | 'sandbox-view' | 'flowchart-view' | 'videoOutput' | 'document-view';
	currentFileDropData?: { url: string; type: 'video' | 'image' | '' };
	setCurrentFileDropData: (a: { url: string; type: 'video' | 'image' | '' }) => void;
	activeFilterAssetsPanel: string;
	setActiveFilterAssetsPanel: Dispatch<SetStateAction<string>>;
	closeProcessingModal: () => void;
	selectedAsset: DataFragment | undefined;
	setSelectedAsset: Dispatch<SetStateAction<DataFragment | undefined>>;
}

export const VideoView = ({
	asset,
	currentFileDropData,
	setCurrentFileDropData,
}: {
	asset: DataFragment;
	currentFileDropData?: { url: string; type: 'video' | 'image' | '' };
	setCurrentFileDropData: (a: { url: string; type: 'video' | 'image' | '' }) => void;
}) => {
	const videoUrl = asset?.videos?.[0]?.url;
	return (
		<>
			{!!videoUrl && (
				<div
					className='custom-video-player'
					draggable
					onDragStart={() => {
						setCurrentFileDropData({ url: videoUrl, type: 'video' });
					}}>
					<HLSVideoPlayerWithQuality
						className='react-player'
						url={asset?.videos?.[0]?.url}
						width={'-webkit-fill-available'}
						style={{ maxWidth: '100%', objectFit: 'contain' }}
						height={'-webkit-fill-available'}
					/>
				</div>
			)}
		</>
	);
};
export const ImageCardMissionAsset = ({
	asset,
	setCurrentFileDropData,
	currentFileDropData,
	activeFilterAssetsPanel,
	setSelectedAsset,
}: {
	asset: DataFragment;
	currentFileDropData?: { url: string; type: 'video' | 'image' | '' };
	setCurrentFileDropData: (a: { url: string; type: 'video' | 'image' | '' }) => void;
	activeFilterAssetsPanel: string;
	setSelectedAsset: Dispatch<SetStateAction<DataFragment | undefined>>;
}) => {
	const images = asset?.images;
	const imageRef = useRef<HTMLImageElement | null>(null);

	const openFullscreen = () => {
		if (imageRef.current) {
			if (imageRef.current.requestFullscreen) {
				imageRef.current.requestFullscreen();
			} else if ((imageRef.current as any).webkitRequestFullscreen) {
				// Safari
				(imageRef.current as any).webkitRequestFullscreen();
			} else if ((imageRef.current as any).msRequestFullscreen) {
				// IE11
				(imageRef.current as any).msRequestFullscreen();
			}
		}
	};

	return (
		<>
			{((activeFilterAssetsPanel === 'all' || activeFilterAssetsPanel === 'Photos') &&
				!!asset?.images?.length) ||
			((activeFilterAssetsPanel === 'all' || activeFilterAssetsPanel === 'Documents') &&
				!!asset?.documents?.length) ? (
				<div
					className='row-item position-relative card img-card black-card mb-3 overflow-y-auto'
					style={asset?.images && asset?.images?.length > 9 ? { width: '100%' } : {}}
					key={asset?.id}>
					<div className='card-head-section px-3 pb-2 pt-3'>
						<div className='d-flex justify-content-between align-items-center'>
							<p
								className='mb-2'
								dangerouslySetInnerHTML={{
									__html: shorten(
										xss(
											asset?.name
												? asset?.name
												: asset?.text
												? asset?.text
												: '',
										),
										20,
									),
								}}></p>
							{asset?.voice ? (
								<BsInfoCircle
									className='cursor-pointer'
									size={20}
									onClick={() => setSelectedAsset(asset)}
								/>
							) : (
								<></>
							)}
						</div>
					</div>
					<div className='card-body-section'>
						{(activeFilterAssetsPanel === 'all' ||
							activeFilterAssetsPanel === 'Photos') &&
						asset?.images &&
						asset?.images?.length > 1 ? (
							<div className='container'>
								<div className='image-grid'>
									{asset?.images?.map((image, index) => (
										<div
											key={index}
											className={
												asset?.images && asset?.images?.length > 9
													? 'multi-image-grid'
													: 'image-grid-item'
											}>
											{
												!!images?.[index]?.url && (
													<img
														src={images?.[index]?.url}
														onDragStart={() => {
															setCurrentFileDropData({
																url: images?.[index]?.url,
																type: 'image',
															});
														}}
														alt={`img-${index}`}
														loading={'lazy'}
													/>
												)
												// : (
												// 	<div className='imageSkeletonLoader'></div>
												// )
											}
										</div>
									))}
								</div>
							</div>
						) : (activeFilterAssetsPanel === 'all' ||
								activeFilterAssetsPanel === 'Photos') &&
						  asset?.images &&
						  asset?.images?.length === 1 ? (
							<div className='d-flex justify-content-center'>
								{
									!!images?.[0]?.url && (
										<img
											src={images?.[0]?.url}
											alt='img'
											onDragStart={() => {
												setCurrentFileDropData({
													url: images?.[0]?.url,
													type: 'image',
												});
											}}
											loading={'lazy'}
											style={{ maxWidth: '-webkit-fill-available' }}
											ref={imageRef}
											onClick={openFullscreen}
										/>
									)
									// : (
									// 	<div
									// 		className='imageSkeletonLoader'
									// 		style={{ width: '200px', height: '200px' }}></div>
									// )
								}
							</div>
						) : (
							// <div className='imageSkeletonLoader mx-auto'></div>
							<></>
						)}
						{
							(activeFilterAssetsPanel === 'all' ||
								activeFilterAssetsPanel === 'Documents') &&
								asset?.documents &&
								asset?.documents?.length >= 0 && (
									<div className='container'>
										<div className='image-grid'>
											{(asset?.documents ?? [])?.map((item, index) => {
												return (
													<div
														key={index}
														className={
															asset?.documents &&
															asset?.documents?.length > 9
																? 'multi-image-grid'
																: asset?.documents?.length == 1
																? 'd-flex align-items-center justify-content-center w-100'
																: 'image-grid-item'
														}>
														<div
															key={index}
															className='position-relative cursor-pointer px-2 black-card mb-3 d-flex align-items-center justify-content-center'
															style={{}}>
															<div className='text-center'>
																<a
																	className='mb-0'
																	href={item?.url}
																	target='_blank'
																	style={{
																		width: '100%',
																		height: '100%',
																		backgroundColor:
																			'transparent',
																	}}>
																	<p className='mb-0 small-sub-title capitalize text-bg-brand-two'>
																		<Icon
																			icon='Picture As Pdf'
																			size={'5x'}
																			className=''
																		/>
																	</p>
																</a>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								)
							//  : (
							// 	<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
							// 		<FaPlus size={75} color='#71788B' className='mt-5' />
							// 	</div>
							// )
						}
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export const VideoCardMissionAsset = ({
	asset,
	setCurrentFileDropData,
	currentFileDropData,
	setSelectedAsset,
}: {
	asset: DataFragment;
	currentFileDropData?: { url: string; type: 'video' | 'image' | '' };
	setCurrentFileDropData: (a: { url: string; type: 'video' | 'image' | '' }) => void;
	setSelectedAsset: Dispatch<SetStateAction<DataFragment | undefined>>;
}) => {
	return (
		<div
			className='row-item position-relative card video-card black-card mb-3'
			style={{}}
			key={asset?.id}>
			<div className='card-head-section  px-3 pb-2 pt-3'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='mt-1'>
						<p className='capitalize mb-1 card-title-text'>
							{asset?.name ?? asset?.text}
						</p>
					</div>
					{asset?.voice ? (
						<BsInfoCircle
							className='cursor-pointer'
							size={20}
							onClick={() => setSelectedAsset(asset)}
						/>
					) : (
						<></>
					)}
				</div>
			</div>
			<div className='card-body-section'>
				<VideoView
					asset={asset}
					currentFileDropData={currentFileDropData}
					setCurrentFileDropData={setCurrentFileDropData}
				/>
			</div>
		</div>
	);
};
export const ActionImageCardMissionAsset = ({ asset }: { asset: DataFragment }) => {
	return (
		<div
			className='row-item position-relative card img-card black-card mb-3 overflow-visible'
			style={{}}>
			<div className='card-head-section  px-3 pb-2 pt-3'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='mt-1'>
						<p className='capitalize mb-1 card-title-text'>
							{asset?.name ?? asset?.text}
						</p>
					</div>
					<CardActions>
						<Dropdown className='show main-contact-star'>
							<DropdownToggle hasIcon={false}>
								<Button
									icon='MoreHoriz'
									// color={}
									shadow='default'
									aria-label='Edit'
									className='p-0'
								/>
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem className='dropdown-item'>
									<Button icon='edit'>Edit</Button>
								</DropdownItem>{' '}
								<DropdownItem className='dropdown-item'>
									<Button icon='Delete'>Delete</Button>
								</DropdownItem>{' '}
							</DropdownMenu>
						</Dropdown>
					</CardActions>
				</div>
			</div>
			<div className='card-body-section'>
				<img
					src={actionDemoImg}
					alt='img'
					loading={'lazy'}
					className='img-fluid objectfit-contain px-5'
				/>
			</div>
		</div>
	);
};
const OutputCardMissionAsset = ({
	asset,
	output,
	setActivePanel,
	currentWorkflowOutput,
	setCurrentWorkflowOutput,
}: {
	asset: FieldData;
	output: WorkflowOutput;
	setActivePanel: React.Dispatch<
		React.SetStateAction<
			'card-view' | 'sandbox-view' | 'flowchart-view' | 'videoOutput' | 'document-view'
		>
	>;
	currentWorkflowOutput?: WorkflowOutput;
	setCurrentWorkflowOutput: (a: WorkflowOutput) => void;
}) => {
	const [downloading, setDownloading] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const { deleteWorkflowOutput } = useContext(WorkflowContext);

	const download = async () => {
		if (!!output?.data?.videoUrl) {
			setDownloading(true);

			const response = await fetch(output?.data?.videoUrl);
			// Create a Blob object from the video data.
			const blob = await response.blob();

			const timestamp = new Date().toISOString().replace(/[-:]/g, '').replace(/\..+/, '');
			const fileName = `${uniqueId()?.toLowerCase().replace(/\s/g, '_')}_${timestamp}.mp4`;
			// Create a download link.
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			// link.download = `${uniqueId()?.toLowerCase().replace(/\s/g, '_')}.mp4`;
			link.download = fileName;
			link.classList.add('hidden');
			// Append the download link to the document body.
			document.body.appendChild(link);
			// Click on the download link.
			link.click();
			// Remove the download link from the document body.
			document.body.removeChild(link);
			setDownloading(false);
		}
	};

	const deleteCurrentOutput = async () => {
		await setDeleting(true);
		const result = await Swal.fire({
			title: 'Confirmation!',
			text: 'Are you sure, you want to delete this workflow output?',
			allowOutsideClick: true,
			showCancelButton: true,
			confirmButtonText: 'Okay',
			cancelButtonColor: '#38cab3',
			confirmButtonColor: 'rgba(0, 56, 255, 1)',
		});
		if (result?.isConfirmed) {
			await deleteWorkflowOutput(output);
		}
		await setDeleting(false);
	};

	const imageUrl = asset?.imageUrl;
	const videoUrl = asset?.attachmentUrl;

	return (
		<div
			className={`row-item position-relative card img-card black-card mb-3 ${
				currentWorkflowOutput?.id === output?.id ? 'border-primary' : ''
			}`}
			onClick={() => {
				if (output?.outputType === 'visual') {
					setActivePanel('videoOutput');
				} else if (output?.outputType === 'whiteboard') {
					setActivePanel('sandbox-view');
				}
				setCurrentWorkflowOutput(output);
			}}>
			<div className='card-head-section  px-3 pb-2 pt-3'>
				<div className='d-flex justify-content-between align-items-center'>
					<div
						className='mt-1'
						dangerouslySetInnerHTML={{
							__html: shorten(xss(asset?.text ?? ''), 200),
						}}></div>
					<CardActions>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button
									// color={darkModeStatus ? 'light' : 'dark'}
									isLink
									className='p-0'
									hoverShadow='default'
									icon='MoreHoriz'
									aria-label='More Actions'
								/>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd>
								<DropdownItem>
									<Button
										icon='RemoveRedEye'
										onClick={() => {
											if (output?.outputType === 'visual') {
												setActivePanel('videoOutput');
											} else if (output?.outputType === 'whiteboard') {
												setActivePanel('sandbox-view');
											}
											setCurrentWorkflowOutput(output);
										}}>
										View
									</Button>
								</DropdownItem>
								<DropdownItem>
									<Button
										icon={downloading ? 'Downloading' : 'Download'}
										onClick={download}
										isDisable={downloading}>
										{/* {sendingToAll ? <Spinner isSmall inButton /> : */}
										{downloading ? 'Downloading...' : 'Download'}
									</Button>
								</DropdownItem>
								<DropdownItem>
									<Button
										icon={downloading ? 'Deleteing' : 'Delete'}
										isDisable={deleting}
										onClick={() => deleteCurrentOutput()}>
										{deleting ? 'Deleting...' : 'Delete'}
									</Button>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</CardActions>
				</div>
			</div>
			<div className='card-body-section'>
				{!!videoUrl ? (
					<video className='objectfit-cover h-100 w-100 px-3' controls>
						<source
							src={videoUrl}
							onClick={() => window.open(asset?.attachmentUrl, '_blank')}
							type='video/mp4'
						/>
					</video>
				) : !!imageUrl ? (
					<img
						src={imageUrl}
						alt='img'
						loading={'lazy'}
						onClick={() => window.open(imageUrl, '_blank')}
						className='img-fluid objectfit-contain pt-5 pb-2 px-5'
						style={{ height: '16vh' }}
					/>
				) : (
					<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
						<FaPlus size={75} color='#71788B' className='mt-5' />
					</div>
				)}
			</div>
		</div>
	);
};

// interface MissionAssetsPanelProps {
// 	collapsePanel: boolean;
// 	setCollapsePanel: Dispatch<SetStateAction<boolean>>;
// 	activePanel?: 'card-view' | 'sandbox-view' | 'flowchart-view' | "videoOutput";
//  activeTab: 'datalake' | 'output';
// 	currentWorkflowOutput?: WorkflowOutput;
// 	setCurrentWorkflowOutput: (a: WorkflowOutput) => void;
// 	currentFileDropData?: {url: string, type: "video" | "image" | ""};
// 	setCurrentFileDropData: (a: {url: string, type: "video" | "image" | ""}) => void;
// }

function MissionAssetsPanel({
	collapsePanel,
	setCollapsePanel,
	activePanel,
	setCurrentWorkflowOutput,
	currentWorkflowOutput,
	setActivePanel,
	currentFileDropData,
	setCurrentFileDropData,
	activeFilterAssetsPanel,
	setActiveFilterAssetsPanel,
	closeProcessingModal,
	selectedAsset,
	setSelectedAsset,
}: MissionAssetsPanelProps) {
	const { workflow, workflowOutput } = useContext(WorkflowContext);
	const assetModal = useModal(Modals.ASSET_DETAILS);
	const { allDataFragments } = useContext(DatalakeContext);
	let assets = [
		...((workflow?.data?.['material'] ?? []) as FieldData[]),
		...((workflow?.data?.['document'] ?? []) as FieldData[]),
		...((workflow?.data?.['object'] ?? []) as FieldData[]),
	].filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index);
	const filters = ['all', 'Videos', 'Photos', 'Documents'];

	if (activeFilterAssetsPanel === 'examples') {
		assets = (workflow?.data?.['object'] ?? []) as FieldData[];
	}
	// if (activeFilterAssetsPanel === 'materials') {
	// 	assets = (workflow?.data?.['material'] ?? []) as FieldData[];
	// }
	if (activeFilterAssetsPanel === 'attachments') {
		assets = (workflow?.data?.['document'] ?? []) as FieldData[];
	}

	useEffect(() => {
		if (selectedAsset?.voice) {
			console.log({ selectedAsset });
			assetModal.show();
		}
	}, [selectedAsset]);
	return (
		<div
			className={`d-none ${
				collapsePanel ? 'slide-in-right-reverse' : 'slide-in-right'
			} mission-asset-panel d-lg-block col-lg-4 p-2 pt-0`}>
			<div className=''>
				<Card className='h-100 cursor-pointer mission-asset-card'>
					<CardHeader className='bg-transparent flex-column pb-0' style={{ height: 115 }}>
						<div className='w-100 d-flex align-items-center justify-content-between'>
							<CardSubTitle
								tag='div'
								className='fs-5 d-flex gap-2 align-items-center'
								onClick={() => setCollapsePanel(!collapsePanel)}>
								Mission Assets{' '}
								<Icon style={{ color: 'blue' }} icon='Double Arrow' />
							</CardSubTitle>
						</div>
						<ScrollContainer horizontal={true} className='scroll-container w-100'>
							<div className=' d-flex align-items-center  gap-1 py-2'>
								{filters?.map((value, index) => (
									<Button
										style={{ flexShrink: 0 }}
										key={index}
										className={`btn mb-2 mb-md-0 ${
											activeFilterAssetsPanel == value
												? 'btn-dark active-btn'
												: 'btn-grey'
										} uppercase`}
										onClick={() => setActiveFilterAssetsPanel(value)}>
										{value}
									</Button>
								))}
							</div>
						</ScrollContainer>
					</CardHeader>
					<CardBody
						style={{
							height: 'calc(100vh - 115px)',
							overflowY: 'scroll',
						}}>
						{/* {activePanel == 'sandbox-view' || activePanel == "videoOutput" ? (
							<div className='d-flex flex-wrap align-items-start justify-content-start gap-1 w-100 mb-3'>
								<Button
									className={`mb-2 mb-md-0 ${activeTab == 'datalake'
											? 'rectangle-grey-btn'
											: 'rectangle-btn btn-transparent theme-gray-text'
										} uppercase py-1 px-2 small`}
									onClick={() => setActiveTab('datalake')}>
									Datalake
								</Button>
								<Button
									className={`mb-2 mb-md-0 ${activeTab == 'output'
											? 'rectangle-grey-btn'
											: 'rectangle-btn btn-transparent theme-gray-text'
										} uppercase py-1 px-2 small`}
									onClick={() => setActiveTab('output')}>
									Output
								</Button>
							</div>
						) : (
							<></>
						)} */}
						{/* {activeFilterAssetsPanel === 'generated' ? (
							<OutputPanel
								setActivePanel={setActivePanel}
								activePanel={activePanel}
								currentWorkflowOutput={currentWorkflowOutput}
								currentFileDropData={currentFileDropData}
								setCurrentFileDropData={setCurrentFileDropData}
								setCurrentWorkflowOutput={setCurrentWorkflowOutput}
								closeProcessingModal={closeProcessingModal}
							/>
						) : */}

						<div className='data-grid d-flex justify-content-between align-items-start flex-wrap'>
							{assets?.map((asset, index) => {
								const fragment = allDataFragments?.find((i) => i?.id === asset?.id);
								return (
									<>
										{!!fragment?.id &&
										(activeFilterAssetsPanel === 'all' ||
											activeFilterAssetsPanel === 'Videos') &&
										fragment.videos?.length ? (
											<VideoCardMissionAsset
												asset={fragment}
												key={index}
												currentFileDropData={currentFileDropData}
												setCurrentFileDropData={setCurrentFileDropData}
												setSelectedAsset={setSelectedAsset}
											/>
										) : !!fragment?.id &&
										  (activeFilterAssetsPanel === 'all' ||
												activeFilterAssetsPanel == 'Photos' ||
												activeFilterAssetsPanel == 'Documents') ? (
											<ImageCardMissionAsset
												asset={fragment}
												key={index}
												currentFileDropData={currentFileDropData}
												setCurrentFileDropData={setCurrentFileDropData}
												activeFilterAssetsPanel={activeFilterAssetsPanel}
												setSelectedAsset={setSelectedAsset}
											/>
										) : (
											<></>
										)}
									</>
								);
							})}

							<OutputPanel
								setActivePanel={setActivePanel}
								activePanel={activePanel}
								activeFilterAssetsPanel={activeFilterAssetsPanel}
								currentWorkflowOutput={currentWorkflowOutput}
								currentFileDropData={currentFileDropData}
								setCurrentFileDropData={setCurrentFileDropData}
								setCurrentWorkflowOutput={setCurrentWorkflowOutput}
								closeProcessingModal={closeProcessingModal}
							/>
						</div>
					</CardBody>
				</Card>
			</div>
		</div>
	);
}

export default MissionAssetsPanel;
