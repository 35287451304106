export const DB_CONTAINERS = {
	DATALAKE: 'datalake',
	CALANDARS: 'calendars',
	CAMPAIGN: 'campaign',
	CAMPAIGN_TEMPLATE: 'campaign-template',
	COMPANY: 'company',
	CLIENTS: 'clients',
	CHAT_COLLABORATOR: 'chat-collaborator',
	PRODUCT: 'product',
	PROMPT: 'prompt',
	PROMPT_TEST_DATA: 'zz-admin/prompt/test-data',
	USER: 'user',
	LEADS: 'leads',
	COPY: 'copy',
	COMPANY_LIST: 'company-list',
	COMPANY_LEAD: 'company-lead',
	MEMBERSHIP_PLAN: 'membership-plan',
	MIDJOURNEY_JOB: 'midjourney-job',
	MIDJOURNEY_DESCRIBE: 'midjourney-describe',
	MIDJOURNEY_IMAGINE: 'midjourney-imagine',
	MODULE: 'module',
	WORKFLOW: 'workflow',
	WORKFLOW_OUTPUT: 'workflow-output',
	WORKFLOW_TEMPLATE: 'workflow-template',
	NOTIFICATION: 'notification',
	WORKFLOW_EMAIL_COPIES: 'email-copies',
	COLLABORATOR: 'collaborator',
	COLLABORATION_THREAD: 'collaboration-thread',
	CAMPAIGNS: 'campaigns',
};
