import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import useLoggedInUser from '../hooks/useLoggedInUser';
import { ActiveUser } from '../redux/active-item/active-itemSlice';
import { CommunicationPreferences, SalesProfessionalInfo } from '../models/profile';
import useusers from '../hooks/useUsers';
import { DbUser } from '../Azure/models';
import { AuthenticationResult } from '@azure/msal-browser';

interface IUserContext {
	user: AuthenticationResult | null;
	activeUser?: ActiveUser;
	salesProfessionalInfo: SalesProfessionalInfo | undefined;
	saveProfile: (profile: Partial<ActiveUser>) => Promise<void>;
	communicationPreferences: CommunicationPreferences;
	users: DbUser[];
	redirectUrl: string;
	setRedirectUrl: Dispatch<SetStateAction<string>>;
	setActiveUser: Dispatch<SetStateAction<ActiveUser | undefined>>;
	setUser: Dispatch<SetStateAction<AuthenticationResult | null>>;
	saveClientIdToUserData: (newClientId: string) => Promise<void>;
	isAuthenticatedCache: boolean;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider = ({ children }: { children: any }) => {
	const {
		user,
		activeUser,
		setActiveUser,
		setUser,
		redirectUrl,
		setRedirectUrl,
		saveClientIdToUserData,
		isAuthenticatedCache,
		saveProfile,
		salesProfessionalInfo,
		communicationPreferences,
	} = useLoggedInUser();
	const { users } = useusers({ user: user ?? undefined, activeUser });
	return (
		<UserContext.Provider
			value={{
				activeUser,
				salesProfessionalInfo,
				communicationPreferences,
				saveProfile,
				users,
				user,
				setUser,
				setActiveUser,
				redirectUrl,
				saveClientIdToUserData,
				setRedirectUrl,
				isAuthenticatedCache,
			}}>
			{children}
		</UserContext.Provider>
	);
};
