import { createContext } from 'react';
import useNotifications from '../hooks/useNotifications';
import { Notification } from '../models/models';

interface INotificationsContext {
	notifications: Notification[];
	loading: boolean;
	readNotification: (a: string) => void;
}

export const NotificationsContext = createContext<INotificationsContext>(
	{} as INotificationsContext,
);

export const NotificationsProvider = ({ children }: { children: any }) => {
	const { notifications, loading, readNotification } = useNotifications();
	return (
		<NotificationsContext.Provider
			value={{
				notifications,
				loading,
				readNotification,
			}}>
			{children}
		</NotificationsContext.Provider>
	);
};
