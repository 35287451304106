import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { stat } from 'fs';

export interface currentStep {
	step: number;
	title: string;
}

const initialState: currentStep = {
	step: 0,
	title: 'Product descriptions',
};

export const currentStepSlice = createSlice({
	name: 'step',
	initialState,
	reducers: {
		updateStep: (state, action: PayloadAction<currentStep>) => {
			const { step, title } = action.payload;
			state.step = step;
			state.title = title;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateStep } = currentStepSlice.actions;
export default currentStepSlice.reducer;
