import { useContext, useEffect, useState } from 'react';
import { Campaign, CampaignTemplate } from '../models/campaign';
import { UserContext } from '../contexts/UserContext';
import { ClientContext } from '../contexts/ClientContext';

export default function useCampaigns() {
	const [loading, setLoading] = useState(false);
	const { activeUser, user } = useContext(UserContext);
	const { activeClientId } = useContext(ClientContext);
	const [campaignTemplates, setCampaignTemplates] = useState<CampaignTemplate[]>([]);
	const [campaigns, setCampaigns] = useState<Campaign[]>([]);

	const appendCampaignTemplate = (campaign: CampaignTemplate) => {
		setCampaignTemplates((prev) => [...prev.filter((pk) => pk.id !== campaign.id), campaign]);
	};
	const appendCampaigns = (campaign: Campaign) => {
		setCampaigns((prev) => [...prev.filter((pk) => pk.id !== campaign.id), campaign]);
	};

	useEffect(() => {
		if (!user?.uniqueId) {
			return;
		} else {
			// setLoading(true);
			// const q = query(collection(firestore, `${rootCollectionCampaignTemplate}`));
			// setCampaigns([]);
			// const unsubscribe = onSnapshot(q, (snapshot) => {
			//   snapshot.docChanges().forEach((change) => {
			//     if (change.type == "added") {
			//       appendCampaignTemplate({...change.doc.data(), id: change.doc.id} as CampaignTemplate);
			//     } else if (change.type == "modified") {
			//       appendCampaignTemplate({...change.doc.data(), id: change.doc.id} as CampaignTemplate);
			//     } else if (change.type == "removed") {
			//       setCampaignTemplates(prev => prev.filter(p => p.id !== change.doc.data().id))
			//     }
			//   })
			//   setLoading(false);
			// },(err)=>{console.log(err)});
			// return unsubscribe;
		}
	}, [user?.uniqueId]);

	useEffect(() => {
		if (!user?.uniqueId || !activeClientId) {
			return;
		} else {
			// setLoading(true);
			// const q = query(collection(firestore, `${FIRESTORE.CAMPAIGN}`), where("clientId", "==", (activeClientId ?? activeUser?.id)), where("status", "==", "active"), orderBy("lastUpdated", "desc"));
			// setCampaigns([]);
			// const unsubscribe = onSnapshot(q, (snapshot) => {
			//   snapshot.docChanges().forEach((change) => {
			//     if (change.type == "added") {
			//       appendCampaigns({...change.doc.data(), id: change.doc.id} as Campaign);
			//     } else if (change.type == "modified") {
			//       appendCampaigns({...change.doc.data(), id: change.doc.id} as Campaign);
			//     } else if (change.type == "removed") {
			//       setCampaigns(prev => prev.filter(p => p.id !== change.doc.data().id))
			//     }
			//   })
			//   setLoading(false);
			// },(err)=>{console.log(err)});
			// return unsubscribe;
		}
	}, [user?.uniqueId, activeUser]);

	const cloneCampaign = async (file: Campaign) => {
		// await setLoading(true);
		// const newId = cloneNumberStampedId(file.id);
		// const newFile: Campaign = {...file,
		//   id: newId,
		//   name: cloneName(file.name),
		//   leadCount: 0,
		//   created: new Date().toISOString(),
		//   lastUpdated: new Date().toISOString(),
		//   selectedLeadEmails: [],
		//   marketSegments: []
		// };

		// const fileRef = await doc(firestore, rootCollectionCampaign, newId);
		// await setDoc(fileRef, newFile, { merge: true });
		// await setLoading(false);
		return {} as Campaign;
	};

	const deleteCampaign = async (campaign: Campaign) => {
		await setLoading(true);
		// const fileRef = await doc(firestore, rootCollectionCampaign, campaign?.id);
		// await setDoc(fileRef, {...campaign, status: "deleted"}, { merge: true });
		await setLoading(false);
	};

	return { deleteCampaign, campaigns, cloneCampaign, campaignTemplates };
}
