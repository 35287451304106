import { Dispatch, SetStateAction, createContext, useState } from 'react';
import useModule from '../hooks/useModule';
import { Workflow, WorkflowCampaign, WorkflowSearchParams, WorkflowTemplate } from '../models/models';
import { LicensedModule, Module } from '../models/module';
import useWorkflows from '../hooks/useWorkflows';

interface IModuleContext {
	modules: Module[];
	licensedModules: LicensedModule[];
	workflowTemplates: WorkflowTemplate[];
	workflows: Workflow[];
	deleteWorkflow: (a: string) => void;
	markWorkflowComplete: (wfId: string) => Promise<void>;
	createWorkflow: (file: Workflow) => Promise<void>;
	selectedModule: string;
	setSelectedModule: Dispatch<SetStateAction<string>>;
	campaigns: WorkflowCampaign[];
	loading: boolean;
	workflowSeachParams: WorkflowSearchParams;
	setWorkflowSeachParams: Dispatch<SetStateAction<WorkflowSearchParams>>;
	totalWorkflows: number;
	createWorkflowSharekeyFunc: (id: string) => Promise<string>;
}

export const ModuleContext = createContext<IModuleContext>({} as IModuleContext);

export const ModuleProvider = ({ children }: { children: any }) => {
	const {
		modules,
		licensedModules,
		workflowTemplates,
		createWorkflow,
		deleteWorkflow,
		selectedModule,
		setSelectedModule,
		markWorkflowComplete,
		createWorkflowSharekeyFunc,
	} = useModule();
	const { workflows, campaigns, loading, workflowSeachParams, setWorkflowSeachParams, totalWorkflows} = useWorkflows();

	// const searchedModules = modules?.filter((i) =>
	// 	`
	// ${i?.name} 
	// ${i?.text}
	// `
	// 		?.toLowerCase()
	// 		?.includes(workflowSeachParams?.searchText?.toLowerCase()),
	// );

	const searchedWorkflowTemplates = workflowTemplates?.filter((i) =>
		`
	${i?.name} ${i?.text} ${i.type?.name}
	${i?.created}  
	${i.type?.text} ${i.type?.icon}  
	${i?.subType?.name} ${i?.subType?.text}  
	${i?.steps?.map((j) => j?.name)}
	${i?.steps?.map((j) => j?.text)} 
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.name))} 
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.datalakeType))}  
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.inputType))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.label))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.objectFields))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.className))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.options))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.required))}
	${i?.lastUpdated}
	`
			?.toLowerCase()
			?.includes(workflowSeachParams?.searchText?.toLowerCase()),
	);

	const searchedWorkflows = workflows?.filter((i) => {
		const searchString = `${i?.name ?? ''} ${i?.data ?? ''} ${i?.text ?? ''} ${
			i?.clientId ?? ''
		}`;
		const lowerCaseSearchString = searchString.toLowerCase();
		const lowerCaseSearchText = workflowSeachParams?.searchText?.toLowerCase();

		return (
			lowerCaseSearchString.includes(lowerCaseSearchText) && i?.moduleId === selectedModule
		);
	});
	const selectedWorkflowTemplates = workflowTemplates?.filter(
		(i) => i?.moduleId == selectedModule,
	);
	return (
		<ModuleContext.Provider
			value={{
				modules,
				licensedModules,
				workflowTemplates: selectedWorkflowTemplates,
				workflows: searchedWorkflows,
				createWorkflow,
				deleteWorkflow,
				selectedModule,
				setSelectedModule,
				campaigns,
				markWorkflowComplete,
				loading,
				workflowSeachParams,
				setWorkflowSeachParams,
				totalWorkflows,
				createWorkflowSharekeyFunc,
			}}>
			{children}
		</ModuleContext.Provider>
	);
};
