import { gql } from '@apollo/client';

export const GET_SAS_URL = gql`
	mutation Mutation(
		$containerName: String
		$fileName: String
		$activeClientId: String
		$accessToken: String
	) {
		getgenerateSASURL(
			containerName: $containerName
			fileName: $fileName
			activeClientId: $activeClientId
			accessToken: $accessToken
		)
	}
`;

export const ENCODING_TO_BITMOVIN_VIDEO = gql`
	mutation Mutation($videoUrl: String, $activeClientId: String, $accessToken: String) {
		encodeToBitmovinVideo(
			videoUrl: $videoUrl
			activeClientId: $activeClientId
			accessToken: $accessToken
		)
	}
`;

export const ENCODING_VIDEO = gql`
	mutation Mutation($videoUrl: String, $activeClientId: String, $accessToken: String) {
		videoConverter(
			videoUrl: $videoUrl
			activeClientId: $activeClientId
			accessToken: $accessToken
		)
	}
`;
