import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Module, LicensedModule } from '../models/module';
import { Workflow, WorkflowTemplate } from '../models/models';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_WORKFLOW_SHAREKEY, UPDATE_WORKFLOW_MUTATION } from '../GraphQL/workflow/mutation';
import { GET_WORKFLOW_TEMPLATES } from '../GraphQL/common-queries';

export default function useModule() {
	const [modules, setModules] = useState<Module[]>([
		{ id: 'mission-builder', name: 'Mission Builder', text: '' } as Module,
	]);
	const [licensedModules, setLicensedModules] = useState<LicensedModule[]>([
		{ id: 'mission-builder' },
	]);
	const [workflowTemplates, setWorkflowTemplates] = useState<WorkflowTemplate[]>([]);
	const { user } = useContext(UserContext);
	const [selectedModule, setSelectedModule] = useState<string>(licensedModules?.[0]?.id);
	const [updateWorkflow] = useMutation(UPDATE_WORKFLOW_MUTATION);
	const [createWorkflowSharekeyMutation] = useMutation(CREATE_WORKFLOW_SHAREKEY);
	const { data: workflowTemplatesData } = useQuery(GET_WORKFLOW_TEMPLATES, {
		variables: { moduleId: selectedModule, accessToken: user?.accessToken },
		skip: !user?.accessToken || !user?.uniqueId || !selectedModule,
	});

	useEffect(() => {
		if (
			!!workflowTemplatesData?.getWorkflowTemplates &&
			workflowTemplatesData?.getWorkflowTemplates?.length > 0
		) {
			setWorkflowTemplates(workflowTemplatesData?.getWorkflowTemplates as WorkflowTemplate[]);
		}
	}, [workflowTemplatesData]);

	useEffect(() => {
		const localSelectedModule = localStorage.getItem('selectedModule');
		if (licensedModules?.length) {
			if (
				!!localSelectedModule &&
				licensedModules.find((module) => module?.id === localSelectedModule)
			) {
				setSelectedModule(localSelectedModule);
			} else {
				setSelectedModule(licensedModules?.[0]?.id);
			}
		}
	}, [licensedModules]);

	useEffect(() => {
		const localSelectedModule = localStorage.getItem('selectedModule');
		if (localSelectedModule != selectedModule && !!selectedModule) {
			console.log('changed');

			localStorage.setItem('selectedModule', selectedModule);
		}
	}, [selectedModule]);

	const deleteWorkflow = async (workflowId: string) => {
		await updateWorkflow({
			variables: {
				userInput: { id: workflowId, status: 'deleted' },
				accessToken: user?.accessToken,
			},
		});
	};

	const markWorkflowComplete = async (workflowId: string) => {
		await updateWorkflow({
			variables: {
				userInput: { id: workflowId, status: 'completed' },
				accessToken: user?.accessToken,
			},
		});
	};

	const createWorkflow = async (file: Workflow) => {
		const workflowData = { ...file, status: 'active' };
		await updateWorkflow({
			variables: {
				userInput: { ...workflowData },
				accessToken: user?.accessToken,
			},
		});
	};

	const createWorkflowSharekeyFunc = async (id: string) => {
		try {
			const res = await createWorkflowSharekeyMutation({
				variables: {
					workflowId: id,
					accessToken: user?.accessToken,
				},
			});
			return (res?.data?.createWorkflowSharekey?.url ?? '') as string;
		} catch (e) {
			console.log(e);
			return '';
		}
	};

	return {
		modules,
		licensedModules,
		workflowTemplates,
		createWorkflow,
		deleteWorkflow,
		selectedModule,
		setSelectedModule,
		markWorkflowComplete,
		createWorkflowSharekeyFunc,
	};
}
