import { gql } from '@apollo/client';

export const GET_COMPANIES_BY_CLIENTID_MUTATION = gql`
	mutation Mutation($clientId: String, $accessToken: String) {
		getCompaniesByClientId(clientId: $clientId, accessToken: $accessToken)
	}
`;

export const GET_COMPANIES_BY_CLIENTIDS = gql`
	query Query($clientIds: [String], $accessToken: String) {
		getCompaniesByClientIds(clientIds: $clientIds, accessToken: $accessToken)
	}
`;

export const UPDATE_COMPANY_MUTATION = gql`
	mutation Mutation($userInput: JSON, $accessToken: String) {
		updateCompany(userInput: $userInput, accessToken: $accessToken)
	}
`;

export const DELETE_COMPANY_MUTATION = gql`
	mutation Mutation($userInput: JSON, $accessToken: String) {
		deleteCompany(userInput: $userInput, accessToken: $accessToken)
	}
`;
