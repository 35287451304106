import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { stat } from 'fs';

export interface campaignStep {
	step: number;
	title: string;
	enableGoals?: boolean;
}

const initialState: campaignStep = {
	step: 0,
	title: 'Basic Info',
	enableGoals: false,
};

export const campaignStepSlice = createSlice({
	name: 'campaignStep',
	initialState,
	reducers: {
		updateStep: (state, action: PayloadAction<campaignStep>) => {
			const { step, title } = action.payload;
			state.step = step;
			state.title = title;
		},
		updateGoals: (state, action: PayloadAction<boolean>) => {
			state.enableGoals = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateStep, updateGoals } = campaignStepSlice.actions;
export default campaignStepSlice.reducer;
