import { useRef, useState } from "react";
import useImageUpload from "../../../helpers/useImageUpload";
import useTranscribe from "../../../hooks/useTranscribe";
import Swal from "sweetalert2";
import { FieldTemplate } from "../../../models/models";
import Spinner from "../../../components/bootstrap/Spinner";

export const DocumentPickerFormComponent = ({
    value,
    setValue,
    setFileInputType,
    setUsingFileInput,
    field,
    dataChanged,
}: {
    value: string;
    setValue: (value: any, shouldValidate?: boolean) => void;
    setFileInputType: (a: string) => void;
    setUsingFileInput: (a: boolean) => void;
    field?: FieldTemplate;
    dataChanged: (field: FieldTemplate, value: string) => void;
}) => {
    const { getDocumentToText } = useTranscribe();
    const { uploadFileToBlobStorage } = useImageUpload();
    const inputPdfRef = useRef<any>(null);
    const [uploadingPdf, setUploadingPdf] = useState(false);
    const [summerizeDocument, setSummerizeDocument] = useState(false);

    const handleFileChange = async (event: any) => {
        setUploadingPdf(true);
        try{
            const fileObj = event?.target?.files && event?.target?.files[0];
            if (!fileObj) {
                return;
            }
            event.target.value = null;
            if (fileObj) {
                console.log('file.type', fileObj.type);
                if (fileObj?.type === 'application/pdf') {
                    try {
                        const downloadUrl = await uploadFileToBlobStorage({ file: fileObj });
                        const result = await getDocumentToText(downloadUrl.url, summerizeDocument);
                        if (result.length > 0) {
                            setValue(`${value} \n ${result === downloadUrl.url ? '' : result}`);
                            if(!!field){
                                dataChanged(
                                    field,
                                    `${value} \n ${result === downloadUrl.url ? '' : result}`,
                                );
                            }
                            setFileInputType('');
                            setUsingFileInput(false);
                        } else {
                            Swal.fire({
                                icon: 'warning',
                                title: 'Invalid File',
                                text: 'Please upload Valid Document that contains data.',
                                showCancelButton: false,
                                showConfirmButton: true,
                                allowOutsideClick: true,
                            });
                        }
                    } catch (err) {
                        throw err;
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Invalid File',
                        text: 'Please upload Valid Document.',
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonColor: 'rgba(0, 56, 255, 1)',
                        allowOutsideClick: true,
                    });
                }
            }
        }catch(e){
            console.log({e});
        }
        setUploadingPdf(false);
    };

    return(
        <div className='col-11'>
            <div className='mt-3 mb-3' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h5 style={{ marginRight: '10px' }}>Summerize File</h5>
                <input
                    type='radio'
                    checked={summerizeDocument}
                    style={{ lineHeight: '2rem' }}
                    onClick={(e) => setSummerizeDocument(prev => !prev)}
                />
            </div>
            <input
                style={{}}
                ref={inputPdfRef}
                disabled={uploadingPdf}
                type='file'
                accept='application/pdf'
                onChange={handleFileChange}
            />
            <div className='my-4 modal-footer'>
                <button
                    type='button'
                    className='btn btn-Secondary'
                    style={{
                        backgroundColor: ' #334151',
                        color: 'white',
                    }}
                    disabled={uploadingPdf}
                    onClick={() => {
                        setUsingFileInput(false);
                        setFileInputType('');
                    }}>
                        {uploadingPdf ? <span>
                            Saving{' '}
                            <Spinner
                                className='spin-btn'
                                isSmall
                                inButton
                                color={'dark'}
                            />
                        </span> : <span>Cancel</span>}
                </button>
            </div>
        </div>
    );
}