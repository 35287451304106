import { gql } from '@apollo/client';

export const UPDATE_WORKFLOW_MUTATION = gql`
	mutation Mutation($userInput: JSON, $accessToken: String) {
		updateWorkflow(userInput: $userInput, accessToken: $accessToken)
	}
`;

export const UPDATE_WORKFLOW_PPT_MUTATION = gql`
	mutation Mutation($userInput: JSON, $accessToken: String) {
		updateWorkflowPPT(userInput: $userInput, accessToken: $accessToken)
	}
`;

export const UPDATE_WORKFLOW_OUTPUT = gql`
	mutation Mutation($userInput: JSON, $accessToken: String) {
		updateWorkflowOutput(userInput: $userInput, accessToken: $accessToken)
	}
`;

export const DELETE_WORKFLOW_OUTPUT = gql`
 mutation Mutation($id: ID!, $accessToken: String) {
    deleteWorkflowOutput(id: $id, accessToken: $accessToken)
}
`;

export const UPDATE_CAMPAIGN_MUTATION = gql`
	mutation Mutation($userInput: JSON, $accessToken: String) {
		updateCampaign(userInput: $userInput, accessToken: $accessToken)
	}
`;


export const CREATE_WORKFLOW_SHAREKEY = gql`
	mutation Mutation($workflowId: String, $accessToken: String) {
		createWorkflowSharekey(workflowId: $workflowId, accessToken: $accessToken)
	}
`;
