import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { Campaign, CampaignComposedEmail, CampaignTemplate } from '../models/campaign';
import useCampaigns from '../hooks/useCampaigns';

interface ICampaignsContext {
	deleteCampaign: (campaign: Campaign) => Promise<void>;
	campaigns: Campaign[];
	cloneCampaign: (file: Campaign) => Promise<Campaign>;
	campaignTemplates: CampaignTemplate[];
}

export const CampaignsContext = createContext<ICampaignsContext>({} as ICampaignsContext);

export const CampaignsProvider = ({ children }: { children: any }) => {
	const { deleteCampaign, campaigns, cloneCampaign, campaignTemplates } = useCampaigns();

	return (
		<CampaignsContext.Provider
			value={{
				deleteCampaign,
				campaigns,
				campaignTemplates,
				cloneCampaign,
			}}>
			{children}
		</CampaignsContext.Provider>
	);
};
