import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
	query Query($id: ID!, $accessToken: String) {
		user(id: $id, accessToken: $accessToken)
	}
`;

export const GET_USERS_BY_CLIENTIDS = gql`
	query Query($clientIds: [String], $accessToken: String) {
		getUsersByClientIds(clientIds: $clientIds, accessToken: $accessToken)
	}
`;
