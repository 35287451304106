import React, { useContext } from 'react';
import { Company, DataFragment, Image } from '../../../models/models';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FaPlus } from 'react-icons/fa';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import { useModal } from '@ebay/nice-modal-react';
import { Modals } from '../../../components/Modals';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { CardActions } from '../../../components/bootstrap/Card';
import DropCapsImageCard from './DropCapsImageCard';
import ImageCard from './ImageCard';
import ImageGridCard from './ImageGridCard';
import TextCard from './TextCard';

function Files({ files, title }: { files: DataFragment[]; title: string }) {
	const {
		setFragmentId: setProductId,
		setSelectedFragmentType,
		deleteDataFragment,
	} = useContext(DatalakeContext);
	const datalakeModal = useModal(Modals.DATALAKE);
	return (
		<div className='px-2 px-md-5 mb-4'>
			<p className='uppercase-title'>{title}</p>
			<div className='row mx-0'>
				<div className='col-6 col-md-4 col-lg-2 mb-4'>
					<div
						className='row-item position-relative bg-dark cursor-pointer'
						onClick={() => {
							setSelectedFragmentType(files?.[0]?.type);
							setProductId('new');
							datalakeModal.show();
						}}>
						<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
							<FaPlus size={75} color='#71788B' className='mt-5' />
						</div>
						<div className='px-3 pb-4'>
							<p className='shop-description text-white fs-4 mb-0 mt-2 px-2'>Add</p>
						</div>
					</div>
				</div>
				{files?.map((file, index) => (
					<div className='col-6 col-md-4 col-lg-2 mb-4' key={index}>
						{(file.images?.length ?? 0) === 1 && file.text ? (
							<DropCapsImageCard key={index} dataFragment={file} />
						) : (file.images?.length ?? 0) === 1 ? (
							<ImageCard key={index} dataFragment={file} />
						) : (file.images?.length ?? 0) > 1 ? (
							<ImageGridCard key={index} dataFragment={file} />
						) : (
							<TextCard key={index} dataFragment={file} />
						)}
					</div>
				))}
			</div>
		</div>
	);
}

export default Files;
