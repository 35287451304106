import { gql } from '@apollo/client';

export const WORKFLOW_CALL_MUTATION = gql`
	mutation Mutation($userInput: JSON) {
		workflowCall(userInput: $userInput)
	}
`;

export const USER_CALL_MUTATION = gql`
	mutation Mutation($userInput: JSON) {
		userCall(userInput: $userInput)
	}
`;

export const CLIENT_CALL_MUTATION = gql`
	mutation Mutation($userInput: JSON) {
		clientCall(userInput: $userInput)
	}
`;

export const TRANSCRIBE_CALL_MUTATION = gql`
	mutation Mutation($userInput: JSON) {
		transcribeCall(userInput: $userInput)
	}
`;

export const CHAT_GPT_CALL_MUTATION = gql`
	mutation Mutation($userInput: JSON) {
		chatGPTCall(userInput: $userInput)
	}
`;

export const WEB_SCRAPE_CALL_MUTATION = gql`
	mutation Mutation($userInput: JSON) {
		webScrapeCall(userInput: $userInput)
	}
`;

export const EXPRESS_DOCKER_MUTATION = gql`
	mutation Mutation($accessToken: String, $url: String, $userInput: JSON) {
		expressDockerPostApi(accessToken: $accessToken, url: $url, userInput: $userInput)
	}
`;

export const VIDEO_CONVERTER = gql`
	mutation VideoConverter($accessToken: String, $userInput: JSON) {
		videoConverter(accessToken: $accessToken, userInput: $userInput)
	}
`;

export const CREATE_PPT = gql`
	mutation CreatePPT($accessToken: String, $userInput: JSON) {
		createPPT(accessToken: $accessToken, userInput: $userInput)
	}
`;

export const GET_REQUEST = gql`
	query Query($requestId: String) {
		getRequestData(requestId: $requestId)
	}
`;
