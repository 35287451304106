import { useContext, useEffect, useState } from 'react';
import { Company, CompanyBasicInfo, Competitor, SuccessStories } from '../models/company';
import { UserContext } from '../contexts/UserContext';
import { Gallery } from '../models/product';
import { useList } from 'react-use';
import { ActiveUser } from '../redux/active-item/active-itemSlice';
import { useMutation, useQuery } from '@apollo/client';
import {
	DELETE_COMPANY_MUTATION,
	GET_COMPANIES_BY_CLIENTIDS,
	GET_COMPANIES_BY_CLIENTID_MUTATION,
	UPDATE_COMPANY_MUTATION,
} from '../GraphQL/company';

export default function useCompanies(clientId?: string) {
	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState<Company[]>([]);
	const { user: authUser, activeUser } = useContext(UserContext);
	const [
		successStories,
		{ set: setSuccessStories, upsert: upsertSuccessStory, removeAt: removeSuccessStoryAt },
	] = useList<SuccessStories>([]);
	const [
		competitors,
		{ set: setCompetitors, upsert: upsertCompetitors, removeAt: removeCompetitorAt },
	] = useList<Competitor>([]);
	const { data: companiesData } = useQuery(GET_COMPANIES_BY_CLIENTIDS, {
		variables: {
			clientIds: activeUser?.clientIds?.map((e) => e) ?? [],
			accessToken: authUser?.accessToken,
		},
		skip: !authUser?.accessToken || !authUser?.uniqueId || !activeUser?.clientIds?.length,
	});
	const [getCompaniesByClientId] = useMutation(GET_COMPANIES_BY_CLIENTID_MUTATION);
	const [updateCompanyMutation] = useMutation(UPDATE_COMPANY_MUTATION);
	const [deleteCompanyMutation] = useMutation(DELETE_COMPANY_MUTATION);

	const initialBasicInfo: CompanyBasicInfo = {
		industry: '',
		crm: '',
		name: '',
		numberOfEmployees: 0,
		revenue: 0,
		mission: '',
		location: '',
		value: '',
		usp: '',
	};

	const [basicInfo, setBasicInfo] = useState<CompanyBasicInfo>(initialBasicInfo);

	const [gallery, setGallery] = useState<Gallery[]>([]);
	const [primaryLogo, setPrimaryLogo] = useState<string>('');

	useEffect(() => {
		if (
			!!companiesData?.getCompaniesByClientIds &&
			companiesData?.getCompaniesByClientIds?.length > 0
		) {
			setCompanies(companiesData?.getCompaniesByClientIds as Company[]);
		}
	}, [companiesData]);

	const appendCompany = (company: Company) => {
		setCompanies((prev) => [...prev.filter((pk) => pk.id !== company.id), company]);
	};

	const initialVM = (company: Company) => {
		const { industry, crm, name, numberOfEmployees, revenue, mission, location, value, usp } =
			company;
		setBasicInfo({
			industry,
			crm,
			name,
			numberOfEmployees,
			revenue,
			mission,
			location,
			value,
			usp,
		});
	};

	const saveCompany = async (file: Partial<Company>) => {
		if (!activeUser) throw new Error('User not found');
		await setLoading(true);
		await updateCompanyMutation({
			variables: {
				userInput: { ...file },
				accessToken: authUser?.accessToken,
			},
		});
		await setLoading(false);
	};

	const deleteCompany = async (id: string) => {
		await setLoading(true);
		await deleteCompanyMutation({
			variables: {
				userInput: { id },
				accessToken: authUser?.accessToken,
			},
		});
		await setLoading(false);
	};
	const removeSuccessStory = (value: SuccessStories) =>
		removeSuccessStoryAt(
			successStories.findIndex(
				(v) => v.name == value.name && v.description == value.description,
			),
		);
	const removeCompetitors = (value: Competitor) =>
		removeCompetitorAt(
			competitors.findIndex(
				(v) =>
					v.name == value.name &&
					v.advantage == value.advantage &&
					v.disadvantage == value.disadvantage,
			),
		);

	const fetchCompanyForUser = async (user: ActiveUser) => {
		console.log('Fetching company for ', user);
		if (!user?.clientIds?.[0]) return undefined;
		setCompanies([]);
		const response = await getCompaniesByClientId({
			variables: {
				clientId: user?.clientIds?.[0],
				accessToken: authUser?.accessToken,
			},
		});
		const companies = response?.data?.getCompaniesByClientId as Company[];
		if (companies?.[0]) {
			initialVM(companies?.[0]);
			return companies?.[0];
		} else {
			return undefined;
		}
	};
	return {
		loading,
		saveCompany,
		deleteCompany,
		companies,
		successStories,
		setSuccessStories,
		upsertSuccessStory,
		removeSuccessStory,
		primaryLogo,
		gallery,
		setPrimaryLogo,
		setGallery,
		fetchCompanyForUser,
		competitors,
		setCompetitors,
		upsertCompetitors,
		removeCompetitors,
		basicInfo,
		setBasicInfo,
	};
}
