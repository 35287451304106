import { gql } from "@apollo/client";

export const GET_PEOPLE_SEARCH = gql`
  query Query($offset: Int, $limit: Int, $searchText: String, $accessToken: String) {
  getPeople(offset: $offset, limit: $limit, searchText: $searchText, accessToken: $accessToken)
}
`;

export const GET_CURRENT_PEOPLE = gql`
  query Query($id: ID!, $accessToken: String) {
		person(id: $id, accessToken: $accessToken)
	}
`;