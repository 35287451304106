import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Notification } from '../models/models';
import { ClientContext } from '../contexts/ClientContext';
import { useMutation, useQuery } from '@apollo/client';
import { GET_NOTIFICATIONS } from '../GraphQL/common-queries';
import { WORKFLOW_CALL_MUTATION } from '../GraphQL/callable-mutations';

enum WorkflowRequestType {
	readNotification = 'readNotification',
}

export type PROMPT_TYPE = 'output' | 'moduleContent' | 'outputSuggestion';

export default function useNotifications() {
	const [loading, setLoading] = useState(false);
	const { user } = useContext(UserContext);
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const { activeClientId } = useContext(ClientContext);
	const { data: notificationsData } = useQuery(GET_NOTIFICATIONS, {
		variables: { clientId: activeClientId, accessToken: user?.accessToken },
		skip: !user?.accessToken || !user?.uniqueId || !activeClientId,
	});
	const [workflowCall, { error: workflowCallError }] = useMutation(WORKFLOW_CALL_MUTATION);

	useEffect(() => {
		if (
			!!notificationsData?.getNotifications &&
			notificationsData?.getNotifications?.length > 0
		) {
			setNotifications(notificationsData?.getNotifications as Notification[]);
		}
	}, [notificationsData]);

	const readNotification = async (id: string) => {
		setLoading(true);
		try {
			const res = await workflowCall({
				variables: {
					userInput: {
						requestType: WorkflowRequestType.readNotification,
						clientId: activeClientId,
						id,
						accessToken: user?.accessToken,
					},
				},
			});
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	return {
		loading,
		readNotification,
		notifications: notifications.sort((a, b) => (a.lastUpdated > b.lastUpdated ? -1 : 1)),
	};
}
