import { PublicClientApplication } from '@azure/msal-browser';
import { AzureConfig } from './azure-constants';

export const getMsalInstance = async (redirectUri?: string) => {
	const msalConfig = {
		auth: {
			clientId: AzureConfig.authClientID,
			authority: `https://login.microsoftonline.com/${AzureConfig.directoryTenentId}`,
			redirectUri: redirectUri ?? '/',
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: true,
		},
	};
	const msalInstance = new PublicClientApplication(msalConfig);
	await msalInstance.initialize();
	return msalInstance;
};

export const getAzureAccount = async () => {
	try {
		const msalInstance = await getMsalInstance();
		const accounts = msalInstance.getAllAccounts();
		if (accounts?.length > 0) {
			await msalInstance.setActiveAccount(accounts?.[0]);
			const tokenRequest = {
				scopes: [AzureConfig.userReadScope, AzureConfig.userOfflineScope],
			};
			const response = await msalInstance.acquireTokenSilent(tokenRequest);
			return response;
		} else {
			return null;
		}
	} catch (e) {
		console.log({ e });
		return null;
	}
};

export const msalLogout = async () => {
	try {
		const msalInstance = await getMsalInstance();
		const logoutRequest = {
			account: msalInstance.getActiveAccount(), // Get the active account
			mainWindowRedirectUri: `/login`, // Your redirect URI
		};
		await msalInstance.logoutPopup(logoutRequest);
		console.log('User signed out successfully');
	} catch (error) {
		console.error('Error signing out:', error);
	}
};
