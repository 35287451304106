import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { ClientContext } from '../contexts/ClientContext';
import { Calendar, EventConfiguration } from '../models/models';
import { sequenceId } from '../util/util';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CALENDARS, UPDATE_CALENDAR_MUTATION } from '../GraphQL/calendar';

export default function useCalendars() {
	const [loading, setLoading] = useState(false);
	const { activeUser, user } = useContext(UserContext);
	const { activeClientId } = useContext(ClientContext);
	const [calendarTemplates, setCalendarTemplates] = useState<Calendar[]>([]);
	const { data: calendarsData } = useQuery(GET_CALENDARS, {
		variables: { accessToken: user?.accessToken },
		skip: !user?.accessToken || !user?.uniqueId || !activeClientId,
	});
	const [updateCalendarMutation] = useMutation(UPDATE_CALENDAR_MUTATION);

	useEffect(() => {
		if (!!calendarsData?.getCalendars && calendarsData?.getCalendars?.length > 0) {
			setCalendarTemplates(calendarsData?.getCalendars);
		}
	}, [calendarsData]);

	const removeEvent = async (event: EventConfiguration, id: string) => {
		try {
			const calendarDoc = calendarTemplates?.find((i) => i?.id === id);
			if (!!calendarDoc && event) {
				const existingEvents = (calendarDoc?.events as EventConfiguration[]) ?? [];
				const filteredEvents = existingEvents?.filter(
					(ec) =>
						!(
							ec?.name === event?.name &&
							ec?.description === event?.description &&
							ec?.eventDate === event?.eventDate &&
							ec?.generationDate === event?.generationDate
						),
				);
				await updateCalendarMutation({
					variables: {
						userInput: {
							id,
							events: [...filteredEvents],
							lastUpdated: new Date().toISOString(),
						},
						accessToken: user?.accessToken,
					},
				});
				console.log('Event removed!');
			}
		} catch (err) {}
	};

	const saveCalendar = async (events: EventConfiguration[], id: string): Promise<string> => {
		const calendarId = id === 'new' ? sequenceId() : id;
		const calendar: Calendar = {
			id: calendarId,
			clientId: activeClientId ?? activeUser?.id,
			events: [...events],
			name: 'Custom calendar',
			status: 'active',
			lastUpdated: new Date().toISOString(),
		};
		try {
			await updateCalendarMutation({
				variables: {
					userInput: { ...calendar },
					accessToken: user?.accessToken,
				},
			});
		} catch (error) {
			console.error('Error modifying calendar in the collection: ', error);
		}
		return calendarId;
	};

	const deleteCalendar = async (calendar: Calendar) => {
		await setLoading(true);
		await updateCalendarMutation({
			variables: {
				userInput: { ...calendar, status: 'deleted' },
				accessToken: user?.accessToken,
			},
		});
		await setLoading(false);
	};

	return { saveCalendar, removeEvent, deleteCalendar, calendarTemplates };
}
