import { gql } from '@apollo/client';

export const GET_WORKFLOW_COLLABORATORS = gql`
	query Query($workflowId: String, $clientIds: [String], $accessToken: String) {
		getWorkflowCollaborators(
			workflowId: $workflowId
			clientIds: $clientIds
			accessToken: $accessToken
		)
	}
`;

export const GET_WORKFLOW_COLLABORATION_THREADS = gql`
	query Query($workflowId: String, $clientIds: [String], $accessToken: String) {
		getWorkflowCollaborationThreads(
			workflowId: $workflowId
			clientIds: $clientIds
			accessToken: $accessToken
		)
	}
`;

export const GET_CHAT_COLLABORATORS = gql`
	query Query($clientId: String, $accessToken: String) {
		getChatCollaborators(clientId: $clientId, accessToken: $accessToken)
	}
`;

export const GET_CHAT_CONVERSATION = gql`
query Query($chatId: String, $accessToken: String) {
  getChatConversation(chatId: $chatId, accessToken: $accessToken)
}
`;

export const GET_CHAT_COMPLETION = gql`
query Query($clientId: String, $chatId: String, $accessToken: String) {
  getChatCompletion(clientId: $clientId, chatId: $chatId, accessToken: $accessToken)
}
`;
