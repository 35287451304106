import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shorten } from '../../../util/util';
import { PeopleUser } from '../../../models/models';
import { forwardRef } from 'react';

const UserCard = forwardRef<HTMLDivElement, { user: PeopleUser }>(({ user }, ref) => {
	return (
		<div ref={ref}>
		<Link to={`/user-profile/${user.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
			<CardWrapper className='border rounded d-flex flex-column align-items-center  shadow-drop-2-bottom'>
				<div className='profile-background'>
					<div className='avator'>
						{user?.profilePictureUrl ? (
							<img
								src={user?.profilePictureUrl}
								alt='user-profile'
								className='img-fluid circle w-100 h-100'
								style={{ objectFit: 'cover' }}
								loading='lazy'
							/>
						) : (
							<p className='text-primary fs-1 fw-bold mb-0'>
								{user?.firstName?.charAt(0)}
								{user?.lastName?.charAt(0)}
							</p>
						)}
					</div>
				</div>
				<div className='p-2' style={{ marginTop: 60 }}>
					<p className='fs-4 fw-bold text-center mb-1'>
						{user?.firstName}
						&nbsp;
						{user?.lastName}
					</p>
					<p className='text-center text-muted'>{shorten(user?.headline, 30)}</p>
					<p className='text-center' style={{ height: 80 }}>
						{shorten(user?.summary, 140)}
					</p>
					<div className='d-flex align-items-center justify-content-center'>
						<button className='btn-blue-bordered add-btn'>Add</button>
					</div>
				</div>
			</CardWrapper>
		</Link>
		</div>
	);
});

export default UserCard;

const CardWrapper = styled.div`
	height: 400px;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	.profile-background {
		height: 100px;
		width: 100%;
		position: relative;

		.avator {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			position: absolute;
			bottom: -50px;
			left: 50%;
			transform: translate(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	a {
		color: inherit !important;
	}
	.hover-text-primary:hover {
		color: rgba(0, 56, 255, 1);
	}

	.add-btn {
		width: 80% !important;
	}
`;
