import { gql } from '@apollo/client';

export const UPDATE_USER_MUTATION = gql`
	mutation Mutation($userInput: JSON, $accessToken: String) {
		updateUser(userInput: $userInput, accessToken: $accessToken)
	}
`;

export const GET_ALL_USERS_FROM_AD = gql`
	mutation Mutation($accessToken: String) {
		getAllUsersFromAzureAD(accessToken: $accessToken)
	}
`;
