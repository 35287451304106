import React, { useContext } from 'react';
import { Company, Image } from '../../../models/models';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FaPlus } from 'react-icons/fa';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import { useModal } from '@ebay/nice-modal-react';
import { Modals } from '../../../components/Modals';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { CardActions } from '../../../components/bootstrap/Card';

function Companies({ companies }: { companies: Company[] }) {
	const {
		setFragmentId: setProductId,
		setSelectedFragmentType,
		deleteDataFragment,
	} = useContext(DatalakeContext);
	const datalakeModal = useModal(Modals.DATALAKE);
	return (
		<div className='px-2 px-md-5 mb-4'>
			<p className='uppercase-title'>Companies</p>
			<ScrollContainer horizontal={true} className={`scroll-container data-row`}>
				<div
					className='row-item position-relative bg-dark cursor-pointer'
					onClick={() => {
						setSelectedFragmentType('company');
						setProductId('new');
						datalakeModal.show();
					}}>
					<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
						<FaPlus size={75} color='#71788B' className='mt-5' />
					</div>
					<div className='px-3 pb-4'>
						<p className='shop-description text-white fs-4 mb-0 mt-2 px-2'>Add</p>
					</div>
				</div>
				{companies?.map((company) => (
					<div className='row-item position-relative grey-card'>
						<div className='card-top-section text-center d-flex align-items-center justify-content-center'>
							{company?.images?.[0]?.url ? (
								<img
									src={company?.images?.[0]?.url}
									alt='company-image'
									className='img-fluid'
									loading='lazy'
									style={{ height: '90%', width: '90%' }}
									onClick={() => {
										setProductId(company?.id);
										datalakeModal.show();
									}}
								/>
							) : (
								<div className='avatar-placeholder'>
									<img
										src='logo512.png'
										alt='avatar'
										loading='lazy'
										className='img-fluid'
										style={{ height: '90%', width: '90%' }}
										onClick={() => {
											setProductId(company?.id);
											datalakeModal.show();
										}}
									/>
								</div>
							)}
						</div>
						<div className='card-bottom-section px-3'>
							<div className='d-flex justify-content-between align-items-center'>
								<p className='uppercase'>{company?.name}</p>

								<CardActions>
									<Dropdown>
										<DropdownToggle hasIcon={false}>
											<Button
												icon='MoreHoriz'
												// color={themeStatus}
												shadow='default'
												aria-label='Edit'
												className='p-0'
											/>
										</DropdownToggle>
										<DropdownMenu
											isAlignmentEnd
											style={{ bottom: '20px', right: 0 }}>
											<DropdownItem>
												<Button
													icon='Visibility'
													onClick={() => {
														setProductId(company?.id);
														datalakeModal.show();
													}}>
													View
												</Button>
											</DropdownItem>
											<DropdownItem isDivider />
											<DropdownItem>
												<Button
													icon='Delete'
													onClick={() => {
														deleteDataFragment(company);
													}}>
													Delete
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</CardActions>
							</div>
						</div>
					</div>
				))}
			</ScrollContainer>
		</div>
	);
}

export default Companies;
