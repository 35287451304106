export const dashboardPagesMenu = {
	workflows: {
		id: 'workflows',
		text: 'Workflows',
		path: '/workflows',
		icon: 'Campaign',
		subMenu: null,
	},
	datalake: {
		id: 'datalake',
		text: 'Knowledge Base',
		path: '/kb',
		icon: 'Analytics',
		subMenu: null,
	},
	skillsBase: {
		id: 'skillsbase',
		text: 'Skills Base',
		path: '/sb',
		icon: 'Analytics',
		subMenu: null,
	},
	userProfile: {
		id: 'userProfile',
		text: 'User Profile',
		path: '/user-profile/:id',
		icon: 'user',
		subMenu: null,
	},
	workspace: {
		id: 'workspace',
		text: 'Workspace',
		path: '/workspace',
		icon: 'Apartment',
		subMenu: null,
	},
	company: {
		id: 'company',
		text: 'Company',
		path: '/company',
		icon: 'Apartment',
		subMenu: null,
	},
	imagegen: {
		id: 'genimageJobs',
		text: 'Media Library',
		path: '/media',
		icon: 'RecentActors',
		subMenu: null,
	},
	missions: {
		id: 'missions',
		text: 'Missions',
		path: '/missions',
		icon: 'OutputCircle',
		subMenu: null,
	},
	missionAnalytics: {
		id: 'mission-analytics',
		text: 'Mission Analytics',
		path: '/mission-analytics',
		icon: '',
		subMenu: null,
	},
};

export const childPages = {};

export const authMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: '/signup',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '/404',
		icon: 'ReportGmailerrorred',
	},
};
