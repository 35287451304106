import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
	query Query($clientId: String, $accessToken: String) {
		getNotifications(clientId: $clientId, accessToken: $accessToken)
	}
`;

export const GET_WORKFLOW_TEMPLATES = gql`
	query Query($moduleId: String, $accessToken: String) {
		getWorkflowTemplates(moduleId: $moduleId, accessToken: $accessToken)
	}
`;
