import { useEffect, useState } from 'react';
import { DataFragment, Workflow, WorkflowStepTemplate, WorkflowTemplate } from '../models/models';
import { useQuery } from '@apollo/client';
import { GET_DISSEMINATE_WORKFLOW } from '../GraphQL/workflow/queries';
import { GET_REQUEST } from '../GraphQL/callable-mutations';

export default function useDisseminate({
	inputWorkflowId,
	shareKey,
}: {
	inputWorkflowId?: string;
	shareKey?: string;
}) {
	const [workflowId, setWorkflowId] = useState(inputWorkflowId ?? '');
	const [requestShareKey, setRequestShareKey] = useState('');
	const [requestId, setRequestId] = useState('');
	const [workflow, setWorkflow] = useState<Workflow>();
	const [workflowTemplate, setWorkflowTemplate] = useState<WorkflowTemplate>();
	const [workflowStep, setWorkflowStep] = useState<WorkflowStepTemplate>();
	const [eventWorkflowId, setEventWorkflowId] = useState<string[]>([]);
	const [allDataFragments, setAllDataFragments] = useState<DataFragment[]>([]);

	useEffect(() => {
		if (!!inputWorkflowId) {
			setWorkflowId(inputWorkflowId);
		}
	}, [inputWorkflowId]);

	const {
		data: workflowData,
		loading,
		refetch: refetchWorkflow,
		error,
	} = useQuery(GET_DISSEMINATE_WORKFLOW, {
		variables: { id: workflowId, shareKey },
		skip: !workflowId || !shareKey,
	});

	const {
		data: requestData,
		loading: requestLoading,
		refetch: refetchRequest,
		error: requestError,
	} = useQuery(GET_REQUEST, {
		variables: { requestId: requestId },
		skip: !requestId,
	});

	const {
		data: requestedWorkflowData,
		loading: requestWorkflowLoading,
		refetch: refetchRequestWorkflow,
		error: requestWorkflowError,
	} = useQuery(GET_DISSEMINATE_WORKFLOW, {
		variables: { id: workflowId, shareKey: requestShareKey },
		skip: !workflowId || !requestShareKey,
	});

	useEffect(() => {
		if (!!workflowData?.disseminateWorkflow) {
			const workflow = workflowData?.disseminateWorkflow?.workflow as Workflow;
			setWorkflow(workflow);
			setWorkflowTemplate(workflow?.wfTemplate);
			const fragments = workflowData?.disseminateWorkflow?.dataFragments as DataFragment[];
			setAllDataFragments(fragments ?? []);
		}
	}, [workflowId, workflowData]);

	useEffect(() => {
		if (!!requestData?.getRequestData) {
			const workflowId = requestData?.getRequestData?.workflowId;
			const shareKey = requestData?.getRequestData?.shareKey;
			setWorkflowId(workflowId);
			setRequestShareKey(shareKey);
		}
	}, [requestId, requestData]);

	useEffect(() => {
		if (!!requestedWorkflowData?.disseminateWorkflow) {
			const workflow = requestedWorkflowData?.disseminateWorkflow?.workflow as Workflow;
			setWorkflow(workflow);
			setWorkflowTemplate(workflow?.wfTemplate);
			const fragments = requestedWorkflowData?.disseminateWorkflow
				?.dataFragments as DataFragment[];
			setAllDataFragments(fragments ?? []);
		}
	}, [workflowId, requestedWorkflowData]);

	return {
		loading,
		setWorkflowId,
		workflow,
		workflowId,
		workflowTemplate,
		workflowStep,
		setWorkflowStep,
		eventWorkflowId,
		setWorkflowTemplate,
		setEventWorkflowId,
		allDataFragments,
		setRequestId,
	};
}
