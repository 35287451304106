import React, { useContext, useState } from 'react';
import { Document } from '../../../models/models';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FaPlus, FaRegFilePdf } from 'react-icons/fa';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import { useModal } from '@ebay/nice-modal-react';
import { Modals } from '../../../components/Modals';
import { CardActions } from '../../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
function Attachments({ documents }: { documents: Document[] }) {
	const {
		setFragmentId: setProductId,
		setSelectedFragmentType,
		deleteDataFragment,
	} = useContext(DatalakeContext);
	const datalakeModal = useModal(Modals.DATALAKE);
	console.log('Documentsssss:', documents);
	console.log('Document:', documents[0]?.documents);

	return (
		<div className='px-2 px-md-5 mb-4'>
			<p className='uppercase-title'>Attachments</p>
			<ScrollContainer horizontal={true} className={`scroll-container data-row`}>
				<div
					className='row-item position-relative bg-dark cursor-pointer'
					onClick={() => {
						setSelectedFragmentType('document');
						setProductId('new');
						datalakeModal.show();
					}}>
					<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
						<FaPlus size={75} color='#71788B' className='mt-5' />
					</div>
					<div className='px-3 pb-4'>
						<p className='shop-description text-white fs-4 mb-0 mt-2 px-2'>Add</p>
					</div>
				</div>
				{documents?.map((document) => (
					<>
						{!!document?.documents?.[0]?.url && (
							<div className='row-item position-relative grey-card'>
								<div
									className='card-top-section text-center d-flex align-items-center justify-content-center'
									style={{ paddingBottom: '10px' }}
									onClick={() => {
										setProductId(document?.id);
										datalakeModal.show();
									}}>
									<FaRegFilePdf
										style={{
											alignItems: 'center',
											alignContent: 'center',
											padding: '15px 15px 10px 15px',
											width: '90%',
											height: '90%',
											backgroundColor: 'transparent',
											color: 'red',
										}}
									/>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontSize: '18px',
										}}></div>
								</div>
								<div className='card-bottom-section px-3'>
									<div className='d-flex justify-content-between align-items-center'>
										<p className='uppercase'>{document?.name}</p>
										<CardActions>
											<Dropdown>
												<DropdownToggle hasIcon={false}>
													<Button
														icon='MoreHoriz'
														// color={themeStatus}
														shadow='default'
														aria-label='Edit'
														className='p-0'
													/>
												</DropdownToggle>
												<DropdownMenu
													isAlignmentEnd
													style={{ bottom: '20px', right: 0 }}>
													<DropdownItem>
														<Button
															icon='Visibility'
															onClick={() => {
																setProductId(document?.id);
																datalakeModal.show();
															}}>
															View
														</Button>
													</DropdownItem>
													<DropdownItem isDivider />
													<DropdownItem>
														<Button
															icon='Delete'
															onClick={() => {
																deleteDataFragment(document);
															}}>
															Delete
														</Button>
													</DropdownItem>
												</DropdownMenu>
											</Dropdown>
										</CardActions>
									</div>
								</div>
							</div>
						)}
					</>
				))}
			</ScrollContainer>
		</div>
	);
}
export default Attachments;
