import { Dispatch, SetStateAction, createContext } from 'react';
import useChatCollaborator from '../hooks/useChatCollaborator';
import { ChatCollaboration } from '../models/chat';

interface IChatCollaboratorContext {
	chats: ChatCollaboration[];
	activeChat?: ChatCollaboration;
	setActiveChat: Dispatch<SetStateAction<ChatCollaboration | undefined>>;
	newChatCollaboration: () => Promise<void>;
	send: (content: string, chatId?: string) => Promise<ChatCollaboration | undefined>
	sendAiCollaborator: (content: string, chatId?: string) => Promise<void>;
	loading: boolean;
	sendToronto: (content: string, chatId?: string | undefined) => Promise<string | undefined>;
	sendAiAgent: (content: string, chatId?: string | undefined) => Promise<string | undefined>;
	setActiveDocumentId: Dispatch<SetStateAction<string | undefined>>;
}

export const ChatCollaboratorContext = createContext<IChatCollaboratorContext>(
	{} as IChatCollaboratorContext,
);

export const ChatCollaboratorProvider = ({ children }: { children: any }) => {
	const {
		chats,
		setActiveDocumentId,
		activeChat,
		setActiveChat,
		newChatCollaboration,
		send,
		sendAiCollaborator,
		loading,
		sendToronto,
		sendAiAgent,
	} = useChatCollaborator();

	return (
		<ChatCollaboratorContext.Provider
			value={{
				chats,
				activeChat,
				setActiveChat,
				newChatCollaboration,
				send,
				sendAiCollaborator,
				loading,
				sendToronto,
				sendAiAgent,
				setActiveDocumentId
			}}>
			{children}
		</ChatCollaboratorContext.Provider>
	);
};
