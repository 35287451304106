import { useContext, useEffect, useState } from 'react';
import { chatGPTName, sequenceId, uniqueId } from '../util/util';
import { UserContext } from '../contexts/UserContext';
import {
	PROMPT_TYPE,
	Workflow,
	WorkflowCampaign,
	WorkflowComposedEmail,
	WorkflowOutput,
	WorkflowPPT,
	WorkflowStepTemplate,
	WorkflowTemplate,
} from '../models/models';
import { GooglePlace } from '../models/product';
import { ClientContext } from '../contexts/ClientContext';
import { ModuleContext } from '../contexts/ModuleContext';
import { useMutation, useQuery } from '@apollo/client';
import {
	CREATE_WORKFLOW_SHAREKEY,
	DELETE_WORKFLOW_OUTPUT,
	UPDATE_CAMPAIGN_MUTATION,
	UPDATE_WORKFLOW_MUTATION,
	UPDATE_WORKFLOW_OUTPUT,
	UPDATE_WORKFLOW_PPT_MUTATION,
} from '../GraphQL/workflow/mutation';
import { GET_WORKFLOW, GET_WORKFLOW_OUTPUTS, GET_WORKFLOW_PPT } from '../GraphQL/workflow/queries';
import { WORKFLOW_CALL_MUTATION } from '../GraphQL/callable-mutations';
import useImageUpload from '../helpers/useImageUpload';
import { ChatCollaboration } from '../models/chat';
import { ChatCollaboratorContext } from '../contexts/ChatCollaboratorContext';
import { GET_CHAT_COMPLETION } from '../GraphQL/collaboration/queries';
import { ChatConversation } from '../models/chatgpt/models';

enum WorkflowRequestType {
	completion = 'completion',
	send_collaboration_request = 'send_collaboration_request',
	createNewWorkflowStep = 'createNewWorkflowStep',
	deleteWorkflowStep = 'deleteWorkflowStep',
	cropVideoOutput = 'cropVideoOutput',
	exportWorkflow = 'exportWorkflow',
	dynamicWorkflow = "dynamicWorkflow",
	send_workflow_emails = "send_workflow_emails",
}

export default function useWorkflow(inputWorkflowId: string) {
	const { uploadFileToBlobStorage } = useImageUpload();
	const { selectedModule, workflowTemplates } = useContext(ModuleContext);
	const { activeClientId } = useContext(ClientContext);
	const blankVM = (): Workflow => {
		return {
			clientId: activeClientId ?? activeUser?.id ?? 'unknown',
			created: new Date().toISOString(),
			lastUpdated: new Date().toISOString(),
			data: {},
			id: uniqueId(),
			userId: activeUser?.id ?? '',
			moduleId: workflowTemplate?.moduleId ?? selectedModule,
			name: '',
			text: '',
			wfTemplateId: '',
			status: 'active',
		};
	};
	const [loading, setLoading] = useState(false);
	const { activeUser, user } = useContext(UserContext);
	const [workflowId, setWorkflowId] = useState(inputWorkflowId);
	const [workflow, setWorkflow] = useState<Workflow>();
	const [workflowPPT, setWorkflowPPT] = useState<WorkflowPPT>();
	const [workflowTemplate, setWorkflowTemplate] = useState<WorkflowTemplate>();
	const [workflowStep, setWorkflowStep] = useState<WorkflowStepTemplate>();
	const [draftWorkflow, setDraftWorkflow] = useState<Workflow>(blankVM());
	const [workflowOutput, setWorkflowOutput] = useState<WorkflowOutput[]>([]);
	const [allWorkflowOutput, setAllWorkflowOutput] = useState<WorkflowOutput[]>([]);
	const [eventWorkflowId, setEventWorkflowId] = useState<string[]>([]);
	const [activeChatCompletion, setActiveChatCompletion] = useState<ChatConversation>();
	const [updateWorkflow] = useMutation(UPDATE_WORKFLOW_MUTATION);
	const [updateWorkflowPPT] = useMutation(UPDATE_WORKFLOW_PPT_MUTATION);
	const [updateCampaign] = useMutation(UPDATE_CAMPAIGN_MUTATION);
	const [updateWorkflowOutput] = useMutation(UPDATE_WORKFLOW_OUTPUT);
	const [deleteWorkflowOutputMutation] = useMutation(DELETE_WORKFLOW_OUTPUT);
	const {
		data: workflowData,
		loading: locading,
		refetch: refetchWorkflow,
	} = useQuery(GET_WORKFLOW, {
		variables: { id: workflowId, accessToken: user?.accessToken },
		skip: !user?.accessToken || !user?.uniqueId || !workflowId || workflowId === 'new',
	});
	const { data: workflowOutputsData, refetch: refetchWorkflowOutputs } = useQuery(
		GET_WORKFLOW_OUTPUTS,
		{
			variables: { id: workflowId, accessToken: user?.accessToken },
			skip: !user?.accessToken || !user?.uniqueId,
		},
	);
	const {
		data: workflowPPTData,
		loading: locadingPPT,
		refetch: refetchWorkflowPPT,
	} = useQuery(GET_WORKFLOW_PPT, {
		variables: { id: workflowId, accessToken: user?.accessToken },
		skip: !user?.accessToken || !user?.uniqueId || !workflowId || workflowId === 'new',
	});
	const {data: chatCompletionData, refetch: refetchChatCompletion} = useQuery(
		GET_CHAT_COMPLETION,
		{
			variables: { clientId: activeClientId, chatId: workflowId, accessToken: user?.accessToken },
			skip: !user?.accessToken || !workflowId || !activeClientId,
		},
	)
	const [workflowCall, { error: workflowCallError }] = useMutation(WORKFLOW_CALL_MUTATION);

	useEffect(() => {
		if(!!chatCompletionData){
			setActiveChatCompletion(chatCompletionData.getChatCompletion as ChatConversation);
		}
	}, [chatCompletionData]);

	useEffect(() => {
		setWorkflowId(inputWorkflowId);
	}, [inputWorkflowId]);

	useEffect(() => {
		if (!!workflowTemplates?.length && !workflowTemplate) {
			setWorkflowTemplate(workflowTemplates?.[0]);
		}
	}, [workflowTemplates]);

	useEffect(() => {
		if (!user?.uniqueId || !workflowId) {
			setWorkflow(undefined);
			setDraftWorkflow(blankVM());
		} else if (workflowId == 'new') {
			setWorkflow(undefined);
			initializeVMForNew();
			return;
		} else if (!!workflowData?.workflow) {
			const d = workflowData?.workflow as Workflow;
			resetVM();
			setWorkflow(d);
			setDraftWorkflow(d);
			setWorkflowTemplate(d?.wfTemplate);
		}
	}, [user?.uniqueId, workflowId, workflowData]);

	useEffect(() => {
		if (!user?.uniqueId || !workflowId) {
			setWorkflowOutput([]);
		} else if (workflowId == 'new') {
			setWorkflowOutput([]);
			return;
		} else if (!!workflowOutputsData?.getWorkflowOutputs) {
			setWorkflowOutput(workflowOutputsData?.getWorkflowOutputs);
		}
	}, [user?.uniqueId, workflowId, workflowOutputsData]);

	useEffect(() => {
		if (!user?.uniqueId || !workflowId) {
			setWorkflowPPT(undefined);
		} else if (workflowId == 'new') {
			setWorkflowPPT(undefined);
			return;
		} else if (!!workflowPPTData?.workflowPPT) {
			setWorkflowPPT(workflowPPTData?.workflowPPT);
		}
	}, [user?.uniqueId, workflowId, workflowPPTData]);

	const initializeVMForNew = () => {
		setDraftWorkflow(blankVM());
		setWorkflow(blankVM());
	};

	const resetVM = () => {
		setDraftWorkflow(blankVM());
		setWorkflow(undefined);
		setWorkflowTemplate(undefined);
	};

	const generateDynamicWorkflow = async (workflowInit: Workflow, modifiedPrompt?: string) => {
		setLoading(true);
		try {
			let response;
			if(!!modifiedPrompt) {
				response = await workflowCall({
					variables: {
						userInput: {
							requestType: WorkflowRequestType.dynamicWorkflow,
							clientId: activeClientId,
							workflowInit,
							message: { role: 'user', name: chatGPTName(activeUser?.name), content: modifiedPrompt },
							promptId: 'mission-builder-generate-dynamic-workflow',
							accessToken: user?.accessToken,
						},
					},
				});
			}
			else {
				response = await workflowCall({
					variables: {
						userInput: {
							requestType: WorkflowRequestType.dynamicWorkflow,
							clientId: activeClientId,
							workflowInit,
							promptId: 'mission-builder-generate-dynamic-workflow',
							accessToken: user?.accessToken,
						},
					},
				});
			}
			await refetchChatCompletion({clientId: activeClientId, chatId: response.data?.workflowCall.id, accessToken: user?.accessToken});
			await refetchWorkflow({ id: response.data?.workflowCall.id, accessToken: user?.accessToken });
			setWorkflowId(response.data?.workflowCall.id);
			setLoading(false);
			} catch (err) {
				setLoading(false);
				console.log("error while generating chatGPT dynamic workflow",{err});
			}
	  }

	const saveWorkflow = async (file: Workflow) => {
		await setLoading(true);
		if (!file?.wfTemplateId || file?.wfTemplateId == '') {
			file = {
				...file,
				wfTemplateId: workflowTemplate?.id ?? '',
				wfTemplate: workflowTemplate,
				userId: activeUser?.id ?? '',
			};
		}
		await updateWorkflow({
			variables: {
				userInput: {
					...file,
				},
				accessToken: user?.accessToken,
			},
		});
		await refetchWorkflow({ id: file?.id, accessToken: user?.accessToken });
		await setWorkflowId(file.id);
		await setLoading(false);
	};

	const saveWorkflowPPT = async (file: WorkflowPPT) => {
		await setLoading(true);
		if (!file?.wfId || file?.wfId == '') {
			file = {
				...file,
				wfId: workflowTemplate?.id ?? '',
			};
		}
		await updateWorkflowPPT({
			variables: {
				userInput: {
					...file,
				},
				accessToken: user?.accessToken,
			},
		});
		await refetchWorkflowPPT({ id: file?.wfId, accessToken: user?.accessToken });
		await setLoading(false);
	};

	const saveWorkflowOutput = async (output: WorkflowOutput) => {
		await setLoading(true);
		await updateWorkflowOutput({
			variables: {
				userInput: {
					...output,
				},
				accessToken: user?.accessToken,
			},
		});
		await refetchWorkflowOutputs({id: workflowId, accessToken: user?.accessToken});
		await setLoading(false);
	};


	const deleteWorkflowOutput = async (output: WorkflowOutput) => {
		try{
			setLoading(true);
			console.log("ID:", output?.id);
        console.log("AccessToken:", user?.accessToken);
		await deleteWorkflowOutputMutation({
			variables: {
				id: output?.id,
				accessToken: user?.accessToken,
			},
		});
		await refetchWorkflowOutputs({ id: workflowId, accessToken: user?.accessToken });
		setLoading(false);
		}
		catch(error){
			console.log(error);
		}
			
	};

	const createWorkflow = async (file: Workflow) => {
		await setLoading(true);
		await updateWorkflow({
			variables: {
				userInput: {
					...file,
				},
				accessToken: user?.accessToken,
			},
		});
		await refetchWorkflow({ id: file?.id, accessToken: user?.accessToken });
		await setLoading(false);
	};

	const generate = async (
		promptType: PROMPT_TYPE,
		input?: Record<string, string>,
		chatId?: string,
		combination?: any[],
	) => {
		setLoading(true);
		try {
			const response = await workflowCall({
				variables: {
					userInput: {
						requestType: WorkflowRequestType.completion,
						clientId: activeClientId,
						workflowId,
						promptType,
						input,
						id: chatId,
						combination,
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchWorkflow({
				id: workflow?.id ?? workflowId,
				accessToken: user?.accessToken,
			});
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const createNewWorkflowStep = async (data: any) => {
		setLoading(true);
		try {
			const response = await workflowCall({
				variables: {
					userInput: {
						requestType: WorkflowRequestType.createNewWorkflowStep,
						createWorkflowStepData: data,
						clientId: activeClientId,
						workflowId: workflow?.id ?? workflowId,
						accessToken: user?.accessToken,
					},
				},
			});
			console.log('new step created ', response);
			await refetchWorkflow({
				id: workflow?.id ?? workflowId,
				accessToken: user?.accessToken,
			});
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const deleteWorkflowStep = async (data: any) => {
		setLoading(true);
		try {
			const response = await workflowCall({
				variables: {
					userInput: {
						requestType: WorkflowRequestType.deleteWorkflowStep,
						chatId: data,
						clientId: activeClientId,
						workflowId: workflow?.id ?? workflowId,
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchWorkflow({ id: workflow?.id, accessToken: user?.accessToken });
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const uploadVideoOutput = async (
		blob: Blob,
		thumbnail: Blob,
		workflowId: string,
		stepId: string,
	) => {
		try {
			const response = (await uploadFileToBlobStorage({ file: blob }));
			const output: WorkflowOutput = {
				id: sequenceId(),
				wfId: workflowId,
				clientId: activeClientId,
				name: `${stepId}-Recording`,
				outputType: 'visual',
				created: new Date().toISOString(),
				promptType: 'visual',
				promptId: '',
				data: {
					stepId: stepId,
					videoUrl: response.url,
					thumbnailUrl: response.thumbnailUrl,
				},
				stepId: stepId,
				lastUpdated: new Date().toISOString(),
			};
			await saveWorkflowOutput(output);
			return response.url;
		} catch (err) {
			console.log({ err });
		}
		return undefined;
	};
	const cropOutputVideo = async (
		videoUrl: string,
		cropdata: { width: number; height: number; x: number; y: number },
	) => {
		try {
			const response = await workflowCall({
				variables: {
					userInput: {
						requestType: WorkflowRequestType.cropVideoOutput,
						input: { videoUrl: videoUrl, cropData: cropdata },
						clientId: activeClientId,
						workflowId: workflow?.id ?? workflowId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.workflowCall;
		} catch (err) {
			console.log(err);
		}
	};

	const exportWorkflow = async () => {
		try {
			await updateWorkflow({
				variables: {
					userInput: {
						id: workflow?.id ?? workflowId,
						clientId: activeClientId,
						workflowId: workflow?.id ?? workflowId,
					},
					accessToken: user?.accessToken,
				},
			});
			const response = await workflowCall({
				variables: {
					userInput: {
						requestType: WorkflowRequestType.exportWorkflow,
						clientId: activeClientId,
						workflowId: workflow?.id ?? workflowId,
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchWorkflow({ id: workflow?.id, accessToken: user?.accessToken });
			return {
				url: response?.data?.workflowCall,
				type: 'success',
			};
		} catch (err) {
			console.log(err);
			return {
				url: err?.toString(),
				type: 'error',
			};
		}
	};
	const saveWorkflowCampaign = async (campaign: WorkflowCampaign) => {
		await setLoading(true);
		console.log(campaign);
		const camp: WorkflowCampaign = {
			...campaign,
			clientId: activeClientId ?? user?.uniqueId,
		};
		await updateCampaign({
			variables: {
				userInput: {
					id: camp?.campaignId,
					...camp,
				},
				accessToken: user?.accessToken,
			},
		});
		await setLoading(false);
	};

	const sendWorkflowEmails = async (emails: WorkflowComposedEmail[]) => {
		setLoading(true);
		try {
			await workflowCall({
				variables: {
					userInput: {
						requestType: WorkflowRequestType.send_workflow_emails,
						clientId: activeClientId,
						input: { emails },
						accessToken: user?.accessToken,
					},
				},
			});
			await refetchWorkflow({ id: workflow?.id, accessToken: user?.accessToken });
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	};

	return {
		loading,
		setWorkflowId,
		workflow,
		workflowId,
		workflowPPT,
		saveWorkflow,
		saveWorkflowPPT,
		createWorkflow,
		workflowTemplate,
		workflowStep,
		setWorkflowStep,
		workflowOutput,
		eventWorkflowId,
		allWorkflowOutput,
		generate,
		setWorkflowTemplate,
		saveWorkflowOutput,
		createNewWorkflowStep,
		deleteWorkflowStep,
		uploadVideoOutput,
		deleteWorkflowOutput,
		cropOutputVideo,
		exportWorkflow,
		saveWorkflowCampaign,
		setEventWorkflowId,
		generateDynamicWorkflow,
		activeChatCompletion,
		sendWorkflowEmails,
		refetchWorkflowPPT,
	};
}
