import { gql } from '@apollo/client';

export const SAVE_DATA_FRAGMENT = gql`
	mutation SaveDataFragment($fragment: JSON, $accessToken: String) {
		saveDataFragment(fragment: $fragment, accessToken: $accessToken)
	}
`;
export const DELETE_DATA_FRAGMENT = gql`
	mutation DeleteDataFragment($id: String, $accessToken: String) {
		deleteDataFragment(id: $id, accessToken: $accessToken)
	}
`;
