import { useState, useEffect, useContext, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { UserContext } from '../contexts/UserContext';
import { GET_ALL_USERS_FROM_AD } from '../GraphQL/user/mutation';
import { EntraIdUser, PeopleUser } from '../Azure/models';
import { GET_CURRENT_PEOPLE, GET_PEOPLE_SEARCH } from '../GraphQL/people/queries';
import {SAVE_PEOPLE_MUTATION} from '../GraphQL/people/mutation';

export function useGetAllUsers() {
	// Renamed function to useGetAllUsers
	const [users, setUsers] = useState<EntraIdUser[]>([]);
	const [peopleUsers, setPeopleUsers] = useState<PeopleUser[]>([]);
	const [peopleUsersSeachText, setPeopleUsersSearchText] = useState("");
	const [activeId, setAcitveId] = useState<string>();
	const [selectedPeopleUser, setSelectedPeopleUser] = useState<PeopleUser>();
	const [offset, setOffset] = useState(false);
	const [offsetUserLength, setOffsetUserLength] = useState(0);
	const [search, setSearch] = useState('');
	const { user } = useContext(UserContext);
	const [gettingData] = useMutation(GET_ALL_USERS_FROM_AD);
	const {loading, data: peopleData, fetchMore, refetch: refetchPeopleData} = useQuery(GET_PEOPLE_SEARCH, {
		variables: {
			searchText: search,
			offset: offsetUserLength,
			limit: 4,
			accessToken: user?.accessToken
		}, notifyOnNetworkStatusChange: true
	});
	const {data: activePersonData} = useQuery(GET_CURRENT_PEOPLE, {
		variables: {
			id: activeId,
			accessToken: user?.accessToken
		},
		skip: !user?.accessToken || !activeId
	});
	const [savePeopleMutation] = useMutation(SAVE_PEOPLE_MUTATION);
	const [gotUsers, setGotUsers] = useState(false);

	useEffect(() => {
		if(!!activePersonData && !!activePersonData.person) {
			setSelectedPeopleUser(activePersonData.person as PeopleUser);
		}
	}, [activeId, activePersonData]);

	useEffect(() => {
		if (user?.accessToken && !gotUsers) {
			(async () => {
				setGotUsers(true);
				await getUsers();
			})();
		}
	}, [user?.accessToken]);

	// useEffect(() => {
	// 	if(!!peopleData) {
	// 		// setPeopleUsers(peopleData?.getPeople as PeopleUser[]);	
  //     setPeopleUsers([])
	// 	}
	// }, [peopleData]);

	useEffect(() => {
		if (offsetUserLength > 0 && peopleData) { // Only fetch more if offset is not 0
			console.log("fetchMore", search);
			 fetchMore({
				variables: {
					searchText: search,
					offset: offsetUserLength,
					limit:3,
					accessToken: user?.accessToken
				},
			});
		}
	}, [offsetUserLength]);

	useEffect(() => {
		if(offset) {
			setOffsetUserLength(peopleUsers.length);
			setOffset(false);
		}
	}, [offset]);

	const loadMore = useCallback(() => {
		setOffset(true);
	  }, []);

	const getUsers = async () => {
		const response = await gettingData({
			variables: {
				accessToken: user?.accessToken,
			},
		});
		console.log({ response });
		setUsers(response?.data?.getAllUsersFromAzureAD);
		return response?.data?.getAllUsersFromAzureAD as EntraIdUser[];
	};

	const getPeople = async (searchText?: string) => {
		setOffsetUserLength(0);
		// await refetchPeopleData({searchText, offset: offsetUserLength, limit:2, accessToken: user?.accessToken});
	};

	const savePeople = async(data: Partial<PeopleUser>) => {
		try {
			await savePeopleMutation({
				variables: {
					user: { ...data },
					accessToken: user?.accessToken,
				},
			});
		} catch (error) {
			console.error('Error modifying people in the collection: ', error);
		}
	}

	return {
		users,
		getUsers,
		getPeople,
		peopleUsers,
		setPeopleUsersSearchText,
		peopleUsersSeachText,
		setSelectedPeopleUser,
		selectedPeopleUser,
		savePeople,
		loading,
		loadMore,
		setSearch,
		search,
		setAcitveId
	};
}
