import { gql } from '@apollo/client';

export const GET_FRAGMENT = gql`
	query Query($dataFragmentId: String, $accessToken: String) {
		dataFragment(id: $dataFragmentId, accessToken: $accessToken)
	}
`;

export const GET_ALL_FRAGMENTS = gql`
	query Query($clientId: String, $level1: String, $level2: String, $level3: String, $pageNumber: Int, $limit: Int, $accessToken: String) {
		getDataFragments(clientId: $clientId, level1: $level1, level2: $level2, level3: $level3, pageNumber: $pageNumber, limit: $limit, accessToken: $accessToken)
	}
`;