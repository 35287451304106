import React, { useContext, useEffect } from 'react';
import { BsTelephone } from 'react-icons/bs';
import { GoDotFill, GoMail } from 'react-icons/go';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { GiDuration } from 'react-icons/gi';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { GetAllUsersContext } from '../../../contexts/getAllUsersContext';
import Spinner from '../../../components/bootstrap/Spinner';


function UserProfile() {
	const { id } = useParams();
	const {selectedPeopleUser, setAcitveId}  = useContext(GetAllUsersContext);

	useEffect(() => {
		setAcitveId(id);
	}, [id]);

	return !selectedPeopleUser ? <Spinner/> :
		(
		<div className='selectedPeopleUser-profile-container w-100'>
			<div className='container col-lg-7 mt-3  py-5'>
				<div className='basic-details rounded border overflow-hidden mb-3 card-container-bg'>
					<div className='profile-backdrop border-bottom'>
						<div className='avator overflow-hidden'>
							{selectedPeopleUser?.profilePictureUrl ? (
								<img
									src={selectedPeopleUser?.profilePictureUrl}
									alt='selectedPeopleUser-profile'
									className='img-fluid circle w-100 h-100'
									style={{ objectFit: 'cover' }}
									loading='lazy'
								/>
							) : (
								<p className='text-primary fs-1 fw-bold mb-0'>
									{selectedPeopleUser?.firstName?.charAt(0)}
									{selectedPeopleUser?.lastName?.charAt(0)}
								</p>
							)}
						</div>
					</div>
					<div className='selectedPeopleUser-detail-container p-5 d-lg-flex justify-content-between'>
						<div className='flex-shrink-0 col-lg-8'>
							<h3 className='fs-2'>
								{selectedPeopleUser?.firstName}
								&nbsp;
								{selectedPeopleUser?.lastName}
							</h3>
							<p className='designation'>{selectedPeopleUser?.headline}</p>
							<span className='text-muted'>
								{selectedPeopleUser?.location?.city},{selectedPeopleUser?.location?.country}
							</span>
						</div>
						<div className='col-lg-4'>
							<p className='mb-2 fs-4'>Contact Info</p>
							<div className='d-flex gap-2 mb-2'>
								<div>
									<BsTelephone />
								</div>
								<div>
									<p className='mb-0'>Phone Number</p>
									<p className='mb-0'>{selectedPeopleUser?.contactInfo?.email}</p>
								</div>
							</div>
							<div className='d-flex gap-2'>
								<div>
									{' '}
									<AiOutlineLinkedin />
								</div>
								<div>
									<p className='mb-0'>Linkedin</p>
									<p className='mb-0'>{selectedPeopleUser?.contactInfo?.linkedInProfileUrl}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='rounded border overflow-hidden mb-3 card-container-bg'>
					<div className='p-5'>
						<div className='mb-4'>
							<h3 className='fs-2 mb-0'>Highlights</h3>
						</div>
						<div className='activity-container'>
							{selectedPeopleUser?.highlights?.map((highlight) => (
								<div className='mb-3'>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											{highlight}
										</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className='rounded border overflow-hidden mb-3 card-container-bg'>
					<div className='p-5'>
						<div className='mb-4'>
							<h3 className='fs-2 mb-0'>About</h3>
						</div>
						<div>
							<p className=''>{selectedPeopleUser?.summary}</p>
						</div>
					</div>
				</div>
				<div className='rounded border overflow-hidden mb-3 card-container-bg'>
					<div className='p-5'>
						<div className='mb-4'>
							<h3 className='fs-2 mb-0'>Activity</h3>
						</div>
						<div className='activity-container'>
							<div className=''>
								<div className='d-flex justify-content-between align-items-start'>
									<div>
										<p className='mb-1 fw-semibold' style={{ fontSize: 16 }}>
											{selectedPeopleUser?.projects?.[0]?.title}
										</p>
										<p className='mb-2 text-muted'>
											{selectedPeopleUser?.projects?.[0]?.role}
										</p>
									</div>
									<div className='d-flex align-items-center gap-2'>
										<GiDuration size={20} />
										<p className='mb-0 text-muted'>
											{selectedPeopleUser?.projects?.[0]?.startDate} -{' '}
											{selectedPeopleUser?.projects?.[0]?.endDate}
										</p>
									</div>
								</div>
								<p>{selectedPeopleUser?.projects?.[0]?.description}</p>
							</div>
						</div>
					</div>
					<div className='border-top py-3'>
						<p className='text-center mb-0 fs-5 cursor-pointer'>
							Show all activity <IoIosArrowRoundForward />
						</p>
					</div>
				</div>

				<div className='rounded border overflow-hidden mb-3 card-container-bg'>
					<div className='p-5'>
						<div className='mb-4'>
							<h3 className='fs-2 mb-0'>Experience</h3>
						</div>
						<div className='experience-container'>
							{selectedPeopleUser?.experience?.map((experience) => (
								<div className='mb-3'>
									<div className='d-flex gap-5 align-items-start'>
										<div className='company-avator'>
											<p className='text-primary fs-3 fw-bold mb-0'>
												{experience?.company?.charAt(0)}
											</p>
										</div>
										<div>
											<div>
												<p
													className='mb-1 fw-semibold'
													style={{ fontSize: 16 }}>
													{experience?.company}
												</p>
												<p className='mb-2 text-muted'>
													{experience?.role}
												</p>
											</div>
											<div>
												<p className='mb-0 text-muted'>
													{experience?.duration}
												</p>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className='rounded border overflow-hidden mb-3 card-container-bg'>
					<div className='p-5'>
						<div className='mb-4'>
							<h3 className='fs-2 mb-0'>Education</h3>
						</div>
						<div className='experience-container'>
							{selectedPeopleUser?.education?.map((education) => (
								<div className='mb-3'>
									<div className='d-flex gap-5 align-items-start'>
										<div className='company-avator'>
											<p className='text-primary fs-3 fw-bold mb-0'>
												{education?.schoolName?.charAt(0)}
											</p>
										</div>
										<div>
											<div>
												<p
													className='mb-1 fw-semibold'
													style={{ fontSize: 16 }}>
													{education?.schoolName}
												</p>
												<p className='mb-2 text-muted'>
													{education?.degree}
												</p>
											</div>
											<div>
												<p className='mb-0 text-muted'>
													{education?.startDate} to {education?.endDate}
												</p>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className='rounded border overflow-hidden mb-3 card-container-bg'>
					<div className='p-5'>
						<div className='mb-4'>
							<h3 className='fs-2 mb-0'>Skills</h3>
						</div>
						<div className='skills-container'>
							{selectedPeopleUser?.skills?.map((skill, index) => {
								return index <= 1 ? (
									<div className='mb-3'>
										<div className='d-flex gap-5 align-items-start'>
											<div className='company-avator'>
												<p className='text-primary fs-3 fw-bold mb-0'>
													{skill?.charAt(0)}
												</p>
											</div>
											<div>
												<div>
													<p
														className='mb-1 fw-semibold'
														style={{ fontSize: 16 }}>
														{skill}
													</p>
												</div>
											</div>
										</div>
									</div>
								) : (
									<></>
								);
							})}
						</div>
					</div>
					<div className='border-top py-3'>
						<p className='text-center mb-0 fs-5 cursor-pointer'>
							Show all {selectedPeopleUser?.skills?.length} skills <IoIosArrowRoundForward />
						</p>
					</div>
				</div>
				<div className='rounded border overflow-hidden mb-3 card-container-bg'>
					<div className='p-5'>
						<div className='mb-4'>
							<h3 className='fs-2 mb-0'>Certifications</h3>
						</div>
						<div className='experience-container'>
							{selectedPeopleUser?.certification?.map((certification) => (
								<div className='mb-3 d-flex align-items-center gap-4'>
									<GoDotFill size={20} className='text-primary' />
									<div>
										<div>
											<p className='mb-0 fs-3'>
												{certification?.issuingOrganization}
											</p>
										</div>
										<div>
											<p className='mb-1 fw-semibold'>
												{certification?.name}
											</p>
											<p className='mb-2 text-muted'>
												{certification?.issueDate}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserProfile;
