import { useContext, useEffect, useState } from 'react';
import { arraysEqual } from '../util/util';
import { UserContext } from '../contexts/UserContext';
import { ClientContext } from '../contexts/ClientContext';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_DATA_FRAGMENT, SAVE_DATA_FRAGMENT } from '../GraphQL/data-fragment/mutation';
import { GET_ALL_FRAGMENTS, GET_FRAGMENT } from '../GraphQL/data-fragment/queries';
import { WorkflowSearchParams } from '../models/models';

export const ROOT_FOLDER = ['/'];

export default function useFolders<T extends { folder: string[]; id: string; lastUpdated: string }>(
	rootCollection: string,
	filterMethod: (obj: T, filter: string) => boolean,
	initialFolder: string[] = ROOT_FOLDER,
) {
	const [loading, setLoading] = useState(false);
	const { activeClientId, missionHierarchySettings } = useContext(ClientContext);
	const { activeUser, user } = useContext(UserContext);
	const [currentFolder, setCurrentFolder] = useState<string[]>(initialFolder);
	const [filesInChildFolders, setFilesInChildFolders] = useState<T[]>([]);
	const [folders, setFolders] = useState<{ path: string; name: string; fileCount: number }[]>([]);
	const [allFiles, setAllFiles] = useState<T[]>([]);
	const [files, setFiles] = useState<T[]>([]);
	const [file, setFile] = useState<T | undefined>(undefined);
	const [fileId, setFileId] = useState<string>();
	const [suggestedfiles, setSuggestedFiles] = useState<T[]>([]);
	const [refresh, setRefresh] = useState(false);
	const [datalakeSeachParams, setDatalakeSeachParams] = useState<WorkflowSearchParams>({
		level1: '',
		level2: '',
		level3: '',
		pageNumber: 1,
		limit: 100,
		searchText: '',
	});

	const [filteredAllFiles, setFilteredAllFiles] = useState<T[]>([]);
	const [filteredSuggestedFiles, setFilteredSuggestedFiles] = useState<T[]>([]);
	const [filteredFilesInChildFolders, setFilteredFilesInChildFolders] = useState<T[]>([]);
	const [filteredFiles, setFilteredFiles] = useState<T[]>([]);
	const [deleteDataFragment, { error: deleteDataFragmentError }] =
		useMutation(DELETE_DATA_FRAGMENT);
	const [saveDataFragment,{ error: saveDataFragmentError }] = useMutation(SAVE_DATA_FRAGMENT);
	const { data: fragmentData } = useQuery(GET_FRAGMENT, {
		variables: {
			dataFragmentId: fileId,
			accessToken: user?.accessToken,
		},
		skip:
			!user?.accessToken ||
			!user?.uniqueId ||
			!activeClientId ||
			!(!!fileId && !file) ||
			fileId == 'new',
	});
	const { data: allFragmentData, refetch: refetchAllFragment } = useQuery(GET_ALL_FRAGMENTS, {
		variables: {
			clientId: activeClientId,
			accessToken: user?.accessToken,
			level1: datalakeSeachParams?.level1,
			level2: datalakeSeachParams?.level2,
			level3: datalakeSeachParams?.level3,
			pageNumber: datalakeSeachParams?.pageNumber,
			limit: datalakeSeachParams?.limit,
		},
		skip:
			!user?.accessToken ||
			!user?.uniqueId ||
			!activeClientId ||
			!(!!activeClientId && !allFiles?.length),
	});

	const appendToAllFiles = (fragment: T) => {
		setAllFiles((prev) => [...prev.filter((pk) => pk.id !== fragment.id), fragment]);
	};

	useEffect(() => {
		setFilteredFiles(
			files.filter(filterFile).sort((a, b) => (a.lastUpdated > b.lastUpdated ? -1 : 1)),
		);
	}, [files, datalakeSeachParams?.searchText]);

	useEffect(() => {
		setFilteredAllFiles(
			allFiles.filter(filterFile).sort((a, b) => (a.lastUpdated > b.lastUpdated ? -1 : 1)),
		);
	}, [allFiles, datalakeSeachParams?.searchText]);

	useEffect(() => {
		setFilteredSuggestedFiles(
			allFiles
				.filter(filterFile)
				.sort((a, b) => (a.lastUpdated > b.lastUpdated ? -1 : 1))
				?.slice(0, 5),
		);
	}, [allFiles, datalakeSeachParams?.searchText]);

	useEffect(() => {
		setFilteredFilesInChildFolders(filesInChildFolders.filter(filterFile));
	}, [filesInChildFolders, datalakeSeachParams?.searchText]);

	// useEffect(() => {
	// 	if (!user?.uniqueId || !activeClientId) {
	// 		return;
	// 	} else {
	// 		setLoading(true);
	// 		// console.log('Fetching for client id', user?.uniqueId, activeUser, activeClientId);
	// 		// const q = query(
	// 		// 	collection(firestore, `${rootCollection}`),
	// 		// 	where('clientId', '==', activeClientId),
	// 		// ) as Query<T>;
	// 		setAllFiles([]);
	// 		getContainerDataWithQuery(
	// 			rootCollection,
	// 			`SELECT * FROM c WHERE c.clientId = '${activeClientId}'`,
	// 		)
	// 			?.then((data) => {
	// 				console.log('fetched allfiles of client id', activeClientId, data);
	// 				setAllFiles(data);
	// 			})
	// 			?.catch((err) => {
	// 				console.log(err);
	// 			});
	// 		// const unsubscribe = onSnapshot(
	// 		// 	q,
	// 		// 	(snapshot) => {
	// 		// 		snapshot.docChanges().forEach((change) => {
	// 		// 			if (change.type == 'added') {
	// 		// 				appendToAllFiles({
	// 		// 					...change.doc.data(),
	// 		// 					id: change.doc.id,
	// 		// 				} as T);
	// 		// 			} else if (change.type == 'modified') {
	// 		// 				appendToAllFiles({
	// 		// 					...change.doc.data(),
	// 		// 					id: change.doc.id,
	// 		// 				} as T);
	// 		// 			} else if (change.type == 'removed') {
	// 		// 				setAllFiles((prev) =>
	// 		// 					prev.filter((p) => p.id !== change.doc.data().id),
	// 		// 				);
	// 		// 			}
	// 		// 		});
	// 		// 		setLoading(false);
	// 		// 	},
	// 		// 	(err) => {
	// 		// 		console.log(err);
	// 		// 	},
	// 		// );
	// 		// return unsubscribe;
	// 	}
	// }, [activeClientId, user?.uniqueId]);

	useEffect(() => {
		setFiles(
			allFiles?.filter(
				(p) =>
					arraysEqual(p.folder, currentFolder) ||
					(!p.folder && arraysEqual(currentFolder, ROOT_FOLDER)),
			),
		);
		setSuggestedFiles(
			allFiles
				? [...allFiles]
						?.sort((a, b) => (a.lastUpdated > b.lastUpdated ? -1 : 1))
						?.slice(0, 5)
				: [],
		);
		setFilesInChildFolders(
			allFiles?.filter(
				(p) =>
					(p.folder ?? ROOT_FOLDER).length > currentFolder.length &&
					arraysEqual(
						(p.folder ?? ROOT_FOLDER).slice(0, currentFolder.length),
						currentFolder,
					),
			),
		);
	}, [allFiles, currentFolder]);

	useEffect(() => {
		const folderNames = [
			...new Set(filteredFilesInChildFolders?.map((p) => p.folder[currentFolder.length])),
		];
		const folderObjs = folderNames.map((f) => ({
			path: [...currentFolder.slice(), f].join('/'),
			name: f,
			fileCount: filteredFilesInChildFolders.filter(
				(p) => p.folder[currentFolder.length] == f,
			).length,
		}));
		// console.log({folderNames, currentFolder, folderObjs});
		setFolders(folderObjs);
	}, [filteredFilesInChildFolders, currentFolder]);

	useEffect(() => {
		if (!fileId || fileId == 'new') {
			setFile(undefined);
			return;
		} else if (!!fileId) {
			setFile(files?.find((file) => file?.id == fileId));
		}
	}, [fileId]);

	useEffect(() => {
		if (!!fragmentData?.dataFragment) {
			setFile(fragmentData?.dataFragment);
		}
	}, [fragmentData]);
	useEffect(() => {
		if (!!allFragmentData?.getDataFragments) {
			setAllFiles(allFragmentData?.getDataFragments);
		}
	}, [allFragmentData]);

	useEffect(() => {
		(async () => {
		  await refetchAllDataFragmentFunc();
		})();
	  }, [activeClientId]);

	const openFolder = (path: string) => {
		console.log('setting currnet folder', path, ['/', ...path.split('/').slice(2)]);
		setCurrentFolder(['/', ...path.split('/').slice(2)]);
	};

	const openChildFolder = (folderName: string) => {
		setCurrentFolder((prev) => [...prev, folderName]);
	};

	const openBreadCrumb = (folderName: string) => {
		const path = currentFolder.slice(0, currentFolder.indexOf(folderName) + 1).join('/');
		openFolder(path);
	};

	const refetchAllDataFragmentFunc = async () => {
		const res = await refetchAllFragment({
			clientId: activeClientId,
			accessToken: user?.accessToken,
			level1: datalakeSeachParams?.level1,
			level2: datalakeSeachParams?.level2,
			level3: datalakeSeachParams?.level3,
			pageNumber: datalakeSeachParams?.pageNumber,
			limit: datalakeSeachParams?.limit,
		});
		if (!!res?.data?.getDataFragments) {
			setAllFiles(res?.data?.getDataFragments);
		}
	};

	const saveFile = async (file: T) => {
		setLoading(true);
		try {
			const response = await saveDataFragment({
				variables: {
					fragment: file,
					accessToken: user?.accessToken,
				},
			});
			await refetchAllDataFragmentFunc();
			setLoading(false);
			return !!response?.data?.saveDataFragment;
		} catch (err: any) {
			console.error('Error saving the fragment', err);
			return err.toString();
		}
	};

	const createFile = async (file: T) => {
		setLoading(true);
		try {
			const response = await saveDataFragment({
				variables: {
					fragment: file,
					accessToken: user?.accessToken,
				},
			});
			await refetchAllDataFragmentFunc();
			setLoading(false);
			return !!response?.data?.saveDataFragment;
		} catch (err: any) {
			console.error('Error saving the fragment', err);
			return err.toString();
		}
	};

	const deleteFile = async (file: T) => {
		setLoading(true);
		try {
			setAllFiles((prev) => prev.filter((p) => p.id !== file.id));
	
			const response = await deleteDataFragment({
				variables: {
					id: file?.id,
					accessToken: user?.accessToken,
				},
			});
			await refetchAllDataFragmentFunc();
			setLoading(false);
			return !!response?.data?.deleteDataFragment;
		} catch (err: any) {
			console.error('Error deleting the fragment', err);
			setLoading(false);
			setAllFiles((prev) => [...prev, file]);
			return err.toString();
		}
	};
	
	useEffect(() => {
		(async() => {
			await refetchAllDataFragmentFunc();
		})()
	}, [datalakeSeachParams?.level1, datalakeSeachParams?.level2, datalakeSeachParams?.level3, datalakeSeachParams?.limit, datalakeSeachParams?.pageNumber])

	useEffect(() => {
		if((!!datalakeSeachParams?.level1?.length && !missionHierarchySettings?.level1?.includes(datalakeSeachParams?.level1))
			|| (!!datalakeSeachParams?.level2?.length && !missionHierarchySettings?.level2?.includes(datalakeSeachParams?.level2)
			|| (!!datalakeSeachParams?.level3?.length && !missionHierarchySettings?.level3?.includes(datalakeSeachParams?.level3)))){
				setDatalakeSeachParams({
					level1: '',
					level2: '',
					level3: '',
					pageNumber: 1,
					limit: 100,
					searchText: '',
				});
			}
	}, [missionHierarchySettings])

	const filterFile = (file: T) => filterMethod(file, datalakeSeachParams?.searchText);

	const isRootFolder = arraysEqual(currentFolder, ROOT_FOLDER);

	return {
		currentFolder,
		folders,
		loading,
		fileId,
		file,
		files: filteredFiles,
		suggestedfiles: filteredSuggestedFiles,
		allFiles: filteredAllFiles,
		openFolder,
		openChildFolder,
		openBreadCrumb,
		saveFile,
		createFile,
		deleteFile,
		setFileId,
		isRootFolder,
		datalakeSeachParams,
		setDatalakeSeachParams,
	};
}
