import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DbUser } from '../Azure/models';
import { CommunicationPreferences, SalesProfessionalInfo } from '../models/profile';

export interface IAuthContextProps {
	user?: DbUser & SalesProfessionalInfo & CommunicationPreferences;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	return <AuthContext.Provider value={{ user: undefined }}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
