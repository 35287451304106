import { getNameFromImageUrl } from '../util/util';
import { InputWebScrape, NewWebScrape } from '../models/webScrape';
import { useContext } from 'react';
import { ClientContext } from '../contexts/ClientContext';
import { UserContext } from '../contexts/UserContext';
import { useMutation } from '@apollo/client';
import {
	CHAT_GPT_CALL_MUTATION,
	CREATE_PPT,
	EXPRESS_DOCKER_MUTATION,
	TRANSCRIBE_CALL_MUTATION,
	VIDEO_CONVERTER,
	WEB_SCRAPE_CALL_MUTATION,
} from '../GraphQL/callable-mutations';
import _ from 'lodash';

export enum RequestType {
	documentToText = 'documentToText',
	speechToText = 'speechToText',
	webScrape = 'webScrape',
	translateScpeech = 'translateScpeech',
}

export default function useTranscribe() {
	const [transcribeCall, { error: transcribeCallError }] = useMutation(TRANSCRIBE_CALL_MUTATION);
	const [chatgptCall, { error: chatgptCallError }] = useMutation(CHAT_GPT_CALL_MUTATION);
	const [webScrapeCall, { error: webScrapeCallError }] = useMutation(WEB_SCRAPE_CALL_MUTATION);
	const [expressDockerCall] = useMutation(EXPRESS_DOCKER_MUTATION);
	const [videoConverter] = useMutation(VIDEO_CONVERTER);
	const [createPPT] = useMutation(CREATE_PPT);
	const { activeClientId } = useContext(ClientContext);
	const { user } = useContext(UserContext);

	const getDocumentToText = async (url: string, summerizeDocument?: boolean) => {
		try {
			const response = await chatgptCall({
				variables: {
					userInput: {
						requestType: RequestType.documentToText,
						clientId: activeClientId,
						fileName: getNameFromImageUrl(url),
						url,
						accessToken: user?.accessToken,
						summerizeDocument:
							summerizeDocument != undefined ? summerizeDocument : false,
					},
				},
			});
			return response?.data?.chatGPTCall as string;
		} catch (e) {
			console.log({ e });
			return url;
		}
	};

	const getSpeechToText = async (url: string, refId: string) => {
		try {
			const response = await chatgptCall({
				variables: {
					userInput: {
						requestType: RequestType.speechToText,
						clientId: activeClientId,
						fileName: getNameFromImageUrl(url),
						url,
						refId: refId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.chatGPTCall as string;
		} catch (e) {
			console.log({ e });
			return url;
		}
	};

	const getTranslateScpeech = async (url: string, refId: string) => {
		try {
			const response = await chatgptCall({
				variables: {
					userInput: {
						requestType: RequestType.translateScpeech,
						clientId: activeClientId,
						fileName: getNameFromImageUrl(url),
						url,
						refId: refId,
						accessToken: user?.accessToken,
					},
				},
			});
			return response?.data?.chatGPTCall as string;
		} catch (e) {
			console.log({ e });
			return url;
		}
	};

	const expressDockerPostApiFunc = async (url: any, data: any) => {
		try {
			const response = await expressDockerCall({
				variables: {
					url,
					userInput: data,
					accessToken: user?.accessToken,
				},
			});
			return response?.data?.expressDockerPostApi;
		} catch (e) {
			console.log({ e });
			return null;
		}
	};

	const videoConverterFunc = async (data: any) => {
		try {
			// const response = await videoConverter({
			// 	variables: {
			// 		userInput: data,
			// 		accessToken: user?.accessToken,
			// 	},
			// });
			// return response?.data?.videoConverter;
			return await expressDockerPostApiFunc('convert-video', data);
		} catch (e) {
			console.log({ e });
			return null;
		}
	};

	const createPPTFunc = async (data: any) => {
		try {
			// const response = await createPPT({
			// 	variables: {
			// 		userInput: data,
			// 		accessToken: user?.accessToken,
			// 	},
			// });
			// return response?.data?.createPPT;
			return await expressDockerPostApiFunc('create-output-ppt', data);
		} catch (e) {
			console.log({ e });
			return null;
		}
	};

	const getWebToText = async (input: InputWebScrape) => {
		try {
			// const response = await webScrapeCall({
			// 	variables: {
			// 		userInput: {
			// 			requestType: RequestType.webScrape,
			// 			input: { ...input, clientId: activeClientId },
			// 			accessToken: user?.accessToken,
			// 		},
			// 	},
			// });
			// return response?.data?.webScrapeCall as NewWebScrape;
			const res = await expressDockerPostApiFunc('webscrape-call', {
				requestType: RequestType.webScrape,
				activeClientId: activeClientId,
				input: {
					...input,
					requestType: RequestType.webScrape,
					clientId: activeClientId,
				},
				accessToken: user?.accessToken,
			});
			const response = res as NewWebScrape;
			// const response = await expressDockerPostApi("webscrape-call", {
			// 	userInput: {
			// 		requestType: RequestType.webScrape,
			// 		input: { ...input, clientId: activeClientId },
			// 		accessToken: user?.accessToken,
			// 	},
			// });
			if (!response?.url) {
				return {
					url: input?.url,
					referenceId: input?.referenceId,
					htmlTextArray: input?.url,
				} as NewWebScrape;
			}
			return response as NewWebScrape;
		} catch (e) {
			console.log({ e });
			return {
				url: input?.url,
				referenceId: input?.referenceId,
				htmlTextArray: input?.url,
			} as NewWebScrape;
		}
	};

	return {
		getDocumentToText,
		getSpeechToText,
		getWebToText,
		getTranslateScpeech,
		videoConverterFunc,
		createPPTFunc,
	};
}
