import { Dispatch, SetStateAction, createContext, useEffect } from 'react';
import { DataFragment, Workflow, WorkflowStepTemplate, WorkflowTemplate } from '../models/models';
import useDisseminate from '../hooks/useDisseminate';

interface IDisseminateContext {
	loading: boolean;
	workflow: Workflow | undefined;
	workflowId: string;
	workflowTemplate: WorkflowTemplate | undefined;
	setWorkflowStep: Dispatch<SetStateAction<WorkflowStepTemplate | undefined>>;
	workflowStep?: WorkflowStepTemplate;
	setWorkflowTemplate: Dispatch<SetStateAction<WorkflowTemplate | undefined>>;
	eventWorkflowId: string[];
	setEventWorkflowId: Dispatch<SetStateAction<string[]>>;
	setWorkflowId: Dispatch<SetStateAction<string>>;
	allDataFragments: DataFragment[];
	shareKey?: string;
	inputWorkflowId: string | undefined;
	setRequestId: Dispatch<SetStateAction<string>>;
}

export const DisseminateContext = createContext<IDisseminateContext>({} as IDisseminateContext);

export const DisseminateProvider = ({
	children,
	inputWorkflowId,
	shareKey,
}: {
	children: any;
	inputWorkflowId?: string;
	shareKey?: string;
}) => {
	const {
		loading,
		workflow,
		workflowId,
		setWorkflowStep,
		workflowStep,
		workflowTemplate,
		eventWorkflowId,
		setWorkflowTemplate,
		setEventWorkflowId,
		setWorkflowId,
		allDataFragments,
		setRequestId,
	} = useDisseminate({ inputWorkflowId, shareKey });

	return (
		<DisseminateContext.Provider
			value={{
				loading,
				workflow,
				workflowId,
				workflowTemplate,
				setWorkflowTemplate,
				workflowStep,
				setWorkflowStep,
				eventWorkflowId,
				setEventWorkflowId,
				setWorkflowId,
				allDataFragments,
				shareKey,
				inputWorkflowId,
				setRequestId,
			}}>
			{children}
		</DisseminateContext.Provider>
	);
};
