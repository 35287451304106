import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import { ProtectedRoute } from './ProtectedRoute';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const EditWorkflow = lazy(() => import('../../pages/presentation/workflow/EditWorkflow'));
const CREATECOMPANY = lazy(() => import('../../pages/presentation/company/manageCompany'));
const AcceptClientId = lazy(() => import('../../pages/presentation/settings/AddClientToUserPage'));
const EditWorkflowTemplate = lazy(
	() => import('../../pages/presentation/workflow/EditWorkflowTemplate'),
);
const MISSIONBUILDER = lazy(() => import('../../pages/presentation/mission-builder/index'));
const PRESENT = lazy(() => import('../../pages/presentation/mission-builder/present/index'));
const MARKETINGDASHBOARD = lazy(() => import('../../pages/presentation/marketingDashboard/index'));
const NEWSDASHBOARD = lazy(() => import('../../pages/presentation/news-dashboard/index'));
const EVENTDASHBOARD = lazy(() => import('../../pages/presentation/event-dasboard/index'));
const ADDCOLLABORATORS = lazy(
	() => import('../../pages/presentation/mission-builder/AddCollaborators'),
);
const ContentRoutes = () => {
	return (
		<Routes>
			{contents.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			<Route path='workflow/:id' element={<ProtectedRoute component={<EditWorkflow />} />} />
			<Route
				path='workflow-template/:templateId/:workflowId'
				element={<ProtectedRoute component={<EditWorkflowTemplate />} />}
			/>
			<Route path='company/:id' element={<ProtectedRoute component={<CREATECOMPANY />} />} />
			<Route
				path='mission-builder/:id'
				element={<ProtectedRoute component={<MISSIONBUILDER />} />}
			/>
			<Route
				path='add-collaborators/:id'
				element={<ProtectedRoute component={<ADDCOLLABORATORS />} />}
			/>
			<Route path='present/:id' element={<PRESENT />} />
			<Route path='invite/:clientId/:code' element={<AcceptClientId />} />
			<Route path='*' element={<PAGE_404 />} />
			<Route path='marketing-dashboard' element={<MARKETINGDASHBOARD />} />
			<Route path='news-dashboard' element={<NEWSDASHBOARD />} />
			<Route path='event-dashboard/:activeTab' element={<EVENTDASHBOARD />} />
			<Route path='event-dashboard/:activeTab/:id' element={<EVENTDASHBOARD />} />
		</Routes>
	);
};

export default ContentRoutes;
