import { gql } from '@apollo/client';

export const CLIENT_CALL = gql`
	mutation ClientCall($userInput: JSON) {
		clientCall(userInput: $userInput)
	}
`;

export const DELETE_CLIENT = gql`
	mutation DeleteClient($id: String, $accessToken: String) {
		deleteClient(id: $id, accessToken: $accessToken)
	}
`;
