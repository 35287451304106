import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardSubTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import demoImg from '../../../assets/img/demo/bottom-shell-demo.png';
import Swal from 'sweetalert2';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { WorkflowContext } from '../../../contexts/WorkflowContext';
import { DataFragment, FieldData, WorkflowOutput } from '../../../models/models';
import { FaPlus } from 'react-icons/fa';
import xss from 'xss';
import { imageUrlToBase64, shorten, uniqueId } from '../../../util/util';
import { HLSVideoPlayerWithQuality } from '../../../components/hls-video-player';

const OutputCard = ({
	asset,
	output,
	setActivePanel,
	currentWorkflowOutput,
	setCurrentWorkflowOutput,
	setCurrentFileDropData,
	closeProcessingModal,
}: {
	asset: FieldData;
	output: WorkflowOutput;
	setActivePanel: React.Dispatch<
		React.SetStateAction<
			'card-view' | 'sandbox-view' | 'flowchart-view' | 'videoOutput' | 'document-view'
		>
	>;
	currentWorkflowOutput?: WorkflowOutput;
	setCurrentWorkflowOutput: (a: WorkflowOutput) => void;
	currentFileDropData?: { url: string; type: 'video' | 'image' | '' };
	setCurrentFileDropData: (a: { url: string; type: 'video' | 'image' | '' }) => void;
	closeProcessingModal?: () => void;
}) => {
	const [downloading, setDownloading] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const { deleteWorkflowOutput } = useContext(WorkflowContext);

	const download = async () => {
		if (!!output?.data?.videoUrl) {
			setDownloading(true);

			const response = await fetch(output?.data?.videoUrl);
			// Create a Blob object from the video data.
			const blob = await response.blob();
			// Create a download link.
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `${uniqueId()?.toLowerCase().replace(/\s/g, '_')}.mp4`;
			link.classList.add('hidden');
			// Append the download link to the document body.
			document.body.appendChild(link);
			// Click on the download link.
			link.click();
			// Remove the download link from the document body.
			document.body.removeChild(link);
			setDownloading(false);
		}
	};
	useEffect(() => {
		if (closeProcessingModal) {
			closeProcessingModal();
		}
	}, []);
	const deleteCurrentOutput = async () => {
		await setDeleting(true);
		const result = await Swal.fire({
			title: 'Confirmation!',
			text: 'Are you sure, you want to delete this workflow output?',
			allowOutsideClick: true,
			showCancelButton: true,
			confirmButtonText: 'Okay',
			cancelButtonColor: '#38cab3',
			confirmButtonColor: 'rgba(0, 56, 255, 1)',
		});
		if (result?.isConfirmed) {
			await deleteWorkflowOutput(output);
		}
		await setDeleting(false);
	};
	const attachmentUrl = asset?.attachmentUrl;
	const imageUrl = (asset?.imageUrl as any)?.url;

	return (
		<div
			className={`row-item position-relative card img-card black-card mb-3 ${
				currentWorkflowOutput?.id === output?.id ? 'border-primary' : ''
			}`}
			onClick={() => {
				// if (output?.outputType === "visual") {
				//     setActivePanel("videoOutput");
				//     setCurrentWorkflowOutput(output);
				// } else
				if (output?.outputType === 'whiteboard') {
					setActivePanel('sandbox-view');
					setCurrentWorkflowOutput(output);
				}
			}}>
			<div className='card-head-section  px-3 pb-2 pt-3'>
				<div className='d-flex justify-content-between align-items-center'>
					<div
						className='mt-1'
						dangerouslySetInnerHTML={{
							__html: shorten(xss(asset?.text ?? ''), 200),
						}}></div>
					<CardActions>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button
									// color={darkModeStatus ? 'light' : 'dark'}
									isLink
									className='p-0'
									hoverShadow='default'
									icon='MoreHoriz'
									aria-label='More Actions'
								/>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd>
								<DropdownItem>
									<Button
										icon='RemoveRedEye'
										onClick={() => {
											if (output?.outputType === 'visual') {
												setActivePanel('videoOutput');
											} else if (output?.outputType === 'whiteboard') {
												setActivePanel('sandbox-view');
											}
											setCurrentWorkflowOutput(output);
										}}>
										View
									</Button>
								</DropdownItem>
								<DropdownItem>
									<Button
										icon={downloading ? 'Downloading' : 'Download'}
										onClick={download}
										isDisable={downloading}>
										{/* {sendingToAll ? <Spinner isSmall inButton /> : */}
										{downloading ? 'Downloading...' : 'Download'}
									</Button>
								</DropdownItem>
								<DropdownItem>
									<Button
										icon={downloading ? 'Deleteing' : 'Delete'}
										isDisable={deleting}
										onClick={() => deleteCurrentOutput()}>
										{deleting ? 'Deleting...' : 'Delete'}
									</Button>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</CardActions>
				</div>
			</div>
			<div className='card-body-section'>
				{!!attachmentUrl ? (
					<div
						className='custom-video-player'
						draggable
						onDragStart={(e) => {
							setCurrentFileDropData({ url: attachmentUrl, type: 'video' });
						}}>
						<HLSVideoPlayerWithQuality
							className='react-player'
							url={asset?.attachmentUrl}
							width={'-webkit-fill-available'}
							style={{ maxWidth: '100%', objectFit: 'contain' }}
							height={'-webkit-fill-available'}
							light={asset?.imageUrl}
						/>
					</div>
				) : !!imageUrl ? (
					<img
						src={imageUrl}
						alt='img'
						onDragStart={(e) => {
							setCurrentFileDropData({ url: imageUrl, type: 'image' });
						}}
						loading='lazy'
						onClick={() => window.open(asset?.imageUrl, '_blank')}
						className='img-fluid objectfit-contain pt-5 pb-2 px-5'
						style={{ height: '16vh' }}
					/>
				) : (
					<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
						<FaPlus size={75} color='#71788B' className='mt-5' />
					</div>
				)}
			</div>
		</div>
	);
};

interface OutputPanelProps {
	setActivePanel: Dispatch<
		SetStateAction<
			'card-view' | 'sandbox-view' | 'flowchart-view' | 'videoOutput' | 'document-view'
		>
	>;
	activePanel?: 'card-view' | 'sandbox-view' | 'flowchart-view' | 'videoOutput' | 'document-view';
	activeFilterAssetsPanel: string;
	currentWorkflowOutput?: WorkflowOutput;
	setCurrentWorkflowOutput: (a: WorkflowOutput) => void;
	currentFileDropData?: { url: string; type: 'video' | 'image' | '' };
	setCurrentFileDropData: (a: { url: string; type: 'video' | 'image' | '' }) => void;
	closeProcessingModal: () => void;
}

function OutputPanel({
	activePanel,
	setCurrentWorkflowOutput,
	activeFilterAssetsPanel,
	currentWorkflowOutput,
	setActivePanel,
	currentFileDropData,
	setCurrentFileDropData,
	closeProcessingModal,
}: OutputPanelProps) {
	const { workflow, workflowOutput } = useContext(WorkflowContext);
	return (
		<>
			{/* <div className='data-grid d-flex justify-content-between align-items-start flex-wrap'> */}
			{workflowOutput
				?.filter((i) => i?.outputType == 'visual' || i?.outputType === 'whiteboard')
				?.sort((a, b) => (parseFloat(a?.id) > parseFloat(b?.id) ? -1 : 1))
				?.map((e, index) => (
					<>
						{activeFilterAssetsPanel == 'all' ||
						(activeFilterAssetsPanel == 'Videos' && !!e?.data?.videoUrl) ||
						(activeFilterAssetsPanel == 'Photos' &&
							!!e?.data?.thumbnailUrl &&
							!e?.data?.tldrawSnapshot &&
							!e?.data?.videoUrl) ||
						(activeFilterAssetsPanel == 'Documents' && !!e?.data?.tldrawSnapshot) ? (
							<OutputCard
								key={index}
								setActivePanel={setActivePanel}
								setCurrentWorkflowOutput={setCurrentWorkflowOutput}
								currentWorkflowOutput={currentWorkflowOutput}
								output={e}
								asset={{
									text: e?.name ?? `Output-${index + 1}`,
									id: '',
									datalakeType: '',
									webPageUrl: '',
									audioUrl: '',
									attachmentUrl: !!e?.data?.videoUrl ? e?.data?.videoUrl : '',
									imageUrl: !!e?.data?.thumbnailUrl
										? e?.data?.thumbnailUrl
										: demoImg,
									stepName: '',
									attachmentType: '',
								}}
								currentFileDropData={currentFileDropData}
								setCurrentFileDropData={setCurrentFileDropData}
								closeProcessingModal={closeProcessingModal}
							/>
						) : (
							<></>
						)}
					</>
				))}
			{/* </div> */}
		</>
	);
}

export default OutputPanel;
