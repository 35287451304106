import { Dispatch, SetStateAction, createContext } from 'react';
import useClient from '../hooks/useClients';
import { Client, MissionHierarchySettings, SendgridApi } from '../models/models';
import { EntraIdUser } from '../Azure/models';

interface IClientContext {
	clients: Client[];
	activeClient: Client | undefined;
	activeClientId: string;
	setActiveClientId: Dispatch<SetStateAction<string>>;
	saveClient: (client: Client) => Promise<any>;
	inviteMembers: (users: EntraIdUser[]) => Promise<any>;
	verifyClientIdToMember: (
		clientId: string,
		secretCode: string,
	) => Promise<
		| false
		| Client
		| {
				msg: string;
		  }
	>;
	removeClient: () => void;
	createNewClient: () => Promise<any>;
	fetchSendgridApiKeys: () => Promise<SendgridApi[]>;
	addSendgridApiKey: (apiKey: string, emails: string[]) => Promise<SendgridApi[]>;
	removeSendgridApiKey: (id: string) => Promise<SendgridApi[]>;
	makeDefaultSendgridApiKey: (id: string) => Promise<SendgridApi[]>;
	addSendgridApiKeyEmail: (id: string, emails: string[]) => Promise<SendgridApi[]>;
	removeSendgridApiKeyEmail: (id: string, emails: string[]) => Promise<SendgridApi[]>;
	makeDefaultSendgridApiKeyEmail: (id: string, emails: string[]) => Promise<SendgridApi[]>;
	setActiveClient: Dispatch<SetStateAction<Client | undefined>>;
	deleteWorkspace: (clientId: string) => Promise<any>;
	missionHierarchySettings: MissionHierarchySettings | null;
	updateMissionHierarchySettingsFunc: (data: Partial<MissionHierarchySettings>) => void;
}

export const ClientContext = createContext<IClientContext>({} as IClientContext);

export const ClientProvider = ({ children }: { children: any }) => {
	const {
		clients,
		activeClient,
		activeClientId,
		setActiveClientId,
		saveClient,
		inviteMembers,
		verifyClientIdToMember,
		removeClient,
		createNewClient,
		fetchSendgridApiKeys,
		addSendgridApiKey,
		removeSendgridApiKey,
		makeDefaultSendgridApiKey,
		addSendgridApiKeyEmail,
		removeSendgridApiKeyEmail,
		makeDefaultSendgridApiKeyEmail,
		setActiveClient,
		deleteWorkspace,
		missionHierarchySettings,
		updateMissionHierarchySettingsFunc,
	} = useClient();

	return (
		<ClientContext.Provider
			value={{
				clients,
				activeClient,
				activeClientId,
				setActiveClientId,
				saveClient,
				inviteMembers,
				verifyClientIdToMember,
				removeClient,
				createNewClient,
				fetchSendgridApiKeys,
				addSendgridApiKey,
				removeSendgridApiKey,
				makeDefaultSendgridApiKey,
				addSendgridApiKeyEmail,
				removeSendgridApiKeyEmail,
				makeDefaultSendgridApiKeyEmail,
				setActiveClient,
				deleteWorkspace,
				missionHierarchySettings,
				updateMissionHierarchySettingsFunc,
			}}>
			{children}
		</ClientContext.Provider>
	);
};
