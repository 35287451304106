import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import Swal from 'sweetalert2';

const AudioTranscriber = ({ uploadAudio }: { uploadAudio: (blob: Blob) => Promise<void> }) => {
	const recorderControls = useAudioRecorder(
		{
			noiseSuppression: true,
			echoCancellation: true,
		},
		(err) =>
			Swal.fire({
				title: 'Microphone Not found!',
				text: 'Please check your microphone conected and try again',
				allowOutsideClick: true,
				confirmButtonText: 'Okay',
				cancelButtonColor: '#38cab3',
				confirmButtonColor: 'rgba(0, 56, 255, 1)',
			}),
	);
	const handleRecordingComplete = async (blob: Blob) => {
		await uploadAudio(blob);
	};
	return (
		<div className='d-flex justify-content-center py-5 my-4'>
			{!recorderControls?.isRecording && (
				<span className='fs-4 mt-2 px-2'>Click the icon to start Recording</span>
			)}
			<AudioRecorder
				onRecordingComplete={(blob) => handleRecordingComplete(blob)}
				recorderControls={recorderControls}
				// classes={{ AudioRecorderClass: 'fs-2' }}
				audioTrackConstraints={{
					noiseSuppression: true,
					echoCancellation: true,
				}}
				onNotAllowedOrFound={() =>
					Swal.fire({
						title: 'Microphone Not found!',
						text: 'Please check your microphone conected and try again',
						allowOutsideClick: true,
						confirmButtonText: 'Okay',
						cancelButtonColor: '#38cab3',
						confirmButtonColor: 'rgba(0, 56, 255, 1)',
					})
				}
				downloadOnSavePress={false}
				downloadFileExtension='webm'
				mediaRecorderOptions={{
					audioBitsPerSecond: 128000,
				}}
				showVisualizer={true}
			/>
		</div>
	);
};
export default AudioTranscriber;
