import React, { useRef, useEffect, useState, Dispatch, SetStateAction } from 'react';
import Hls, { Level } from 'hls.js';
import Plyr from 'plyr';
import ReactPlayer from 'react-player/lazy';

export const HLSVideoPlayerWithQuality = ({
	url,
	height,
	width,
	style,
	className,
	light,
	fullscreen,
	isPlaying,
	setIsPlaying,
}: {
	url?: string;
	width: any;
	style: any;
	height: any;
	className?: string;
	light?: string;
	fullscreen?: boolean;
	isPlaying?: boolean;
	setIsPlaying?: Dispatch<SetStateAction<boolean>>;
}) => {
	const videoRef = useRef<any>(null);
	const hlsRef = useRef<any>(null);
	const playerRef = useRef<any>(null);

	useEffect(() => {
		if ((url ?? '')?.includes('.m3u8')) {
			const defaultOptions: any = {};
			if (Hls.isSupported()) {
				const hls = new Hls();
				hls.loadSource(url ?? '');
				hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
					const availableQualities = hls.levels.map((l) => l.height);
					defaultOptions.controls = [
						'play-large',
						// 'restart',
						// 'rewind',
						// 'play',
						// 'fast-forward',
						// 'progress',
						// 'current-time',
						// 'duration',
						// 'mute',
						// 'volume',
						// 'captions',
						// 'settings',
						// 'pip',
						// 'airplay',
						// 'download',
						'fullscreen',
					];
					defaultOptions.quality = {
						default: availableQualities[0],
						options: availableQualities,
						forced: true,
						onChange: (e: any) => updateQuality(e),
					};

					const player = new Plyr(videoRef.current, defaultOptions);
					playerRef.current = player;
					player.on('play', () => {
						if (fullscreen) {
							player.fullscreen.enter();
						}
						if (setIsPlaying) setIsPlaying(true);
					});
					player.on('pause', () => {
						if (setIsPlaying) setIsPlaying(false);
					});

					player.on('exitfullscreen', () => {
						if (setIsPlaying) setIsPlaying(false);
					});
				});
				hls.attachMedia(videoRef.current);
				hlsRef.current = hls;
			} else {
				const player = new Plyr(videoRef.current, defaultOptions);
				playerRef.current = player;
				player.on('play', () => {
					if (fullscreen) {
						player.fullscreen.enter();
					}
					if (setIsPlaying) setIsPlaying(true);
				});
				player.on('pause', () => {
					if (setIsPlaying) setIsPlaying(false);
				});

				player.on('exitfullscreen', () => {
					if (setIsPlaying) setIsPlaying(false);
				});
			}

			function updateQuality(newQuality: any) {
				hlsRef.current.levels.forEach((level: any, levelIndex: any) => {
					if (level.height === newQuality) {
						console.log('Found quality match with ' + newQuality);
						hlsRef.current.currentLevel = levelIndex;
					}
				});
			}
		}
	}, [fullscreen, setIsPlaying, url]);

	useEffect(() => {
		if (playerRef.current) {
			if (isPlaying) {
				playerRef.current.play();
			} else {
				playerRef.current.pause();
			}
		}
	}, [isPlaying]);
	if ((url ?? '')?.includes('.m3u8')) {
		return (
			<video
				ref={videoRef}
				controls={true}
				height={height}
				width={width}
				style={style}
				autoPlay={false}
				controlsList='nodownload nodirections noremoteplayback'
				className={className ?? ''}
			/>
		);
	} else {
		return (
			<ReactPlayer
				controls
				className={className ?? ''}
				url={url}
				width={width}
				style={style ?? {}}
				height={height}
				playing={false}
				light={
					!!light ? (
						<img
							style={{ width: '100%', objectFit: 'contain' }}
							src={light}
							loading='lazy'
							alt='Thumbnail'
						/>
					) : (
						false
					)
				}
			/>
		);
	}
};

export const HLSVideoPlayerForTLDraw = ({
	url,
	height,
	width,
	style,
	className,
	sandbox,
}: {
	url?: string;
	width: any;
	style: any;
	height: any;
	className?: string;
	sandbox: any;
}) => {
	if (!!url && !!url?.includes('.m3u8')) {
		return (
			<ReactPlayer
				url={url}
				controls={true}
				height={height}
				width={width}
				style={style}
				playing={false}
				className={className ?? ''}
			/>
		);
	} else if (!!url) {
		return (
			<iframe
				className='tl-embed'
				sandbox={sandbox}
				src={url}
				width={width}
				height={height}
				draggable={false}
				referrerPolicy='no-referrer-when-downgrade'
				style={style}
			/>
		);
	} else {
		return <></>;
	}
};
