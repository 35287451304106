import { gql } from '@apollo/client';

export const GET_WORKFLOW = gql`
	query Query($id: ID!, $accessToken: String) {
		workflow(id: $id, accessToken: $accessToken)
	}
`;

export const GET_WORKFLOW_PPT = gql`
	query Query($id: ID!, $accessToken: String) {
		workflowPPT(id: $id, accessToken: $accessToken)
	}
`;

export const GET_WORKFLOW_PRESENTATION = gql`
	query Query($id: ID!, $accessToken: String) {
		workflowPresentation(id: $id, accessToken: $accessToken)
	}
`;

export const GET_WORKFLOW_PRESENTATION_OUTPUTS = gql`
	query Query($id: ID!, $accessToken: String) {
		workflowPresentationOutput(id: $id, accessToken: $accessToken)
	}
`;

export const GET_WORKFLOW_PRESENTATION_OBJECTS = gql`
	query Query($id: ID!, $accessToken: String) {
		workflowPresentationObjects(id: $id, accessToken: $accessToken)
	}
`;

export const GET_WORKFLOWS_BY_CLIENTID = gql`
	query Query($clientId: String, $level1: String, $level2: String, $level3: String, $pageNumber: Int, $limit: Int, $accessToken: String) {
		getWorkflows(clientId: $clientId, level1: $level1, level2: $level2, level3: $level3, pageNumber: $pageNumber, limit: $limit, accessToken: $accessToken)
	}
`;

export const GET_CAMPAIGNS_BY_CLIENTID = gql`
	query Query($clientId: String, $accessToken: String) {
		getCampaigns(clientId: $clientId, accessToken: $accessToken)
	}
`;

export const GET_WORKFLOW_OUTPUT = gql`
	query Query($id: ID!, $accessToken: String) {
		getWorkflowOutput(id: $id, accessToken: $accessToken)
	}
`;

export const GET_WORKFLOW_OUTPUTS = gql`
	query Query($id: ID!, $accessToken: String) {
		getWorkflowOutputs(id: $id, accessToken: $accessToken)
	}
`;

export const GET_DISSEMINATE_WORKFLOW = gql`
	query Query($id: ID!, $shareKey: String) {
		disseminateWorkflow(id: $id, shareKey: $shareKey)
	}
`;