import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { CardActions, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Dropzone from 'react-dropzone';
import useDarkMode from '../../../hooks/useDarkMode';
import classNames from 'classnames';
import useImageUpload from '../../../helpers/useImageUpload';
import { FaPlus } from 'react-icons/fa';
import { FormLabel, Modal } from 'react-bootstrap';
import BootButton from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import { DATAFRAGMENT_TYPE, Images, Product } from '../../../models/models';
interface Props {
	fragmentName: string;
	images: Images[];
	setImages: Dispatch<SetStateAction<Images[]>>;
	selectedType: DATAFRAGMENT_TYPE;
}
const FragmentImagePicker = ({
	fragmentName: fragmentName,
	selectedType,
	images,
	setImages,
}: Props) => {
	const { themeStatus } = useDarkMode();
	const { uploadFileToBlobStorage } = useImageUpload();
	const [uploadingImage, setUploadingImage] = useState(false);

	async function handleAcceptedFiles(files: any) {
		setUploadingImage(true);
		const galleryItems: Images[] = [];
		for (let file of files) {
			const result = await uploadFileToBlobStorage({
				file,
			});
			if (result?.url?.length > 0) {
				galleryItems.push({ url: result.url, type: 'image' });
			}
		}
		setImages([...images, ...galleryItems]);
		setUploadingImage(false);
	}

	const handleRemoveFragmentImage = (img: Images) => {
		setImages([...images.filter((i) => i.url !== img.url)]);
	};
	const Drop = () => {
		function formatBytes(bytes: any, decimals = 2) {
			if (bytes === 0) return '0 Bytes';
			const k = 1024;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

			const i = Math.floor(Math.log(bytes) / Math.log(k));
			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		}

		return (
			<React.Fragment>
				<Dropzone
					onDrop={(acceptedFiles) => {
						handleAcceptedFiles(acceptedFiles);
					}}>
					{({ getRootProps, getInputProps }) => (
						<div
							className={`col-xxl-${3} col-lg-${3} col-md-${3} mt-0`}
							{...getRootProps()}>
							<button
								type='button'
								disabled={uploadingImage}
								className={classNames(
									'position-relative',
									'ratio ratio-1x1',
									'rounded-2',
									'border-0',
									'bg-dark',
									'text-white',
								)}>
								<div className='cursor-pointer position-absolute'>
									<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
										{uploadingImage ? (
											<Spinner size={75} />
										) : (
											<FaPlus size={75} color='#71788B' className='mt-5' />
										)}
									</div>
									<div className='px-3 pb-4'>
										<p className='shop-description text-white fs-4 mb-0 mt-2 px-2'>
											{uploadingImage ? `Uploading...` : `Add`}
										</p>
									</div>
								</div>
							</button>
						</div>
					)}
				</Dropzone>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<div className='row'>
				<div className='col-12'>
					<CardHeader className='p-0'>
						<CardLabel icon='Image' className='mb-3' iconColor='success'>
							<CardTitle>Images</CardTitle>
						</CardLabel>
						{/* <Icon
							icon='Grid View'
							size={'2x'}
							className='cursor-pointer'
							onClick={() => modalProductImageGrid.show()}
						/> */}
					</CardHeader>
					<div className='mb-3'>
						<FormLabel className='mb-3'>Add images(s)</FormLabel>
						<div className='row g-2'>
							{images?.map((item, index) => (
								<div
									key={index}
									className={`col-xxl-${3} col-lg-${3} col-md-${3} mt-0`}>
									<button
										type='button'
										className={classNames(
											'position-relative',
											'ratio ratio-1x1',
											'rounded-2',
											'border-0',
										)}>
										<div className='d-flex justify-content-end'>
											<div
												className='position-relative bg-primary'
												style={{
													top: 5,
													zIndex: 5,
													right: 20,
													borderRadius: '50%',
													width: 20,
													height: 20,
												}}
												key='actions'>
												<CardActions>
													<BootButton
														style={{ borderRadius: 10 }}
														icon='Delete'
														color={themeStatus}
														aria-label='Delete'
														className='p-0'
														onClick={() => {
															handleRemoveFragmentImage(item);
														}}
													/>
												</CardActions>{' '}
											</div>
										</div>
										<img
											src={item?.url}
											alt={'image'}
											loading='lazy'
											style={{ width: '100%', height: '100%' }}
											className='object-fit-cover p-0 rounded-2'
										/>
									</button>
								</div>
							))}
							<Drop />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default FragmentImagePicker;
