import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from './bootstrap/OffCanvas';
import Alert from './bootstrap/Alert';
import { NotificationsContext } from '../contexts/NotificationContext';
import { Notification } from '../models/models';
import striptags from 'striptags';
import moment from 'moment';
import Progress from './bootstrap/Progress';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
	offcanvasStatus: boolean;
	setOffcanvasStatus: Dispatch<SetStateAction<boolean>>;
}

function NotificationCanvas({ offcanvasStatus, setOffcanvasStatus }: Props) {
	const navigate = useNavigate();
	const { notifications, readNotification } = useContext(NotificationsContext);

	const clickNotification = async (item: Notification) => {
		readNotification(item?.id);
		if (!!item?.url) {
			window.open(item?.url, '_blank');
		}
	};

	const closeNotification = async (item: Notification) => {
		await readNotification(item?.id);
	};

	return (
		<OffCanvas
			id='notificationCanvas'
			titleId='offcanvasExampleLabel'
			placement='end'
			isOpen={offcanvasStatus}
			setOpen={setOffcanvasStatus}
			style={{ zIndex: 999999999 }}>
			<OffCanvasHeader setOpen={setOffcanvasStatus}>
				<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody>
				{(notifications ?? []).map((item, index) => (
					<Alert
						key={index}
						icon={item?.icon}
						isLight
						onClose={() => closeNotification(item)}
						isDismissible
						color={item?.iconType ?? 'info'}
						className='flex-nowrap '>
						<span
							key={index}
							className='cursor-pointer'
							onClick={() => clickNotification(item)}>
							{striptags(item?.progressMessage ?? item?.message)}
							{typeof item?.processedRequestCount == 'number' &&
							typeof item?.totalRequestCount == 'number' &&
							(item?.processedRequestCount / item?.totalRequestCount) * 100 < 100 ? (
								<Progress
									value={
										!!item?.processedRequestCount
											? (item?.processedRequestCount /
													item?.totalRequestCount) *
											  100
											: 5
									}
									max={100}
									color='primary'
								/>
							) : typeof item?.processedRequestCount == 'number' &&
							  typeof item?.totalRequestCount == 'number' &&
							  item?.processedRequestCount == item?.totalRequestCount ? (
								<div>Done</div>
							) : (
								<></>
							)}
							<p className='mb-0 text-muted' style={{ fontSize: 11 }}>
								{moment(item.lastUpdated).fromNow()}
							</p>
						</span>
					</Alert>
				))}
				{/* <Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
							4 new workflows added
						</Alert>
						<Alert icon='ThumbUp' isLight color='red' className='flex-nowrap'>
							New module added.
						</Alert>
						<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
							Your collaborator responded
						</Alert>
						<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
							Collaboration request sent for Thought Leadership Post
						</Alert>
						<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
							New Team Member joined!
						</Alert> */}
			</OffCanvasBody>
		</OffCanvas>
	);
}

export default NotificationCanvas;
