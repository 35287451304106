import { gql } from "@apollo/client";

export const GET_MISSION_HIERARCH_SETTINGS = gql`
    query Query($clientId: String, $accessToken: String) {
        getMissionHierarchySettings(clientId: $clientId, accessToken: $accessToken)
    }
`;

export const UPDATE_MISSION_HIERARCH_SETTINGS = gql`
    mutation Mutation($userInput: JSON, $accessToken: String) {
        updateMissionHierarchySettings(userInput: $userInput, accessToken: $accessToken)
    }
`;