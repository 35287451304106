import React, { FC, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import Spinner from '../../../components/bootstrap/Spinner';
import { dashboardPagesMenu } from '../../../menu';
import { getMsalInstance } from '../../../Azure/login';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_MUTATION } from '../../../GraphQL/user/mutation';
import { UserContext } from '../../../contexts/UserContext';

interface ILoginProps {
	isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const navigate = useNavigate();
	const [updateUser, { error }] = useMutation(UPDATE_USER_MUTATION);
	const { user, redirectUrl, setUser } = useContext(UserContext);

	const Login = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		var loginRequest = {
			scopes: ['user.read', 'mail.send'], // optional Array<string>
		};
		try {
			const msalInstance = await getMsalInstance();
			const result = await msalInstance.loginPopup(loginRequest);
			if (!!result?.uniqueId) {
				setUser(result);
				await navigate(redirectUrl ?? '/');
				await updateUser({
					variables: {
						userInput: {
							id: result?.uniqueId,
							userId: result?.uniqueId,
						},
						accessToken: result?.accessToken,
					},
				});
			} else {
				console.log({ result });
			}
		} catch (e) {
			console.log({ e });
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (!!user?.uniqueId) {
			handleOnClick();
		}
	}, [user?.uniqueId]);

	const handleOnClick = async () => {
		if (!user?.uniqueId) {
			throw new Error('User not found');
		} else {
			if (!!redirectUrl) {
				navigate(redirectUrl);
			} else {
				navigate(dashboardPagesMenu.workflows.path);
			}
		}
	};

	const { darkModeStatus } = useDarkMode();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	return (
		<PageWrapper
			isProtected={false}
			title={isSignUp ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !isSignUp, 'bg-light': isSignUp })}>
			<Page className='p-0'>
				<div
					className='row align-items-center justify-content-center'
					style={{ height: '100vh' }}>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-4'>
									<Link
										to={dashboardPagesMenu.workflows.path}
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={200} color='light' />
									</Link>
								</div>
								<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
								<div className='text-center h4 text-muted mb-5'>
									Login to continue!
								</div>
								<div className='col-12'>
									<Button color='warning' className='w-100 py-3' onClick={Login}>
										{isLoading ? <Spinner /> : 'Login'}
									</Button>
								</div>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href={'/'}
								className={classNames('text-decoration-none me-3', {
									'link-light': isSignUp,
									'link-dark': !isSignUp,
								})}>
								Privacy policy
							</a>
							<a
								href={'/'}
								className={classNames('link-light text-decoration-none', {
									'link-light': isSignUp,
									'link-dark': !isSignUp,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
