import { Routes, Route, NavLink, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { authMenu } from '../../menu';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';

export const ProtectedRoute = ({ component }: { component: any }) => {
	const { user, setRedirectUrl } = useContext(UserContext);
	const location = useLocation();

	if (!user?.uniqueId) {
		setRedirectUrl(location.pathname);
		return <Navigate to={authMenu.login.path} replace />;
	}

	return component;
};
