import React, { useContext } from 'react';
import Avatar, { AvatarGroup } from '../../../components/Avatar';
import useDarkMode from '../../../hooks/useDarkMode';
import { DataFragment } from '../../../models/models';
import { shorten } from '../../../util/util';
import { ClientContext } from '../../../contexts/ClientContext';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import userDarkModeImg from '../../../assets/img/ai/user-dark-mode.png';
import userLightModeImg from '../../../assets/img/ai/user-light-mode.png';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import { useModal } from '@ebay/nice-modal-react';
import { Modals } from '../../../components/Modals';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { CardActions } from '../../../components/bootstrap/Card';
import { MissionBuilderAttachmentData } from '../mission-builder/step-modal/AttatchmentsComponent';
import { VideoView } from '../mission-builder/MissionAssetsPanel';

const ImageGridCard = ({
	dataFragment,
	missionBuilderData,
}: {
	dataFragment: DataFragment;
	missionBuilderData?: MissionBuilderAttachmentData;
}) => {
	const {
		setFragmentId: setProductId,
		setSelectedFragmentType,
		deleteDataFragment,
	} = useContext(DatalakeContext);
	const datalakeModal = useModal(Modals.DATALAKE);
	const { activeClient } = useContext(ClientContext);
	const { darkModeStatus } = useDarkMode();

	const [userProfile] =
		activeClient?.team?.filter((t) => t?.sender == dataFragment?.userId) || [];
	return (
		<div className='row-item position-relative card white-card mb-3'>
			<div className='card-head-section px-3 py-2'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='mt-1'>
						{!!dataFragment?.name && (
							<p className='uppercase mb-0 card-title-text'>
								{shorten(dataFragment?.name, 30)}
							</p>
						)}
						{!dataFragment?.name && (
							<p className='mb-0  small-sub-title uppercase-title'>
								{dataFragment?.type}
							</p>
						)}
					</div>
					<CardActions>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button
									icon='MoreHoriz'
									// color={themeStatus}
									shadow='default'
									aria-label='Edit'
									className='p-0'
								/>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd style={{ top: '0', right: 0 }}>
								<DropdownItem>
									<Button
										icon='Visibility'
										onClick={() => {
											setProductId(dataFragment?.id);
											if (!!missionBuilderData) {
												missionBuilderData.setShowPopup(true);
											} else {
												datalakeModal.show();
											}
										}}>
										View
									</Button>
								</DropdownItem>
								<DropdownItem isDivider />
								<DropdownItem>
									<Button
										icon='Delete'
										onClick={() => {
											if (!!missionBuilderData) {
												missionBuilderData?.deleteitem();
											} else {
												deleteDataFragment(dataFragment);
											}
										}}>
										Delete
									</Button>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</CardActions>
				</div>
			</div>
			{!!dataFragment?.videos?.length ? (
				<VideoView
					asset={dataFragment}
					setCurrentFileDropData={() => {}}
					currentFileDropData={{ url: '', type: '' }}
				/>
			) : (
				<>
					{dataFragment?.images ? (
						<ImageGallery
							items={dataFragment?.images?.map((image) => ({
								original: image?.url,
								thumbnail: image?.url,
							}))}
							showPlayButton={false}
							showBullets={true}
							showThumbnails={false}
							autoPlay={true}
						/>
					) : (
						<div>Loading images...</div>
					)}
				</>
			)}
		</div>
	);
};

export default ImageGridCard;
