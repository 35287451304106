import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { TourProvider } from '@reactour/tour';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ToastContainer } from 'react-toastify';
import ThemeContext from '../contexts/ThemeContext';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import { ToastCloseButton } from '../components/bootstrap/Toasts';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import { useLocation } from 'react-router-dom';
import { UserProvider } from '../contexts/UserContext';
import { CampaignsProvider } from '../contexts/CampaignsContext';
import { CompanyProvider } from '../contexts/CompanyContext';
import NiceModal from '@ebay/nice-modal-react';
import { ModuleProvider } from '../contexts/ModuleContext';
import { DatalakeProvider } from '../contexts/DatalakeContext';
import { NotificationsProvider } from '../contexts/NotificationContext';
import { ClientProvider } from '../contexts/ClientContext';
import { ChatCollaboratorProvider } from '../contexts/ChatCollaboratorContext';
import { CalendarProvider } from '../contexts/CalendarContext';
import { AzureConfig } from '../Azure/azure-constants';
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	HttpLink,
	from,
	FieldPolicy,
	FieldFunctionOptions,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GetAllUsersProvider } from '../contexts/getAllUsersContext';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights, reactPlugin } from '../Analytics/AppAnalytics';
import { DisseminateProvider } from '../contexts/DisseminateContext';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-9CZL654WBY');

const App = () => {
	getOS();
	dayjs.extend(localizedFormat);
	dayjs.extend(relativeTime);
	const location = useLocation();
	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			graphQLErrors.map(({ message }) => {
				console.log({ graphQLErrors });
			});
		}
		if (networkError) {
			console.log({ networkError });
		}
	});

	const cache = new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					getPeople: {
						keyArgs: ['searchText'], // Cache based on searchText
						merge(existing, incoming, { args }) {
							// Append new data if offset/limit changes, replace if searchText changes
							if (existing && args) {
								return [...existing, ...incoming];
							}
							return incoming; // New search, create new list
						},
					},
				},
			},
		},
	});

	const link = from([
		errorLink,
		// new HttpLink({uri: "https://mb-stg-z8cs4.azurewebsites.net/api/src"})
		new HttpLink({ uri: 'https://api.missionbuilder.multiplied.ai/api/src' }),
		// new HttpLink({uri: "http://localhost:4000/graphql"})
		// new HttpLink({uri: "https://mb-z8cs4.azurewebsites.net/api/src/graphql"})
	]);

	const client = new ApolloClient({
		cache,
		link,
	});

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
			document.documentElement.setAttribute('data-bs-theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
			document.documentElement.removeAttribute('data-bs-theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});
	useEffect(() => {
		const pageName = !!location?.pathname?.split('/')?.[1]
			? location?.pathname?.split('/')?.[1]
			: 'Home Page';
		appInsights.trackPageView({ name: pageName, uri: location?.pathname });
	}, [location?.pathname]);

	const shareKey = location?.search
		?.split('?')?.[1]
		?.split('&')
		?.find((i) => i?.includes('key='))
		?.replace('key=', '');
	const url = location?.pathname;
	const idPattern = /(?:disseminate\/(?:[^/]+\/)?)([a-f0-9-]{36})/;
	const workflowId = url?.match(idPattern)?.[1] || '';

	if (location?.pathname?.includes('/disseminate/')) {
		return (
			<ThemeProvider theme={theme}>
				<NiceModal.Provider>
					<ApolloProvider client={client}>
						<DisseminateProvider inputWorkflowId={workflowId} shareKey={shareKey}>
							<Provider store={store}>
								<TourProvider
									steps={steps}
									styles={styles}
									showNavigation={false}
									showBadge={false}>
									<div
										ref={ref}
										className='app'
										style={{
											backgroundColor: fullScreenStatus
												? 'var(--bs-body-bg)'
												: undefined,
											zIndex: fullScreenStatus ? 1 : undefined,
											overflow: fullScreenStatus ? 'scroll' : undefined,
										}}>
										{/* <AsideRoutes /> */}
										<script src='PptxGenJS/dist/pptxgen.bundle.js'></script>
										<script src='PptxGenJS/libs/jszip.min.js'></script>
										<script src='PptxGenJS/dist/pptxgen.min.js'></script>
										<Wrapper wrapperType={'disseminate'} />
									</div>
									<link
										rel='stylesheet'
										href='https://cdn.plyr.io/3.7.2/plyr.css'
									/>
									<Portal id='portal-notification'>
										<ReactNotifications />
									</Portal>
									<ToastContainer
										closeButton={ToastCloseButton}
										toastClassName='toast show'
									/>
								</TourProvider>
							</Provider>
						</DisseminateProvider>
					</ApolloProvider>
				</NiceModal.Provider>
			</ThemeProvider>
		);
	} else {
		return (
			<ThemeProvider theme={theme}>
				<NiceModal.Provider>
					<ApolloProvider client={client}>
						<UserProvider>
							<GetAllUsersProvider>
								<ClientProvider>
									<ChatCollaboratorProvider>
										<CompanyProvider>
											<CampaignsProvider>
												<CalendarProvider>
													<ModuleProvider>
														<DatalakeProvider>
															<NotificationsProvider>
																<Provider store={store}>
																	<TourProvider
																		steps={steps}
																		styles={styles}
																		showNavigation={false}
																		showBadge={false}>
																		<div
																			ref={ref}
																			className='app'
																			style={{
																				backgroundColor:
																					fullScreenStatus
																						? 'var(--bs-body-bg)'
																						: undefined,
																				zIndex: fullScreenStatus
																					? 1
																					: undefined,
																				overflow:
																					fullScreenStatus
																						? 'scroll'
																						: undefined,
																			}}>
																			{/* <AsideRoutes /> */}
																			<script src='PptxGenJS/dist/pptxgen.bundle.js'></script>
																			<script src='PptxGenJS/libs/jszip.min.js'></script>
																			<script src='PptxGenJS/dist/pptxgen.min.js'></script>
																			<Wrapper
																				wrapperType={
																					'default'
																				}
																			/>
																		</div>
																		<link
																			rel='stylesheet'
																			href='https://cdn.plyr.io/3.7.2/plyr.css'
																		/>
																		<Portal id='portal-notification'>
																			<ReactNotifications />
																		</Portal>
																		<ToastContainer
																			closeButton={
																				ToastCloseButton
																			}
																			toastClassName='toast show'
																		/>
																	</TourProvider>
																</Provider>
															</NotificationsProvider>
														</DatalakeProvider>
													</ModuleProvider>
												</CalendarProvider>
											</CampaignsProvider>
										</CompanyProvider>
									</ChatCollaboratorProvider>
								</ClientProvider>
							</GetAllUsersProvider>
						</UserProvider>
					</ApolloProvider>
				</NiceModal.Provider>
			</ThemeProvider>
		);
	}
};

export default withAITracking(reactPlugin, App);
