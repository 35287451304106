import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import Card, {
	CardActions,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Dropzone, { Accept } from 'react-dropzone';
import useDarkMode from '../../../hooks/useDarkMode';
import classNames from 'classnames';
import useImageUpload from '../../../helpers/useImageUpload';
import Swal from 'sweetalert2';
import { FaPlus, FaRegFilePdf } from 'react-icons/fa';
import { FormLabel, Modal } from 'react-bootstrap';
import BootButton from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import { DATAFRAGMENT_TYPE, FileDocument, Images, Product } from '../../../models/models';
import useTranscribe from '../../../hooks/useTranscribe';
interface Props {
	productName: string;
	documents: FileDocument[];
	setDocuments: Dispatch<SetStateAction<FileDocument[]>>;
	selectedType: DATAFRAGMENT_TYPE;
	onDocumentUpload: (url: string, text: string) => void;
}
const DocumentPicker = ({
	productName,
	selectedType,
	documents,
	setDocuments,
	onDocumentUpload,
}: Props) => {
	const { themeStatus } = useDarkMode();
	const { uploadFileToBlobStorage } = useImageUpload();
	const { getDocumentToText } = useTranscribe();
	const [uploadingImage, setUploadingImage] = useState(false);

	async function handleAcceptedFiles(files: any) {
		setUploadingImage(true);
		const documentItems = [];
		// const documentItems: FileDocument[] = [];
		for (let file of files) {
			if (file.type === 'application/pdf') {
				const result = await uploadFileToBlobStorage({file});
				const doctTextResult = await getDocumentToText(result.url);
				if (doctTextResult?.length > 0) {
					await onDocumentUpload(result.url, doctTextResult);
					documentItems.push({ url: result.url, type: 'pdf' });
				} else {
					Swal.fire({
						icon: 'warning',
						title: 'Invalid File',
						text: 'Please upload Valid Document that contains data.',
						showCancelButton: false,
						showConfirmButton: true,
						allowOutsideClick: true,
					});
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Invalid File',
					text: 'Please upload Valid Document.',
					showCancelButton: false,
					showConfirmButton: false,
					allowOutsideClick: false,
				});

				await new Promise((resolve) => setTimeout(resolve, 1000));
				await Swal.close();
			}
		}

		setDocuments([...documents, ...documentItems]);
		setUploadingImage(false);
	}

	const handleRemoveProductImage = (document: FileDocument) => {
		setDocuments([...documents.filter((i) => i.url !== document.url)]);
	};
	const Drop = () => {
		function formatBytes(bytes: any, decimals = 2) {
			if (bytes === 0) return '0 Bytes';
			const k = 1024;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

			const i = Math.floor(Math.log(bytes) / Math.log(k));
			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		}

		return (
			<React.Fragment>
				<Dropzone
					onDrop={(acceptedFiles) => {
						handleAcceptedFiles(acceptedFiles);
					}}
					accept={{'application/pdf': ['.pdf']}}
				>
					{({ getRootProps, getInputProps }) => (
						<div
							className={`col-xxl-${3} col-lg-${3} col-md-${3} mt-0`}
							{...getRootProps()}>
							<button
								type='button'
								disabled={uploadingImage}
								className={classNames(
									'position-relative',
									'ratio ratio-1x1',
									'rounded-2',
									'border-0',
									'bg-dark',
									'text-white',
								)}>
								<div className='cursor-pointer position-absolute'>
									<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
										{uploadingImage ? (
											<Spinner size={75} />
										) : (
											<FaPlus size={75} color='#71788B' className='mt-5' />
										)}
									</div>
									<div className='px-3 pb-4'>
										<p className='shop-description text-white fs-4 mb-0 mt-2 px-2'>
											{uploadingImage ? `Uploading...` : `Add`}
										</p>
									</div>
								</div>
							</button>
						</div>
					)}
				</Dropzone>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<div className='row'>
				<div className='col-12'>
					<CardHeader className='p-0'>
						<CardLabel icon='Description' className='mb-3' iconColor='success'>
							<CardTitle>Documents</CardTitle>
						</CardLabel>
					</CardHeader>
					<div className='mb-3'>
						<FormLabel className='mb-3'>Add document(s)</FormLabel>
						<div className='row g-2'>
							{documents?.map((item, index) => (
								<div
									key={index}
									className={`col-xxl-${3} col-lg-${3} col-md-${3} mt-0`}>
									<button
										type='button'
										className={classNames(
											'position-relative',
											'ratio ratio-1x1',
											'rounded-2',
											'border-0',
										)}>
										<div className='d-flex justify-content-end'>
											<div
												className='position-relative bg-primary'
												style={{
													top: 5,
													zIndex: 5,
													right: 20,
													borderRadius: '50%',
													width: 20,
													height: 20,
												}}
												key='actions'>
												<CardActions>
													<BootButton
														style={{ borderRadius: 10 }}
														icon='Delete'
														color={themeStatus}
														aria-label='Delete'
														className='p-0'
														onClick={() => {
															handleRemoveProductImage(item);
														}}
													/>
												</CardActions>{' '}
											</div>
										</div>
										<a
											className='mb-0'
											href={item?.url}
											style={{
												width: '100%',
												height: '100%',
												backgroundColor: 'transparent',
											}}>
											<FaRegFilePdf
												style={{
													alignItems: 'center',
													alignContent: 'center',
													padding: '15px 15px 0 15px',
													width: '90%',
													height: '90%',
													backgroundColor: 'transparent',
													color: 'red',
												}}
											/>
										</a>
									</button>
								</div>
							))}
							<Drop />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DocumentPicker;
