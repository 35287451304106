import { Dispatch, SetStateAction, createContext, useContext, useEffect } from 'react';
import { useGetAllUsers } from '../hooks/getAllUsers';
import { EntraIdUser, PeopleUser } from '../Azure/models';
import { UserContext } from './UserContext';

interface IGetAllUsersContext {
	users: EntraIdUser[];
	getUsers: () => Promise<EntraIdUser[]>;
	peopleUsers: PeopleUser[];
	peopleUsersSeachText: string;
	getPeople: (searchText?: string | undefined) => Promise<void>
	setPeopleUsersSearchText: Dispatch<SetStateAction<string>>;
	selectedPeopleUser?: PeopleUser;
	setSelectedPeopleUser: Dispatch<SetStateAction<PeopleUser | undefined>>;
	savePeople: (data: Partial<PeopleUser>) => Promise<void>;
	loading: boolean;
	loadMore: () => void;
	setSearch: Dispatch<SetStateAction<string>>;
	search: string;
	setAcitveId: Dispatch<SetStateAction<string | undefined>>;
}

export const GetAllUsersContext = createContext<IGetAllUsersContext>({} as IGetAllUsersContext);

export const GetAllUsersProvider = ({ children }: { children: any }) => {
	const { 
		users, getUsers, peopleUsers, peopleUsersSeachText, getPeople, setPeopleUsersSearchText, selectedPeopleUser, 
		setSelectedPeopleUser, savePeople, loading, loadMore, setSearch, search, setAcitveId
	} = useGetAllUsers();
	const { user } = useContext(UserContext);

	useEffect(() => {
		if (user?.accessToken) {
			getUsers();
		}
	}, [user?.accessToken]);

	return (
		<GetAllUsersContext.Provider value={{ 
			users, getUsers, peopleUsers, peopleUsersSeachText, getPeople, setSelectedPeopleUser, selectedPeopleUser, 
			setPeopleUsersSearchText, savePeople, loading, loadMore, setSearch, search, setAcitveId
		}}>
			{children}
		</GetAllUsersContext.Provider>
	);
};
