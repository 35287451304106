import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Workflow, WorkflowCampaign, WorkflowSearchParams } from '../models/models';
import { ClientContext } from '../contexts/ClientContext';
import { useQuery } from '@apollo/client';
import { GET_CAMPAIGNS_BY_CLIENTID, GET_WORKFLOWS_BY_CLIENTID } from '../GraphQL/workflow/queries';
import { log } from 'console';

export default function useWorkflows() {
	const { activeUser, user } = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [workflows, setWorkflows] = useState<Workflow[]>([]);
	const [totalWorkflows, setTotalWorkflows] = useState<number>(0);
	const [campaigns, setCampaigns] = useState<WorkflowCampaign[]>([]);
	const { activeClientId, missionHierarchySettings } = useContext(ClientContext);
	const [workflowSeachParams, setWorkflowSeachParams] = useState<WorkflowSearchParams>({
		level1: '',
		level2: '',
		level3: '',
		pageNumber: 1,
		limit: 20,
		searchText: '',
	});
	const { data: workflowsData, refetch: refetchWorkflows } = useQuery(GET_WORKFLOWS_BY_CLIENTID, {
		variables: {
			clientId: activeClientId,
			accessToken: user?.accessToken,
			level1: workflowSeachParams?.level1,
			level2: workflowSeachParams?.level2,
			level3: workflowSeachParams?.level3,
			pageNumber: workflowSeachParams?.pageNumber,
			limit: workflowSeachParams?.limit,
		},
		skip: !user?.accessToken || !user?.uniqueId || !activeClientId,
	});
	const { data: campaignsData } = useQuery(GET_CAMPAIGNS_BY_CLIENTID, {
		variables: { clientId: activeClientId, accessToken: user?.accessToken },
		skip: !user?.accessToken || !user?.uniqueId || !activeClientId,
	});

	useEffect(() => {
		if (!!workflowsData?.getWorkflows) {
			setWorkflows((workflowsData?.getWorkflows?.workflows ?? []) as Workflow[]);
			setTotalWorkflows(workflowsData?.getWorkflows?.totalWorkflows ?? 0);
			setLoading(false);
		}
	}, [workflowsData]);

	useEffect(() => {
		if (!!campaignsData?.getCampaigns) {
			setCampaigns(campaignsData?.getCampaigns);
		}
	}, [campaignsData]);

	const refetchWorkflowsFunc = async () => {
		setLoading(true);
		const res = await refetchWorkflows({
			clientId: activeClientId,
			accessToken: user?.accessToken,
			level1: workflowSeachParams?.level1,
			level2: workflowSeachParams?.level2,
			level3: workflowSeachParams?.level3,
			pageNumber: workflowSeachParams?.pageNumber,
			limit: workflowSeachParams?.limit,
		});
		if (!!res?.data?.getWorkflows) {
			setWorkflows(res?.data?.getWorkflows?.workflows as Workflow[]);
			setTotalWorkflows(res?.data?.getWorkflows?.totalWorkflows);
		}
		setLoading(false);
	};

	useEffect(() => {
		(async() => {
			await refetchWorkflowsFunc();
		})()
	}, [workflowSeachParams?.level1, workflowSeachParams?.level2, workflowSeachParams?.level3, workflowSeachParams?.limit, workflowSeachParams?.pageNumber])

	useEffect(() => {
		if((!!workflowSeachParams?.level1?.length && !missionHierarchySettings?.level1?.includes(workflowSeachParams?.level1))
			|| (!!workflowSeachParams?.level2?.length && !missionHierarchySettings?.level2?.includes(workflowSeachParams?.level2)
			|| (!!workflowSeachParams?.level3?.length && !missionHierarchySettings?.level3?.includes(workflowSeachParams?.level3)))){
				setWorkflowSeachParams({
					level1: '',
					level2: '',
					level3: '',
					pageNumber: 1,
					limit: workflowSeachParams?.limit,
					searchText: '',
				});
			}
	}, [missionHierarchySettings])

	return { workflows, campaigns, loading, workflowSeachParams, setWorkflowSeachParams, totalWorkflows};
}
