import { createContext } from 'react';
import { Calendar, EventConfiguration } from '../models/models';
import useCalendars from '../hooks/useCalendars';

interface ICalendarContext {
	calendarTemplates: Calendar[];
	saveCalendar: (events: EventConfiguration[], id: string) => Promise<string>;
	removeEvent: (event: EventConfiguration, id: string) => Promise<void>;
	deleteCalendar: (calendar: Calendar) => Promise<void>;
}

export const CalendarContext = createContext<ICalendarContext>({} as ICalendarContext);

export const CalendarProvider = ({ children }: { children: any }) => {
	const { calendarTemplates, saveCalendar, removeEvent, deleteCalendar } = useCalendars();
	return (
		<CalendarContext.Provider
			value={{
				calendarTemplates,
				saveCalendar,
				removeEvent,
				deleteCalendar,
			}}>
			{children}
		</CalendarContext.Provider>
	);
};
