import React, { FC } from 'react';
import PropTypes from 'prop-types';
import whiteLogo from '../assets/img/ai/Frame.png';
import blackLogo from '../assets/img/ai/Frame-black.png';

interface ILogoProps {
	width?: number;
	height?: number;
	color?: string;
}
const Logo: FC<ILogoProps> = ({ width, height, color }) => {
	return (
		<img
			src={color == 'black' ? blackLogo : whiteLogo}
			loading='lazy'
			// width={height !== 854 && !!height ? height * (2155 / 854) : width}
			// height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			width={80}
			height={100}
			alt='logo'
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	color: PropTypes.string,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
	color: 'white',
};

export default Logo;
