import React, { FC, ReactNode, useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Content from '../Content/Content';
import WrapperOverlay from './WrapperOverlay';
import HeaderRoutes from '../Header/HeaderRoutes';
import FooterRoutes from '../Footer/FooterRoutes';
import ThemeContext from '../../contexts/ThemeContext';
import intl from 'react-intl-universal';
import bechtel from '../../lang/bechtel.json';
import default0 from '../../lang/default.json';
import { ClientContext } from '../../contexts/ClientContext';
//import useForceUpdate from 'use-force-update';
interface IWrapperContainerProps {
	children: ReactNode;
	className?: string;
}
const LOCALES_LIST = [
	{
		label: 'Bechtel',
		value: 'bechtel',
	},
	{
		label: 'Default',
		value: 'default0',
	},
];

const LOCALE_DATA = {
	bechtel: bechtel,
	default0: default0,
};
export const WrapperContainer: FC<IWrapperContainerProps> = ({ children, className, ...props }) => {
	const { rightPanel } = useContext(ThemeContext);
	return (
		<div
			className={classNames(
				'wrapper',
				{ 'wrapper-right-panel-active': rightPanel },
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
	);
};
WrapperContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
WrapperContainer.defaultProps = {
	className: undefined,
};

const WrapperUseEffectFunc = () => {
	const { activeClient } = useContext(ClientContext);
	const [initDone, setInitDone] = useState(false);

	useEffect(() => {
		if (activeClient?.locale == 'bechtel') {
			setCurrentLocale('bechtel');
		} else if (activeClient?.locale == 'fleato') {
			setCurrentLocale('fleato');
		} else {
			setCurrentLocale('default0');
		}
	}, [activeClient]);

	useEffect(() => {
		initializeIntl();
	}, []);

	const initializeIntl = () => {
		let currentLocale = intl.determineLocale({
			urlLocaleKey: 'lang',
			cookieLocaleKey: 'lang',
		});

		if (!LOCALES_LIST.some((item) => item.value === currentLocale)) {
			currentLocale = 'fleato';
		}

		setCurrentLocale(currentLocale);
		setInitDone(true);
	};

	const setCurrentLocale = (currentLocale: string) => {
		intl.init({
			currentLocale,
			locales: LOCALE_DATA,
		});
	};

	const onLocaleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setCurrentLocale(e.target.value);
		//forceUpdate();
	};

	const localeSelector = (
		<select onChange={onLocaleChange} defaultValue=''>
			<option value='' disabled>
				Change Language
			</option>
			{LOCALES_LIST.map((locale) => (
				<option key={locale.value} value={locale.value}>
					{locale.label}
				</option>
			))}
		</select>
	);

	return (<></>);
}
const Wrapper = ({wrapperType}: {wrapperType: 'disseminate' | 'default'}) => {
	//const forceUpdate = useForceUpdate();

	return (
		<>
			{wrapperType === 'default' && <WrapperUseEffectFunc />}
			<WrapperContainer>
				<HeaderRoutes />
				<Content />
				<FooterRoutes />
			</WrapperContainer>
			<WrapperOverlay />
		</>
	);
};

export default Wrapper;
